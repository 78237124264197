import { Component } from '@angular/core';
import { LinkService } from '../../services/link.service';
import { UtilsUser } from '../../utils/utils-user';
import { MenuPosition } from './models/menu-position';
import { BaseComponent } from '../../core/base/base/base.component';
import { environment } from '../../environments/environment';
import { UserService } from '../../services/user.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends BaseComponent {
	public current: MenuPosition;

	public showMenu: boolean = false;
	public top: number = 0;
	public left: number = 0;

	public menuElements: MenuPosition[];

	public isOnRightClickMenu: boolean = true;
	public useMenuFromJson: boolean = environment.useMenuFromJson;

	public imageLoadedCounter: number = 0;
	public isLoading: boolean = true;

	constructor(private linkService: LinkService, private us: UserService) {
		super();
		super.setTitle(`Dev IT v. ${environment.version}`);

		if (location.hash == '#/redirect') this.router.navigateByUrl('home');

		if (this.useMenuFromJson)
			this.us.logIn()
				.then((x) => {
					this.menuElements = x;
				})
				.catch(() => {
					this.menuElements = UtilsUser.getMenu();
				});
		else
			this.menuElements = UtilsUser.getMenu();
	}

	imageLoaded() {
		this.imageLoadedCounter++;
		if (this.imageLoadedCounter == this.menuElements.length)
			this.isLoading = false;
	}

	enter(menuPosition: MenuPosition) {
		if (this.isOffline && !menuPosition.isWorkOffline) {
			this.displaySnack('Opcja dostępna tylko w trybie Online');
			return;
		}

		if (menuPosition.subdomain && menuPosition.useSubdomain) {
			location.href = `https://${menuPosition.subdomain}.${environment.domain}`;
			return;
		}

		if (menuPosition.url.includes('{url}')) {
			menuPosition.url = menuPosition.url.replace(
				'{url}',
				this.linkService.getUrl()
			);
		}

		if (menuPosition.url.startsWith(':')) {
			location.href = this.linkService.getUrl() + menuPosition.url;
			return;
		}

		if (
			menuPosition.url.startsWith('http') ||
			menuPosition.url.startsWith('www')
		) {
			location.href = menuPosition.url;
			return;
		}

		this.router.navigateByUrl(menuPosition.url);
	}

	openInNewWindow() {
		let url = this.current.url;

		if (this.isOffline && !this.current.isWorkOffline) {
			this.displaySnack('Opcja dostępna tylko w trybie Online');
		} else if (this.current.subdomain && this.current.useSubdomain) {
			url = `https://${this.current.subdomain}.${environment.domain}`;
		} else if (url.includes('{url}')) {
			url = url.replace('{url}', this.linkService.getUrl());
		} else if (url.startsWith(':')) {
			url = this.linkService.getUrl() + url;
		} else if (url.startsWith('http') || this.current.url.startsWith('www')) {
			url = this.current.url;
		} else {
			url = this.linkService.getUrl() + '#' + url;
		}

		this.utilsService.openUrl(url);

		this.showMenu = false;
		this.current = null;
	}

	showCustomContextMenu(menuPosition: MenuPosition, event: any) {
		event.preventDefault();
		this.current = menuPosition;
		this.showMenu = !this.showMenu;
		this.top = event.clientY;
		this.left = event.clientX;
	}

	closeContextMenu() {
		this.showMenu = false;
	}

	displayUrl(menuPosition: MenuPosition, event: Event) {
		if (this.isOnRightClickMenu && this.isDavid()) {
			event.preventDefault();
			this.showCustomContextMenu(menuPosition, event);
		}
	}
}
