import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../core/base/base/base.component';
import { UtilsString } from '../../../utils/utils-string';

@Component({
  selector: 'app-notes-details',
  templateUrl: './notes-details.component.html',
  styleUrls: ['./notes-details.component.scss']
})
export class NotesDetailsComponent extends BaseComponent implements OnInit {

  public hideKey: boolean = false;
  public category: string;
  public categoryText: string;
  public notes: string = null;
  public key: string;
  public value: string;
  public isSomethingInEditMode: boolean = false;

  constructor() {
    super();

    this.route.params.subscribe(x => {
      this.category = x.id.toUpperCase();
      this.categoryText = UtilsString.capitalizeFirstLetter(UtilsString.normalize(x.id));

      if (x.hide && x.hide === "1")
        this.hideKey = true;
    });
  }

  public ngOnInit(): void {
    this.get();
  }

  public updateEdit(product: any) {
    product.value = product.tmpValue;
    product.key = product.tmpKey;
    product.editMode = false;
    this.update(product);
  }

  public cancelEdit(product: any) {
    product.editMode = false;
  }

  public update(model: any) {
    this.api.apiDictionaryLiteDbUpdate(model).then(x => console.log(x));
  }

  public openEdit(product: any) {
    if (!product.editMode) {
      product.editMode = true;
      product.tmpKey = product.key;
      product.tmpValue = product.value;
      this.isSomethingInEditMode = true;
    } else {
      product.editMode = false;
      this.isSomethingInEditMode = false;
    }
  }

  public remove(n: any) {
    this.utilsService.openConfirm(`Czy na pewno usunąć notatkę '${n.key ?? n.value}'?`, (x: boolean) => {
      if (x)
        this.api.apiDictionaryLiteDbRemoveByid(n.id).then(() => {
          this.get();
          this.displaySnack(`Pomyślenie usunięto notatkę '${n.key ?? n.value}'?`);
        })
    });
  }

  public add() {
    if (!this.value)
      return;

    let model = {
      value: this.value,
      context: 'NotesCategory-' + this.category,
      key: this.key
    } as any;

    this.api.apiDictionaryLiteDbAdd(model).then(() => {
      this.get();
      this.key = null;
      this.value = null;
    });
  }

  goToCategory() {
    this.router.navigateByUrl('notes')
  }

  public get() {
    this.api.apiDictionaryLiteDbGetByContext(`NotesCategory-${this.category}`).then(x => {
      this.notes = (x as any);
      this.saveInLocalStorage(`NotesCategory-${this.category}Data`, x);
    }, () => {
      this.getDataFromlocalStorage();
    }).catch(x => {
      this.getDataFromlocalStorage();
    });
  }

  public getDataFromlocalStorage() {
    let data = this.loadFromLocalStorage(`NotesCategory-${this.category}Data`);
    if (data)
        this.notes = (data as any);
  }
}
