import { Injectable } from "@angular/core";

@Injectable()
export class NsBridgeService {

    public isBridge() {
        return (window as any).nsWebViewBridge;
    }

    public listen(eventName: string, callback: Function) {
        if (this.isBridge()){
          ((window as any).nsWebViewBridge).on(eventName, callback);
        }
    }

    public emit(eventName: string, data: any) {
      if (this.isBridge()){
            (window as any).nsWebViewBridge.emit(eventName, data);
      }
    }



    public getVersion() {
      if ((window as any).nsWebViewBridge)
          return (window as any).nsWebViewBridge.getVersion();
    }


    public eventListener(eventName: string, callback: any) {
      if ((window as any).nsWebViewBridge) {
        console.log("Dodaję eventListenera " + eventName);
        window.addEventListener(eventName, callback);
      }
  }
}
