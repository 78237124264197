import { GuidModel } from "./guid.model";

export class DownloadModel {
    link: string;
    fileName: string;
    folderName: string;
    saveLocation: number = 1;
    status: boolean = false;
    extension: string = "mp4";
    guid: string = GuidModel.create().toString();
    percetage: string = 'Inicjalizacja...';
    quality: string = '720p';

    lastStatus: string;
    counter: number = 0;
}
