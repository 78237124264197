<div class="form">

  <app-header-for-module text="Inteligentny Dom" src="/assets/icons/domoticz/domoticz.png"></app-header-for-module>

  <app-progress-spinner [isShow]="!data[233]"></app-progress-spinner>

  <div *ngIf="data[233]">

    <div class="row center" style="width:98%">
      <div class="col">
        Temperatura <br />
        <b>{{data[233].data}}</b> <br />
        <small class="lastUpdate">{{data[233].lastUpdate}}</small>
      </div>
      <div class="col">
        Temperatura odczuwalna <br />
        <b class="value">{{data[234].data}}</b><br />
        <small class="lastUpdate">{{data[234].lastUpdate}}</small>
      </div>
    </div>

    <hr />

    <div class="row center" style="width:98%">
      <div class="col">
        Sypialnia <br />
        <b class="value"> {{data[17].data}}</b><br />
        <small class="lastUpdate">{{data[17].lastUpdate}}</small>
      </div>
      <div class="col">
        Salon <br />
        <b class="value">{{data[248].data}}</b><br />
        <small class="lastUpdate">{{data[248].lastUpdate}}</small>
      </div>
    </div>

    <hr />

    <div class="row center" style="width:98%">
      <div class="col">
        Kuchnia <br />
        <b class="value"> {{data[127].data}}</b><br />
        <small class="lastUpdate">{{data[127].lastUpdate}}</small>
      </div>
      <div class="col">
        Łazienka <br />
        <b class="value">{{data[126].data}}</b><br />
        <small class="lastUpdate">{{data[126].lastUpdate}}</small>
      </div>
    </div>

    <hr />

    <div class="row center" style="width:98%">
      <div class="col">
        Pokój <br />
        <b class="value"> {{data[52].data}}</b><br />
        <small class="lastUpdate">{{data[52].lastUpdate}}</small>
      </div>
      <div class="col">
        Cytryna <br />
        <b class="value">{{data[85].data}}</b> <br />
        <small class="lastUpdate">{{data[85].lastUpdate}}</small>
      </div>
    </div>

    <hr />


  </div>
</div>