<div class="form">

  <app-header-for-module text="Czas Pracy" src="/assets/icons/work-time/work-time.png"></app-header-for-module>

  <div>

    <div>
      <button class="col-2 lavender-black-btn" (click)="minus()" mat-raised-button color="warn">-</button>
      {{year}}
      <button class="col-2 lavender-black-btn" (click)="plus()" mat-raised-button color="warn">+</button>

      <mat-checkbox class="ms-5" color="warn" *ngIf="selectedRaport == 2" [(ngModel)]="isColor">Koloruj
      </mat-checkbox>

      <hr />
    </div>


    <div class="row mt-5">
      <button class="col-sm p-10 lavender-btn" (click)="raport(1)" mat-raised-button color="warn">Wpisy</button>
      <button class="col-sm p-10 lavender-btn" (click)="raport(2)" mat-raised-button color="warn">Raport Stary</button>
      <button class="col-sm p-10 lavender-black-btn" (click)="raport(3)" mat-raised-button color="warn">Raport
        Nowy</button>
      <button class="col-sm p-10 green-btn" (click)="back()" mat-raised-button color="warn">Powrót</button>
    </div>

  </div>
  <hr />

  <div *ngIf="selectedRaport == 1">

    <table class='table table-striped table-hover'>
      <thead>
        <tr>
          <th>Zdarzenie</th>
          <th>Terminal</th>
          <th>Data i Czas</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let entry of data; trackBy: trackByIndex">
          <td [ngSwitch]="entry.eventTypeId">
            <div *ngSwitchCase="1">
              Wejscie
            </div>
            <div *ngSwitchCase="2">
              Wyjscie
            </div>
            <div *ngSwitchCase="20">
              Przerwa
            </div>
          </td>

          <td>{{ entry.terminalId }}</td>
          <td>{{ entry.loggedOn | date:'dd/MM/yyyy HH:mm:ss' }}</td>
        </tr>
      </tbody>
    </table>

  </div>

  <div *ngIf="selectedRaport == 2">

    <table class='table table-striped table-hover'>
      <thead>
        <tr>
          <th>Zdarzenie</th>
          <th>Czas bycia w pracy</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let entry of data; trackBy: trackByIndex" [ngClass]="getClass(entry)">
          <td>
            <b class="head">{{entry.date | date: 'dd/MM/yyyy'}}</b> ({{getDayofWeek(entry.date | date: 'EEEE')}})

            <div style="padding-left:10px" *ngFor="let item of entry.logEntries; trackBy: trackByIndex"
              (click)="entry.showDetails = !entry.showDetails">
              {{item.loggedOn | date: 'dd/MM/yyyy HH:mm'}}

              <ng-container [ngSwitch]="item.eventTypeId" *ngIf="!entry.showDetails">
                <ng-container *ngSwitchCase="1">
                  <b class="head">(Wejscie)</b>
                </ng-container>
                <ng-container *ngSwitchCase="2">
                  <b class="head">(Wyjscie)</b>
                </ng-container>
                <ng-container *ngSwitchCase="20">
                  <b class="head">(Przerwa)</b>
                </ng-container>
              </ng-container>
            </div>

          </td>
          <td> {{entry.workTimeString}} <br /><b class="head">({{ entry.summaryTimeString }})</b></td>
        </tr>
      </tbody>
    </table>

  </div>

  <div *ngIf="selectedRaport == 3">

    <table class='table table-striped table-hover'>
      <thead>
        <tr>
          <th>Miesiąc</th>
          <th>Godzin pracujących</th>
          <th>Dni wolnych</th>
          <th>Dni zdalnych</th>
          <th>Przepracowano</th>
          <th>Przepracowano PZ + U</th>
          <th>Różnica</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let entry of data?.months; trackBy: trackByIndex">
          <td>
            {{entry.name}}
          </td>
          <td>
            {{entry.workDay * 8}}
          </td>
          <td>
            {{entry.freeDay}}
          </td>
          <td>
            {{entry.remoteDay}}
          </td>
          <td>
            {{getTime(entry.workedTimeMinutes)}}
          </td>
          <td>
            {{getTime(entry.fullWorkedTimeMinutes)}}
          </td>
          <td>
            {{getTimeRoznica(entry.fullWorkedTimeMinutes - entry.workDay * 8 * 60, entry)}}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <td>

        </td>
        <td>
          {{getSummary('workDay') * 8}}
        </td>
        <td>
          {{getSummary('freeDay')}}
        </td>
        <td>
          {{getSummary('remoteDay')}}
        </td>
        <td>

        </td>
        <td>

        </td>
        <td>
          {{getTime(getSummary('minutes') + 10)}}
        </td>
      </tfoot>
    </table>

  </div>

</div>