<div class="form">

	<app-header-for-module text="Wazne" src="/assets/icons/important/important.png"></app-header-for-module>

	<div class="center">

		<mat-card (click)="counter= counter+1">
			<mat-card-content>
				<b>Krew Dawida</b> <br />
				ARh+
			</mat-card-content>
		</mat-card>

		<mat-card class="">
			<mat-card-content>
				<b>Krew Madzi</b> <br />
				BRh-
			</mat-card-content>
		</mat-card>

		<mat-card class="">
			<mat-card-content>
				<b>Krew Marcela</b> <br />
				ARh+
			</mat-card-content>
		</mat-card>

		<mat-card>
			<mat-card-content>
				<b>Kod do klatki 10</b> <br />
				<i class="fas fa-bell"></i> 10 9267 <i class="fas fa-bell"></i>
			</mat-card-content>
		</mat-card>

		<div *ngIf="counter > 5">

			<mat-card>
				<mat-card-content>
					<b>NIP</b> <br />
					627 270 38 42
				</mat-card-content>
			</mat-card>

			<mat-card>
				<mat-card-content>
					<b>Dawid </b> <i class="fas fa-id-card"></i> <br />
					91013108513
				</mat-card-content>
			</mat-card>

			<mat-card>
				<mat-card-content>
					<b>Dawid </b> <i class="fas fa-phone"></i> <br />
					663583517
				</mat-card-content>
			</mat-card>

			<mat-card>
				<mat-card-content>
					<b>Magda </b> <i class="fas fa-id-card"></i> <br />
					96112303887
				</mat-card-content>
			</mat-card>

			<mat-card>
				<mat-card-content>
					<b>Magda </b> <i class="fas fa-phone"></i> <br />
					798334776
				</mat-card-content>
			</mat-card>

			<mat-card>
				<mat-card-content>
					<b>Marcel </b> <i class="fas fa-id-card"></i> <br />
					22301806439
				</mat-card-content>
			</mat-card>


			<div *ngIf="userName== 'Admin'">
				<mat-card class="little-purple">
					<mat-card-content>
						<b>Kod do klatki 14</b> <br />
						<i class="fas fa-key"></i> 14 <i class="fas fa-key"></i> 5917
					</mat-card-content>
				</mat-card>

				<mat-card class="little-purple">
					<mat-card-content>
						<b>Kod do klatki 2</b> <br />
						<i class="fas fa-bell"></i> 2 1973 <i class="fas fa-bell"></i>
					</mat-card-content>
				</mat-card>

				<mat-card class="little-purple">
					<mat-card-content>
						<b>Kod do klatki Babcia Basia</b> <br />
						<i class="fas fa-bell"></i> 8 4444 <i class="fas fa-bell"></i>
					</mat-card-content>
				</mat-card>

				<mat-card class="little-purple">
					<mat-card-content>
						<b>Kaj teroz</b> <br />
						823033
					</mat-card-content>
				</mat-card>

				<mat-card class="little-purple">
					<mat-card-content>
						<b>Madzi Wifi</b> <br />
						29c479zxa92
					</mat-card-content>
				</mat-card>

				<mat-card class="little-purple">
					<mat-card-content>
						<b>Samochód licznik na start</b> <br />
						41522
					</mat-card-content>
				</mat-card>

				<mat-card class="little-purple">
					<mat-card-content>
						<b>Samochód Radio PIN</b> <br />
						1834
					</mat-card-content>
				</mat-card>

				<mat-card class="little-purple">
					<mat-card-content>
						<b>Samochód Lakier</b> <br />
						Nissan Z11
					</mat-card-content>
				</mat-card>

				<mat-card class="little-purple">
					<mat-card-content>
						<b>Walizka PIN</b> <br />
						310
					</mat-card-content>
				</mat-card>

				<mat-card class="little-purple">
					<mat-card-content>
						<b>Mac Laptop</b> <br />
						08:60:6E:06:B7:07
					</mat-card-content>
				</mat-card>

				<mat-card class="little-purple">
					<mat-card-content>
						<b>Mama numer konta</b> <br />
						48 1140 2004 0000 3902 6959 5911
					</mat-card-content>
				</mat-card>

				<mat-card class="little-purple">
					<mat-card-content>
						<b>Bitbucket App Key</b><br />
						old. afej7k5HQ3kaP5ULa9Ke
						<br />
						new. 78lvo1vQ5O3H+eJzCummz1oTdVi3agSStjZtviPf2Rb+tjBJxp1Atg==
					</mat-card-content>
				</mat-card>

				<mat-card class="little-purple">
					<mat-card-content>
						<b>vWifi Voyager Wifi</b><br />
						v0yager1109W1F1
					</mat-card-content>
				</mat-card>

				<mat-card class="little-purple">
					<mat-card-content>
						<b>Sztauwajery Voyager Wifi</b><br />
						Macos5693
					</mat-card-content>
				</mat-card>

				<mat-card class="little-purple">
					<mat-card-content>
						<b>Magda </b> <i class="fas fa-phone"></i> <br />
						798334776
					</mat-card-content>
				</mat-card>

				<mat-card class="little-purple">
					<mat-card-content>
						<b>Mama </b> <i class="fas fa-phone"></i> <br />
						661545998
					</mat-card-content>
				</mat-card>

				<mat-card class="little-purple">
					<mat-card-content>
						<b>Tata </b> <i class="fas fa-phone"></i> <br />
						726675001
					</mat-card-content>
				</mat-card>

				<mat-card class="little-purple">
					<mat-card-content>
						<b>Łukasz </b> <i class="fas fa-phone"></i> <br />
						725206246
					</mat-card-content>
				</mat-card>


				<mat-card class="semi-purple">
					<mat-card-content>
						<b>M B L</b> <br />
						dPMyJpcagVcSFu86PAVO5g== <br />
						ZhywH1BOO1I/AEdy38Abmg==
					</mat-card-content>
				</mat-card>

				<mat-card class="semi-purple">
					<mat-card-content>
						<b>T B L</b> <br />
						Zodq1GNGoR8= <br />
						Dws+wy5X9rgSFu86PAVO5g==
					</mat-card-content>
				</mat-card>

				<mat-card class="semi-purple">
					<mat-card-content>
						<b>T Obl H</b> <br />
						fkC9wqbOih4SFu86PAVO5g== <br />
						2ZBTHv9Xc/XcIDmk+TeSgQ==
					</mat-card-content>
				</mat-card>

				<mat-card class="semi-purple">
					<mat-card-content>
						<b>Mag B H</b> <br />
						2sJVShJS3IT5XmzSIM8lUg== <br />
						EKcZ6Uz4fnISFu86PAVO5g== <br />
						4360REM4vxSYhyRpzAmx5KuLWuMmui/A
					</mat-card-content>
				</mat-card>

				<mat-card class="semi-purple">
					<mat-card-content>
						<b>VoyVPN</b> <br />
						7v37j3wZp
					</mat-card-content>
				</mat-card>

				<mat-card class="semi-purple">
					<mat-card-content>
						<b>Mag mOby</b> <br />
						irSZfNcUtB4uszd1z8tjSg== <br />
					</mat-card-content>
				</mat-card>

				<mat-card class="semi-purple">
					<mat-card-content>
						<b>Enc K</b> <br />
						sblw-3hn8-sqoy21 <br />
					</mat-card-content>
				</mat-card>




			</div>




		</div>



	</div>
</div>