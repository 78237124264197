<div class="center body">
  <img *ngIf="count < 5" [src]="getImage()" class="img-fluid image-class"
    [ngClass]="{'rotated' : isRotated, 'transitioning-src ' : imageClass === 'transitioning-src', 'parallax-slider' : imageClass === 'parallax-slider'}"
    (load)="onImageLoad($event)" />

  <iframe *ngIf=" count===5" [src]="iFrameUrl" frameborder="0" scrolling="none" width="100%" height="98%">
  </iframe>
</div>

<div *ngIf="count < 5 && actualDate">
  <h1 class="time"><b>{{actualDate}}</b></h1>
  <h3 class="date">{{dayOfWeek}}, {{date}}</h3>
  <h3 class="folder">{{folderName}}</h3>
  <h3 (click)="weatherClick()" class="weather">{{weather}}</h3>
</div>