import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { BusStopPanelVM } from "./models/busStopPanelVM";
import { BaseComponent } from '../../../core/base/base/base.component';

@Component({
	selector: 'app-bus-stop-panel',
	templateUrl: './bus-stop-panel.component.html',
	styleUrls: ['./bus-stop-panel.component.scss']
})
export class BusStopPanelComponent extends BaseComponent implements OnInit, OnDestroy {

	@Input() stationId: number;

	public vm: BusStopPanelVM = new BusStopPanelVM();

	constructor() {
		super();
	}

	ngOnInit() {
		this.check();
		this.vm.s = interval(12000).subscribe(() => this.check());
	}

	parseInt(number: number) {
		return parseInt(number.toString());
	}

	check() {
		// this.api.apiZtmGetBusStopInfo(this.stationId).then((y) => {
		//   this.vm.lastUpdate = new Date();
		//   this.vm.res = y;
		// });
	}

	ngOnDestroy(): void {
		this.vm.s?.unsubscribe();
		super.ngOnDestroy();
	}
}
