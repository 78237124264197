import { AfterViewChecked, Component, QueryList, ViewChildren } from '@angular/core';
import { BusStopPanelComponent } from './bus-stop-panel/bus-stop-panel.component';

@Component({
  selector: 'app-bus-stop',
  templateUrl: './bus-stop.component.html',
  styleUrls: ['./bus-stop.component.scss']
})
export class BusStopComponent implements AfterViewChecked {

  @ViewChildren(BusStopPanelComponent)
  busStopPanels: QueryList<BusStopPanelComponent>;

  ngAfterViewChecked(): void {
    console.debug(this.busStopPanels);
  }
}
