import { Component } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { NotesModel } from "./models/notes.model";
import { BaseComponent } from '../../core/base/base/base.component';
import { DictionaryEntity } from '../../generated/DomoticzApiClient';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent extends BaseComponent {

  public categories: any = null;
  public newCategory: string;
  public canAdd: boolean = true;

  private s: Subscription;

  constructor() {
    super();

    this.get();
    this.s = interval(10000).subscribe(x => {
      this.get();
    });
  }

  public add() {
    if (!this.newCategory)
      return;

    this.canAdd = false;
    let model = new DictionaryEntity(new NotesModel(this.newCategory, 'NotesCategory', 'NotesCategory'));

    this.api.apiDictionaryLiteDbAdd(model).then(x => {
      this.get();
      this.newCategory = null;
      this.canAdd = true;
    });
  }

  public remove(n: any) {
    this.utilsService.openConfirm(`Czy na pewno usunąć kategorię '${n.value.toUpperCase()}'?`, x => {
      if (x) {
        if (this.userName == "Admin")
          this.api.apiDictionaryLiteDbRemoveByid(n.id).then(() => this.get());
        else
          this.utilsService.openAlert('Wyłączona możliwosć usuwania kategorii');
      }
    });
  }

  public go(category: any) {
    this.router.navigateByUrl(`notes-details/${category.value}${category.key == 'hide' ? '/1' : ''}`)
  }

  public get() {
    this.api.apiDictionaryLiteDbGetByContext('NotesCategory').then(x => {
      this.categories = x;
      this.saveInLocalStorage('NotesData', x);
    }, () => {
      this.getDataFromlocalStorage();
    }).catch(x => {
      this.getDataFromlocalStorage();
    });
  }

  public getDataFromlocalStorage(){
    let data = this.loadFromLocalStorage('NotesData');
    if (data)
      this.categories = data;
  }

  override ngOnDestroy(): void {
    this.s?.unsubscribe();
    super.ngOnDestroy();
  }
}
