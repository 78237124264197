import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MenuPosition } from "../app/home/models/menu-position";

@Injectable()
export class UserService {

	public menuItems: MenuPosition[];
	public availableMenuItems: MenuPosition[];

	constructor(public httpClient: HttpClient) {
	}

	public getUserName(): string {
		if (localStorage.getItem("user")?.indexOf("Sudo") === 0)
			return "Admin";
		else if (localStorage.getItem("user"))
			return localStorage.getItem("user") as string;
		else if (navigator.userAgent.indexOf("DevITAndroidApp-") < 0)
			return "Anonim";
		else
			return navigator.userAgent.split('-')[1];
	}

	public async logIn() {
		await this.getMenuItems();
		return this.availableMenuItems;
	}

	private async getMenuItems() {
		return new Promise<void>(async (resolve, reject) => {
			try {
				let menuItemsData = await this.httpClient.get<MenuPosition[]>("/assets/users/menu-items.json").toPromise();
				menuItemsData = menuItemsData.filter(x => !x.disabled);
				this.menuItems = menuItemsData;
				await this.getAvailableMenuItems();
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	private async getAvailableMenuItems() {
		if (this.getUserName() == "Admin") {
			let rolesArray = new Array<number>();
			this.menuItems.forEach(x => { if (x.id < 1000) rolesArray.push(x.id) });
			this.getMenu(rolesArray);
		}
		else {
			return new Promise<void>(async (resolve, reject) => {
				try {
					let fileName = this.getUserName();
					if (fileName == "David")
						fileName = "Dawid";

					this.httpClient.get<number[]>(`/assets/users/user-${fileName}.json`).subscribe(rolesArray => {
						this.getMenu(rolesArray)
							.then(() => resolve())
					});
				} catch (error) {
					reject(error);
				}
			});
		}
	}

	private async getMenu(roles: number[]) {
		let res = new Array<MenuPosition>();
		roles.forEach(z => {
			let arr = this.menuItems.filter(x => x.id == z);
			if (arr.length > 0)
				res.push(arr[0]);
		});
		this.availableMenuItems = res;
		return res;
	}
}
