export class Product {

    id: number;
    value: string;
    context: string;
    key: string;

    constructor(name: string, context: string, key: string) {
        this.value = name;
        this.context = context;
        this.key = key;
    }
}