<div class="form">
  <div class="modal-header row">
    <div class="col-10">
      <h2 class="modal-title"> {{ data.element.name }}</h2>
    </div>
    <div class="col-2">
      <span class="close red x-large cursor-pointer" (click)="close()"><span class="fa fa-times"></span>
      </span>
    </div>

  </div>
  <hr />

  <div class="modal-body pt-5">
    <div [innerHTML]="data.element.html"></div>
  </div>
</div>