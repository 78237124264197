import { Injectable } from "@angular/core";
import { LinkService } from "./link.service";
import { NsBridgeService } from "./ns-bridge.service";

declare var bootbox: any;

@Injectable()
export class UtilsService {

    constructor(
        private nsBridgeService: NsBridgeService,
        private linkService: LinkService) { }

    openUrl(link: string) {
        if (this.nsBridgeService.isBridge())
            this.nsBridgeService.emit("OpenUrl", link)
        else
            window.open(link, '_blank');
    }

    openImage(link: string) {
        if (this.nsBridgeService.isBridge()) {
            let url = link;
            if (url.indexOf('http') < 0)
                url = 'http://devit4.ddns.net/' + url;

            this.nsBridgeService.emit("OpenUrl", url)
        }
        else
            window.open(link, '_blank');
    }

    openAlert(message: string, title: string = '', okButtonText: string = '') {
        okButtonText = okButtonText ? okButtonText : "OK"

        if (this.nsBridgeService.isBridge())
            this.nsBridgeService.emit("OpenAlert", { title: title, message: message, okButtonText: okButtonText })
        else
            bootbox.alert({ title: title, message: message, buttons: { ok: { label: okButtonText, className: 'lavender-lite-btn' } } });
    }

    openConfirm(message: string, callbackFunc: Function, yesButtonText: string = '', noButtonText: string = '') {
        yesButtonText = yesButtonText ? yesButtonText : "TAK"
        noButtonText = noButtonText ? noButtonText : "NIE"

        bootbox.dialog({
            closeButton: false,
            message: message,
            buttons: {
                success: { label: "TAK", className: 'green-btn', callback: () => callbackFunc(true) },
                cancel: {
                    label: "NIE", className: 'lavender-lite-btn', callback: () => callbackFunc(false)
                }
            }
        });
    }

    openPrompt(message: string, title: string, callbackFunc: Function, yesButtonText: string = '', noButtonText: string = '') {
        yesButtonText = yesButtonText ? yesButtonText : "TAK"
        noButtonText = noButtonText ? noButtonText : "NIE"

        return bootbox.prompt({
            closeButton: false,
            message: message,
            title: `<h1>${title}</h1>`,
            buttons: {
                confirm: { label: "TAK", className: 'green-btn' },
                cancel: { label: "NIE", className: 'lavender-lite-btn' }
            },
            callback: callbackFunc
        });
    }

    openLogin(callbackFunc: Function, yesButtonText: string = '', noButtonText: string = '') {
        yesButtonText = yesButtonText ? yesButtonText : "TAK"
        noButtonText = noButtonText ? noButtonText : "NIE"

        bootbox.dialog({
            closeButton: false,
            message: "<div class='center'>\
            Login: <input id='login' type='text' name='first_name' class='bootbox-input bootbox-input-text form-control' /><br/><br/>\
            Hasło: <input id='password' type='password' name='last_name' class='bootbox-input bootbox-input-text form-control' /> <div/>",
            inputType: 'password',
            buttons: {
                success: {
                    label: "ZALOGUJ", className: 'green-btn', callback: () => {
                        return callbackFunc({ login: $('#login').val(), password: $('#password').val() });
                    }
                },
                cancel: {
                    label: "ANULUJ", className: 'lavender-lite-btn', callback: () => callbackFunc(false)
                }
            }
        });
    }
}