import { Component } from '@angular/core';
import { NotificationService } from "../../services/notification.service";
import { BaseComponent } from '../../core/base/base/base.component';
import { NotificationEntity } from '../../generated/DomoticzApiClient';
import { UtilsDate } from '../../utils/utils-date';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent extends BaseComponent {

  public message: string;
  public history: any = [];

  constructor(
    private notificationService: NotificationService) {
    super();

      let userName = this.userName;
      if (userName == 'Admin')
        userName = 'Dawid';

    this.api.apiNotificationGetNotifications(userName).then(x =>{
      this.history = x;
    });
  }

  public sendTo(userName: string, title: string) {
    let model = { message: this.message, user: userName, title: title, returnUrl: 'notification' } as any;
    this.notificationService.sendFirebaseNotification(model).then(() => {

      this.utilsService.openAlert(`Wysłano powiadomienie do ${userName}`);
      this.message = null;
    }, () => {
      this.utilsService.openAlert(`Nie udało się wysłać powiadomienia do ${userName}`);
    });
  }

  public isEnabled() {
    return this.message?.length > 0;
  }
}
