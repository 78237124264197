<div *ngIf="isShow" class="form">
  <h1>F</h1>
  <a routerLink="/cigar/{{fDateString}}" href="#/cigar/{{fDateString}}">{{fResult}}</a>
  <hr />
  <h1>M</h1>
  {{mResult}}
  <!-- <a routerLink="/seed/{{mDateString}}" href="#/cigar/{{mDateString}}">{{mResult}}</a> -->
  <hr />
  <!-- <h1>A</h1>
  {{aResult}}
  <hr /> -->
</div>
<p *ngIf="!isShow">
  Brak autoryzacji!
</p>