<div class="form">

  <app-header-for-module text="Ostatnie Zdjecia" src="/assets/icons/onedrive/onedrive.png"></app-header-for-module>

  <app-progress-spinner [isShow]="!response"></app-progress-spinner>

  <div *ngIf="response">

    <mat-card class="mb-2 center">
      <b> Madzia 🥰</b>
      <mat-card-content>
        <div class="item center">
          Magda: <b>{{response.madziaMagda}}</b>
          <br />
          Dawid: <b>{{response.madziaDawid}}</b>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="mb-2 center">
      <b>Junior 👶</b>
      <mat-card-content>
        <div class="item center">
          Magda: <b>{{response.juniorMagda}}</b>
          <br />
          Dawid: <b>{{response.juniorDawid}}</b>
        </div>
      </mat-card-content>
    </mat-card>

    <hr />

    <div class="row mt-5">
      <button class="col-sm p-10 lavender-black-btn" mat-raised-button color="primary"
        (click)="synchronize()">Synchronizuj</button>
      <button class="col-sm p-10 lavender-btn" mat-raised-button color="warn" (click)="openJuniorFolder()">Junior
        👶</button>
      <button class="col-sm p-10 lavender-btn" mat-raised-button color="warn" (click)="openMadziaFolder()">Madzia
        🥰</button>
      <button class="col-sm p-10 lavender-btn" mat-raised-button color="warn" (click)="openMadziaCiazowaFolder()">Madzia
        Ciążowa 🤰</button>
      <button class="col-sm p-10 green-btn" mat-raised-button color="primary" (click)="back()">Wróć</button>
    </div>

  </div>