<div class="form">
  <app-header-for-module [text]="getModuleName()" src="/assets/icons/downloader/downloader.png"></app-header-for-module>

  <mat-form-field class="example-full-width">
    <mat-label>Link</mat-label>
    <input matInput required placeholder="Link" [(ngModel)]="model.link" (blur)="onLinkBlur()">
  </mat-form-field>

  <mat-form-field class="example-full-width">
    <mat-label>Nazwa Pliku</mat-label>
    <input matInput placeholder="Nazwa Pliku" [(ngModel)]="model.fileName" (blur)="onFileNameBlur()">
  </mat-form-field>

  <mat-form-field class="example-full-width">
    <mat-label>Nazwa folderu</mat-label>
    <input type="text" placeholder="Nazwa Folderu" aria-label="Folder" matInput [formControl]="folderControl"
      [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredFolders | async; trackBy: trackByIndex" [value]=" option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field class="example-full-width">
    <mat-label>Ścieżka zapisu</mat-label>
    <select matNativeControl required [(ngModel)]="model.saveLocation" (change)="getFolders(true)">
      <option value="0">Inne/youtube-dl</option>
      <option value="1">Filmy</option>
      <option value="2">Horrory</option>
      <option value="3">Animowane</option>
      <option value="4">Seriale</option>
      <option value="5">Muzyka</option>
    </select>
  </mat-form-field>

  <mat-form-field class="example-full-width" *ngIf="getVisibleQualitySection()">
    <mat-label>Jakość</mat-label>
    <select matNativeControl [(ngModel)]="model.quality">
      <option value=""></option>
      <option value="360p">360p</option>
      <option value="480p">480p</option>
      <option value="720p">720p</option>
      <option value="1080p">1080p</option>
    </select>
  </mat-form-field>


  <div class="row mt-5">
    <button class="col-sm p-10 lavender-black-btn" [disabled]="checkButtonDisbaled()" (click)="save()" mat-raised-button
      color="primary">Zapisz</button>

    <a class="col-sm p-10 lavender-btn" routerLink="/moviedb" routerLinkActive="active" mat-raised-button
      color="warn">Pomocnik Filmowy</a>

    <button class="col-sm p-10 lavender-btn" data-bs-target="#settings" data-bs-toggle="offcanvas"
      aria-controls="offcanvasTop" mat-raised-button color="warn">Ustawienia</button>

    <button *ngIf="false" class="col-sm p-10 lavender-btn" mat-raised-button color="warn"
      (click)="openRapideo()">Rapideo</button>


    <button class="col-sm p-10 lavender-btn" (click)="clear()" mat-raised-button color="warn">Wyczyść</button>
    <button class="col-sm p-10 lavender-btn" (click)="clearDownloadList()" mat-raised-button color="warn">Wyczyść
      Historię</button>
    <button class="col-sm p-10 lavender-btn" (click)="refreshLibrary()" mat-raised-button color="warn">Odśwież
      bibliotekę</button>

  </div>

  <br /><br />

  <div *ngIf="lastModels.length > 0">

    <h1>Historia ({{lastModels.length}})</h1>
    <hr />

    <div *ngFor="let m of lastModels; trackBy: trackByIndex" class="row">

      <span class="col-sm" style="width:96%; overflow: hidden;">
        Nazwa pliku: <b>{{m.fileName}}</b>
      </span>

      <span class="col-sm d-inline d-sm-none" style="width:96%; overflow: hidden;">
        Nazwa folderu: <b>{{m.folderName || m.fileName}}</b>
      </span>

      <span class="col-sm d-inline d-sm-none" *ngIf="true" style="width:96%; overflow: hidden;">
        Ścieżka: <b>{{saveLocationItems[m.saveLocation]}}</b>
      </span>

      <span class="col-sm" style="width:96%; overflow: hidden;">
        Link: <a href="{{m.link}}">{{m.link}}</a>
      </span>

      <span class="col-sm" style="width:96%; overflow: hidden;">
        <span *ngIf="m.percetage?.indexOf('ETA') > 0">Pobrano:</span>
        {{m.percetage}}
      </span>

      <div class="col-sm" style="width:96%; overflow: hidden;">

        <div class="d-inline d-sm-none">
          Pobrano
        </div>
        <span *ngIf="m.status" class="icon-x-large d-inline">
          <i class="fa ms-2 fa-check green"></i>
        </span>
        <span *ngIf="!m.status" class="icon-x-large d-inline">
          <i class="fa ms-2 fa-times red"></i>
        </span>
      </div>

      <div class="col-sm">
        <div class="d-inline d-sm-none">
          Wczytaj
        </div>
        <div (click)="reTry(m)" class="me-4 icon-x-large cursor-pointer d-inline">
          <i class="fas fa-sync gold-and-hover"></i>
        </div>

        <div class="d-inline d-sm-none">
          Usuń
        </div>
        <div (click)="delete(m)" class="me-4 icon-x-large cursor-pointer d-inline">
          <i class="fa fa-trash grey-and-hover"></i>
        </div>

        <div class="d-inline d-sm-none">
          Dziennik
        </div>
        <div (click)="showDetails(m)" class="icon-x-large cursor-pointer d-inline" data-bs-target="#log"
          data-bs-toggle="offcanvas" aria-controls="offcanvasTop">
          <i class="fas fa-sticky-note"></i>
        </div>
      </div>
      <hr />
    </div>
  </div>

  <app-progress-spinner [isShow]="!isDownloaded"></app-progress-spinner>

  <mat-card *ngIf="lastModels.length === 0 && isDownloaded" class="center mb-5">
    <mat-card-content>
      Historia jest pusta 🥰
    </mat-card-content>
  </mat-card>

</div>

<div class="offcanvas offcanvas-top" tabindex="-1" id="settings" aria-labelledby="offcanvasTopLabel">
  <div class="offcanvas-header">
    <h1 id="offcanvasTopLabel">Ustawienia</h1>
    <button type="button" class="btn-close text-reset red" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">

    <div class="form">
      <div class="container">

        <div class="row justify-content-md-center">
          <div class="col-10 ">
            Pobieranie <b>MULTI</b>
          </div>
          <div class="col-2 col-sm-2">
            <mat-checkbox [checked]="settings.isDownloadMulti"
              (change)="onChange(settings.isDownloadMulti, 'isDownloadMulti')"></mat-checkbox>
          </div>
          <hr class="col-sm-12" />
        </div>

        <div class="row justify-content-md-center">
          <div class="col-10 ">
            Download <b>Async</b>
          </div>
          <div class="col-2 col-sm-2">
            <mat-checkbox [checked]="settings.isDownloadAsync"
              (change)="onChange(settings.isDownloadAsync, 'isDownloadAsync')"></mat-checkbox>
          </div>
          <hr class="col-sm-12" />
        </div>

      </div>
    </div>
  </div>
</div>


<div class="offcanvas offcanvas-top logs" tabindex="-1" id="log" aria-labelledby="offcanvasTopLabel">
  <div class="offcanvas-header">
    <h1 id="offcanvasTopLabel">Log</h1>
    <button type="button" class="btn-close text-reset red" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div #content class="offcanvas-body" style="width:96%; overflow-x: hidden;">

    <div
      *ngFor="let log of logs; index as i; first as isFirst; even as even; odd as odd; last as isLast; trackBy: trackByIndex">
      <span class="col-sm" style="width:96%; overflow-x: hidden;">
        <b>{{i}}</b>. {{log}}
      </span>
    </div>

  </div>
</div>