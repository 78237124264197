<div class="form">
  <app-header-for-module text="Tak czy nie?" src="/assets/icons/yes-no/yes-no.png"></app-header-for-module>

  <img (click)="random()" class="backgroud img-fluid mx-auto d-block pb-3" [src]="response.image" width="400px" />

  Odpowiedź to:

  <div class="center" *ngIf="response.answer == 'no'">
    <h1>Nie</h1>
  </div>

  <div class="center" *ngIf="response.answer == 'yes'">
    <h1>Tak</h1>
  </div>

</div>