import { orderBy } from "lodash-es";
import { ReceiptModel } from "./receipt.model";

export class ReceiptsVM {

    public receipts: Array<ReceiptModel> = new Array<ReceiptModel>();
    public step: number = 0;

    constructor() {
        this.receipts.push(new ReceiptModel("Szpinak", "szpinak"));
        this.receipts.push(new ReceiptModel("Carbonara", "carbonara"));
        this.receipts.push(new ReceiptModel("Curry", "curry"));
        this.receipts.push(new ReceiptModel("Pizza", "pizza"));
        this.receipts.push(new ReceiptModel("Placki ziemniaczane", "plackiziemniaczane"));
        this.receipts.push(new ReceiptModel("Naleśniki", "nalesniki"));
        this.receipts.push(new ReceiptModel("Gofry", "gofry"));
        this.receipts.push(new ReceiptModel("Cytrynowo-waniliowa tratwa", "cytrynowowaniliowatratwa"));
        this.receipts.push(new ReceiptModel("Ciasto 3 bit bez pieczenia", "trzybit"));
        this.receipts.push(new ReceiptModel("Zupa cygańska", "zupacyganska"));
        this.receipts.push(new ReceiptModel("Zupa meksykańska", "zupameksykanska"));
        this.receipts.push(new ReceiptModel("Zupa szpinakowa", "zupaszpinakowa"));

        this.receipts = orderBy(this.receipts, x => x.name);
    }
}
