<div class=" form mb-5 mt-3" *ngIf="products || isError">

  <div class="row">
    <div class="col-10">
      <div class="m-3">
        <img class="inline cursor-pointer" src="/assets/icons/shop-list/shop-list.png" width="40px"
          [attr.data-bs-target]="'#settings'" data-bs-toggle="offcanvas" aria-controls="offcanvasTop" />
        <h1 (dblclick)="sendNotificationDblClick()" class="inline ms-3 sofia-font cursor-pointer">Lista zakupów</h1>
      </div>
    </div>
  </div>



  <div class="row">
    <div class="col-10 col-sm-11">
      <mat-form-field class="example-full-width">
        <mat-label>Nowy</mat-label>
        <input matInput [(ngModel)]="newProduct" [readonly]="isError" (keyup.enter)="add()" />
      </mat-form-field>
    </div>
    <div class="col-2 col-sm-1 mt-3 cursor-pointer" *ngIf=" !isError">
      <span class="x-large" *ngIf="canAdd" (click)="add()" [ngClass]="true ? 'green-and-hover' : 'grey'">
        <i class="fas fa-plus"></i>
      </span>
    </div>
  </div>

  <br />
  <br />

  <!-- <div class="m-5 center red" *ngIf="isLocalStorageData && !isOfflineMode()">
    <small>Awaryjny tryb!! Dane pobrane lokalnie, mogą być niekompletne! Przed zmianami proszę poczekać aż komunikat
      zniknie, w przeciwnym wypadku zmiany zostaną utracone!</small>
  </div> -->

  <app-progress-spinner [isShow]="!isError && !isDownloadedData"></app-progress-spinner>

  <mat-card class="mb-2" *ngFor="let product of products; trackBy: trackByProduct" (dblclick)="openEdit(product)">
    <mat-card-content>
      <div class="row item" *ngIf="!product.editMode">
        <div class="col">
          {{product.value | uppercase}}
        </div>
        <div class="col-2 red-and-hover" (click)="remove(product)">
          <i class="fas fa-times ml-10"></i>
        </div>
      </div>

      <div class="row" *ngIf="product.editMode">
        <div class="col-10">
          <mat-form-field class="example-full-width">
            <mat-label>Edytowanie</mat-label>
            <input matInput [(ngModel)]="product.tmpValue" (keyup.enter)="updateEdit(product)" />
          </mat-form-field>
        </div>
        <div class="col">
          <span (click)="updateEdit(product)">
            <i class="fas fa-check item m-3 cursor-pointer green-and-hover x-large"></i>
          </span>
          <span (click)="cancelEdit(product)">
            <i class="fas fa-undo-alt item m-3 cursor-pointer red-and-hover x-large cancel-edit"></i>
          </span>
        </div>
      </div>

    </mat-card-content>
  </mat-card>

  <mat-card *ngIf=" products?.length==0 && !isError && isDownloadedData" class="center">
    <mat-card-content>
      Lista zakupów jest pusta 🥰
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="isError" class="center">
    <mat-card-content>
      Brak połączenia z bazą danych ❌
    </mat-card-content>
  </mat-card>

  <div class="mt-100">
  </div>

  <div *ngIf="productsTryBuy.length > 0">
    <mat-card class="mb-2 header-try-buy" *ngIf="settings.isShowTryBuy">
      <mat-card-content>
        <div class="row item">
          <div class="col-10" data-bs-toggle="collapse" data-bs-target="#collapseTryBuy" aria-expanded="false"
            aria-controls="collapseTryBuy">
            <b>Do kupienia przy okazji</b>
          </div>
          <div class="col">
            <span>
              <!-- <i class="fas fa-times ml-10 red bigger"></i> -->
            </span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="mb-2 try-buy collapse" [ngClass]="{'show': settings.isShowTryBuy && settings.expandTryBuy}"
      id="collapseTryBuy" *ngFor="let product of productsTryBuy; trackBy: trackByProduct"
      (dblclick)="openEdit(product)">
      <mat-card-content>
        <div class="row item" *ngIf="!product.editMode">
          <div class="col">
            {{product.value | uppercase}}
          </div>
          <div class="col-1 me-3">
            <div (click)="backProduct(product)">
              <i class="fas fa-undo ml-10 green-and-hover"></i>
            </div>

          </div>
          <div class="col-2 red-and-hover" (click)="removeFromHistory(product)">
            <i class="fas fa-times ml-10"></i>
          </div>
        </div>

        <div class="row" *ngIf="product.editMode">
          <div class="col-10">
            <mat-form-field class="example-full-width">
              <mat-label>Edytowanie</mat-label>
              <input matInput [(ngModel)]="product.tmpValue" (keyup.enter)="updateEdit(product)" />
            </mat-form-field>
          </div>
          <div class="col">
            <span (click)="updateEdit(product)">
              <i class="fas fa-check item m-3 green-and-hover x-large cursor-pointer"></i>
            </span>
            <span (click)="cancelEdit(product)">
              <i class="fas fa-undo-alt item m-3 red-and-hover cancel-edit x-large cursor-pointer"></i>
            </span>
          </div>
        </div>


      </mat-card-content>
    </mat-card>
  </div>

  <div *ngIf="productsOld.length > 0">
    <mat-card class="mb-2 header" *ngIf="settings.isBought">
      <mat-card-content>
        <div class="row item">
          <div class="col-10" data-bs-toggle="collapse" data-bs-target="#collapseBought" aria-expanded="false"
            aria-controls="collapseBought">
            <b>Zakupione</b>
          </div>
          <div class="col">
            <span (click)="clearHistory()">
              <i class="fas fa-times ml-10 red-and-hover"></i>
            </span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="mb-2 bought collapse" [ngClass]="{'show': settings.isBought && settings.expandBought}"
      id="collapseBought" *ngFor="let product of productsOld; trackBy: trackByProduct" (dblclick)="openEdit(product)">
      <mat-card-content>
        <div class="row item" *ngIf="!product.editMode">
          <div class="col">
            {{product.value | uppercase}}
          </div>
          <div class="col-2" (click)="backProduct(product)">
            <i class="fas fa-undo ml-10 green-and-hover "></i>
          </div>
          <div class="col-2" (click)="moveToTryBuy(product)" *ngIf="settings.isShowTryBuy">
            <i class="fas fa-shopping-cart ml-10"></i>
          </div>
          <div class="col-2 red-and-hover" (click)="removeFromHistory(product)">
            <i class="fas fa-times ml-10"></i>
          </div>
        </div>

        <div class="row" *ngIf="product.editMode">
          <div class="col-10">
            <mat-form-field class="example-full-width">
              <mat-label>Edytowanie</mat-label>
              <input matInput [(ngModel)]="product.tmpValue" (keyup.enter)="updateEdit(product)" />
            </mat-form-field>
          </div>
          <div class="col">
            <span (click)="updateEdit(product)">
              <i class="fas fa-check item m-3 green-and-hover x-large cursor-pointer"></i>
            </span>
            <span (click)="cancelEdit(product)">
              <i class="fas fa-undo-alt item m-3 red-and-hover cancel-edit x-large cursor-pointer"></i>
            </span>
          </div>
        </div>

      </mat-card-content>
    </mat-card>
  </div>


</div>


<div class="offcanvas offcanvas-top" tabindex="-1" id="settings" aria-labelledby="offcanvasTopLabel">
  <div class="offcanvas-header">
    <h1 id="offcanvasTopLabel">Ustawienia</h1>
    <button type="button" class="btn-close text-reset red" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">

    <div class="form">
      <div class="container">

        <div class="row justify-content-md-center">
          <div class="col-10 ">
            Pokazuj <b>Do kupienia przy okazji</b>
          </div>
          <div class="col-2 col-sm-2">
            <mat-checkbox [checked]="settings.isShowTryBuy"
              (change)="onChange(settings.isShowTryBuy, 'isShowTryBuy')"></mat-checkbox>
          </div>
          <hr class="col-sm-12" />
        </div>

        <div class="row justify-content-md-center">
          <div class="col-10 ">
            Pokazuj <b>Zakupione</b>
          </div>
          <div class="col-2 col-sm-2">
            <mat-checkbox [checked]="settings.isBought"
              (change)="onChange(settings.isBought, 'isBought')"></mat-checkbox>
          </div>
          <hr class="col-sm-12" />
        </div>

        <div class="row justify-content-md-center">
          <div class="col-10 ">
            Pokazuj <b>Dzwonek powiadomień</b>
          </div>
          <div class="col-2 col-sm-2">
            <mat-checkbox [checked]="settings.isBellNotification"
              (change)="onChange(settings.isBellNotification, 'isBellNotification')"></mat-checkbox>
          </div>
          <hr class="col-sm-12" />
        </div>

        <div class="row justify-content-md-center">
          <div class="col-10 ">
            Rozwiń <b>Do kupienia przy okazji</b>
          </div>
          <div class="col-2 col-sm-2">
            <mat-checkbox [checked]="settings.expandTryBuy"
              (change)="onChange(settings.expandTryBuy, 'expandTryBuy')"></mat-checkbox>
          </div>
          <hr class="col-sm-12" />
        </div>

        <div class="row justify-content-md-center">
          <div class="col-10 ">
            Rozwiń <b>Zakupione</b>
          </div>
          <div class="col-2 col-sm-2">
            <mat-checkbox [checked]="settings.expandBought"
              (change)="onChange(settings.expandBought, 'expandBought')"></mat-checkbox>
          </div>
          <hr class="col-sm-12" />
        </div>


      </div>

    </div>

  </div>
</div>

<div class="m-5 center red" *ngIf="isLocalStorageData $$ !isOfflineMode()">
  <small>Awaryjny tryb!! Dane pobrane lokalnie, mogą być niekompletne! Przed zmianami proszę poczekać aż komunikat
    zniknie, w przeciwnym wypadku zmiany zostaną utracone!</small>
</div>