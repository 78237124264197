import { Component } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { BaseComponent } from '../core/base/base/base.component';
import { environment } from '../environments/environment';
import { random } from 'lodash-es';

declare let ga: any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent {

	public showHeader: boolean = true;
	public layoutFileName: string = 'lawenda2.jpg';

	constructor() {
		super();
		super.setTitle(`Dev IT v. ${environment.version}`);


		if (random(2) == 1)
			this.layoutFileName = 'lawenda.jpg';
		if (random(2) == 2)
			this.layoutFileName = 'cytryna.jpg';

		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd)
				this.isShowHeader(event.url);
		});
	}

	isShowHeader(url: string) {
		if (url.indexOf('nh') > -1)
			this.showHeader = false;
		else
			this.showHeader = true;
	}
}
