import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { NotesModel } from "../notes/models/notes.model";
import { BaseComponent } from '../../core/base/base/base.component';
import { UtilsUser } from '../../utils/utils-user';
import { GenreListResponse_Genre, MovieListResponse_Root, MoviesToDownloadModel, MovieRequest, ESaveLocation, DictionaryEntity } from '../../generated/DomoticzApiClient';
import { UtilsDate } from '../../utils/utils-date';
import { cloneDeep } from 'lodash-es';

@Component({
	selector: 'app-the-movie-db',
	templateUrl: './the-movie-db.component.html',
	styleUrls: ['./the-movie-db.component.scss']
})
export class TheMovieDbComponent extends BaseComponent implements OnInit {

	public userName: string = UtilsUser.getUserName();
	public genreList: GenreListResponse_Genre[];
	public modelPagging: MovieListResponse_Root;
	public model: MoviesToDownloadModel[];
	public request: MovieRequest = new MovieRequest();
	public yearList: any;
	public rateList: any;

	public similarModel: any = {};

	constructor(
		private clipboard: Clipboard) {

		super();
		super.setTitle(`Filmowy Pomocnik - Dev IT`);
		this.getRequest();
	}

	getRequest() {
		let request = sessionStorage.getItem('movieDbRequest');
		if (!request) {
			this.initRequest();
		} else {
			try {
				this.request = JSON.parse(request);
			} catch (e) {
				sessionStorage.removeItem('movieDbRequest');
				this.initRequest();
			}
		}
	}

	initRequest() {
		this.request.voteAverageGte = "5";
		this.request.year = UtilsDate.new().year();
		this.request.page = 1;
		this.request.genre = 27;
		this.request.sort = 'popularity.desc';
		this.request.useSaveLocation = true;
		this.request.saveLocation = ESaveLocation._2;
		this.request.useListToDownload = true;
		this.request.showAllMovies = false;
		this.request.hideDownloaded = false;
	}

	ngOnInit() {
		this.getYearList();
		this.getRateList();
		this.getGenreList();
		this.search();
	}

	getGenreList() {
		this.api.apiTheMovieDbGetGenreList().then(x => {
			this.genreList = x.genres;
		});
	}

	openInBlank(link: string) {
		this.utilsService.openUrl(link);
	}

	addToDownloadList(el: MoviesToDownloadModel) {
		el.existOnListToDownload = true;
		let model = new DictionaryEntity(new NotesModel(`${el.data.title} (${this.getYear(el.data.release_date)})`, 'NotesCategory-FILMY DO POBRANIA', null));
		this.api.apiDictionaryLiteDbAdd(model).then(() => {
			el.existOnListToDownload = true;
		})
	}

	removeFromDownloadList(el: MoviesToDownloadModel) {
		let request = {} as any;
		request.context = 'NotesCategory-FILMY DO POBRANIA';
		request.value = `${el.data.title} (${this.getYear(el.data.release_date)})`;
		this.api.apiDictionaryLiteDbRemoveByContextAndValue(request.context, request.value).then(x => {
			if (x)
				el.existOnListToDownload = false;
		})
	}

	openRapideoAndCopy(link: string) {
		if (this.userName == 'Dawid' || this.userName == 'Admin') {
			this.clipboard.copy(link);
			this.utilsService.openUrl('https://www.rapideo.net/wyszukiwarka');
		}
	}

	nextPage() {
		if (this.request.page < this.modelPagging.total_pages) {
			this.request.page++;
			this.search();
		}
	}

	previousPage() {
		if (this.request.page > 1) {
			this.request.page--;
			this.search();
		}
	}

	nextPageSub() {
		if (this.similarModel.data.root.page < this.similarModel.data.root.total_pages) {
			this.similarModel.data.root.page++;
			this.searchSub();
		}
	}

	previousPageSub() {
		if (this.similarModel.data.root.page > 1) {
			this.similarModel.data.root.page--;
			this.searchSub();
		}
	}

	searchAndReset() {
		if (this.request.page > this.modelPagging?.total_pages || this.request.page < 1)
			this.request.page = 1;
		this.search();
	}

	searchSub() {
		if (this.similarModel?.data?.moviesToDownload)
			this.similarModel.data.moviesToDownload = null;

		if (this.similarModel.isSimilar)
			this.getSimilar(this.similarModel.element);
		else
			this.getRecomendations(this.similarModel.element);
	}

	search() {
		if (this.request.genre == 27 || this.request.genre == 53)
			this.request.saveLocation = ESaveLocation._2;
		else if (this.request.genre == 16)
			this.request.saveLocation = ESaveLocation._3;
		else
			this.request.saveLocation = ESaveLocation._1;

		sessionStorage.setItem('movieDbRequest', JSON.stringify(this.request));
		this.model = null;
		return this.api.apiTheMovieDbGetMoviesToDownload(this.request).then(x => {
			console.log(x);
			this.modelPagging = x.root;
			this.model = x.moviesToDownload;
		})
	}

	getSimilar(el: MoviesToDownloadModel, reset: boolean = false) {

		if (reset)
			this.similarModel = {};

		this.similarModel.isSimilar = true;
		this.similarModel.element = el;

		this.similarModel.name = `Filmy podobne do`;
		this.similarModel.movie = `${el.data.title} (${this.getYear(el.data.release_date)})`;

		let request = cloneDeep(this.request);
		request.page = this.similarModel?.data?.root?.page;
		request.idMovie = el.data.id;

		this.api.apiTheMovieDbGetSimilarPost(request).then(x => {
			this.similarModel.data = x;
		});
	}

	getRecomendations(el: MoviesToDownloadModel, reset: boolean = false) {

		if (reset)
			this.similarModel = {};

		this.similarModel.isSimilar = false;
		this.similarModel.element = el;

		this.similarModel.name = `Filmy rekomendowane do`;
		this.similarModel.movie = `${el.data.title} (${this.getYear(el.data.release_date)})`;

		let request = cloneDeep(this.request);
		request.page = this.similarModel.data?.root?.page;
		request.idMovie = el.data.id;

		this.api.apiTheMovieDbGetRecommendationsPost(request).then(x => {
			this.similarModel.data = x;
		});
	}

	getYear(date: string) {
		return date.split('-')[0];
	}

	getYearList() {
		var res = [];
		for (let i = 2000; i <= UtilsDate.new().year(); i++)
			res.push({ id: i.toString(), name: i.toString() });
		res.push({ id: '1990', name: "Wszystkie" });

		this.yearList = res;
	}

	getRateList() {
		var res = [];
		for (let i = 1; i <= 10; i = i + 0.5)
			res.push({ id: i.toString(), name: i.toString() });

		this.rateList = res;
	}
}


