<div *ngIf="isShow" style="padding-top: 40px;">
	<img src="/assets/images/wakacje.jpg" alt="Wakacje 2024" (click)="back()" class=" img-fluid mx-auto d-block" />
	<br />
	<div class="center">
		<h1><span class="red">❤</span> Wakacje <span class="red">❤</span></h1>
		<h2>06.07.2024</h2>
	</div>
	<hr />
</div>
<div class="center">
	<h2>
		<app-calc-from-date date="202407060000" showWeek="true" (output)="outputEvent()">
		</app-calc-from-date>
	</h2>
</div>
<hr *ngIf="isShow" />