import { Component, OnInit } from '@angular/core';
import { UtilsDate } from '../../utils/utils-date';
import { UtilsUser } from "../../utils/utils-user";
import { NotificationService } from "../../services/notification.service";
import { CalendarVM } from "./models/calendarVM";
import { CalendarItem } from '../../generated/DomoticzApiClient';
import { BaseComponent } from '../../core/base/base/base.component';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent extends BaseComponent implements OnInit {

  public vm: CalendarVM = new CalendarVM();

  constructor(
    public ns: NotificationService) {
    super();
    super.setTitle("Kalendarz - Dev IT")
  }

  ngOnInit() {
    if (UtilsUser.userIsNotDavid())
      this.ns.sendFirebaseNotificationInline("Dawid", `Sprawdzany kalendarz przez ${this.userName}`);

      this.api.apiCalendarGetCalendar()
        .then(x => {
          this.vm.calendarElements = x;
          this.saveInLocalStorage('CalendarData', x);
        }, () => {
            this.getDataFromlocalStorage();
        }).catch(e => {
          this.getDataFromlocalStorage();
        });
  }

  public getDataFromlocalStorage(){
    let data = this.loadFromLocalStorage('CalendarData');
    if (data)
      this.vm.calendarElements = data;
  }

  public sendNotification(item: CalendarItem) {
    this.api.apiCalendarNotifyElement(item);
  }

  public getDayofWeek(date: string) {
    return UtilsDate.getDayofWeek(date);
  }

  public getMonth(date: string) {
    return UtilsDate.getMonth(date);
  }
}
