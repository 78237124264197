import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Subscription, interval } from 'rxjs';
import { LinkService } from '../../services/link.service';
import { BaseComponent } from '../../core/base/base/base.component';
import { environment } from '../../environments/environment';
import { UtilsDate } from '../../utils/utils-date';

@Component({
  selector: 'app-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss']
})
export class FrameComponent extends BaseComponent implements OnInit, OnDestroy {

  private s: Subscription;
  private sDate: Subscription;
  public urlPhoto: string;
  public actualDate: any;
  public dayOfWeek: string;
  public date: string;
  public isShowDashticz = false;
  public isRotated = false;
  public imageClass: string;
  public folderName: string;
  public weather: string;

  public count = 0;
  public currentState: string;
  public iFrameUrl: any;

  @HostListener('window:keydown', ['$event'])
  keyEvent($event: KeyboardEvent) {
    console.log($event);

    if ($event.key === "ArrowRight")
      this.logic();

    if ($event.key === "ArrowLeft")
      this.logic();
  }

  constructor(
    public httpClient: HttpClient,
    public linkService: LinkService,
    public sanitizer: DomSanitizer) {
    super();
    super.setTitle(`Madzia i Dawid 🥰 - Dev IT`);
    this.logic();

    this.iFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.url}:805/index3.html`);

    this.route.params.subscribe(x => {
      if (x.id === "2")
        this.isShowDashticz = true;
    });
  }

  ngOnInit() {
    this.s = interval(12000).subscribe(x => {
      this.logic();
    });

    this.sDate = interval(1000).subscribe(x => {
      this.actualDate = UtilsDate.new().format("HH:mm:ss");
      this.date = UtilsDate.new().format('DD/MM/YYYY');
      this.dayOfWeek = UtilsDate.getDayofWeek(UtilsDate.new().format('dddd'));
    });
  }

  onImageLoad(evt) {
    if (evt && evt.target) {

      setTimeout(() => {
        this.imageClass = 'parallax-slider';
      }, 500);
    }
  }

  getIFrameUrl() {
    return this.sanitizer.bypassSecurityTrustUrl(this.iFrameUrl);
  }

  getImage() {
    if (!this.urlPhoto)
      return null;

    if (!environment.production)
      return "http://192.168.1.116" + this.urlPhoto.replace("mnt/ScyllaOne/Multimedia/Zdjęcia/! Zdjęcia Dawida/Madzia ❤", "assets/Madzia");

    return this.urlPhoto.replace("mnt/ScyllaOne/Multimedia/Zdjęcia/! Zdjęcia Dawida/Madzia ❤", "assets/Madzia");
  }

  logic() {
    this.temperature();

    this.imageClass = 'transitioning-src';
    this.isRotated = false;
    if (this.isShowDashticz)
      this.count++;

    if (this.count > 5)
      this.count = this.count % 5;

    this.api.apiFrameRandomPhoto(true).then(x => {
      this.urlPhoto = x.path;
      const fa = this.urlPhoto.split('/');
      this.folderName = fa[fa.length - 2];
    });
  }

  weatherClick() {
    if (this.isShowDashticz)
      this.count = 5;
  }

  temperature() {
    this.api.apiDomoticzGetDeviceVariable(233).then(x => this.weather = x.data);
  }

  ngOnDestroy(): void {
    this.s?.unsubscribe();
    this.sDate?.unsubscribe();
    super.ngOnDestroy();
  }
}
