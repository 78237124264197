<div class="m-3 center">
	<h1 class="sofia-font">Kalendarz Marcelka </h1>
</div>

<img (click)="randomImage()" class="backgroud img-fluid mx-auto d-block"
	src="/assets/icons/baby/icon{{vm.iconIndex}}.png" width="100px" />

<hr />

<ul>
	<li>04.01.2022 - Ostatni dzień miesiączki</li>
	<li>17.01.2022 - Dlaczego Nie i wino truskawkowe</li>
	<li>18.01.2022 - Zapłodnienie jajeczka</li>
	<li>29.01.2022 - Pierwszy test ciążowy</li>
	<li>31.01.2022 - Trzeci test ciążowy w dzień przewidywanego okresu</li>
	<li class="cursor-pointer" (click)="openImage('assets/baby/1.jpg')">09.02.2022 - Pierwsza wizyta u ginekologa - 5
		tydz
		i 1
		dzień - 3 mm woreczek (owulacja z prawego jajnika)</li>
	<li class="cursor-pointer" (click)="openImage('assets/baby/2.jpg')">23.02.2022 - Druga wizyta u ginekologa - 7
		tydzień
		- 0,93
		cm</li>
	<li class="cursor-pointer" (click)="openImage('assets/baby/3.jpg')">16.03.2022 - Trzecia wizyta u ginekologa - 10
		tydzień i 2
		dni - 3,36 cm (spał junior)
	</li>
	<li class="cursor-pointer" (click)="openImage('assets/baby/4.jpg')">04.04.2022 - Prenatalne badanie o 16:15
		(całkowita
		dlugośc 9,6 cm)
	</li>
	<li>11.04.2022 - Prenatalne badanie - powtórka o 16:45 (całkowita dlugośc 12,8 cm)</li>
	<li class="cursor-pointer" (click)="openImage('assets/baby/5.jpg')">13.04.2022 - Czwarta wizyta u ginekologa</li>
	<li class="cursor-pointer" (click)="openImage('assets/baby/6.jpg')">11.05.2022 - Piąta wizyta u ginekologa</li>
	<li class="cursor-pointer" (click)="openImage('assets/baby/7.jpg')">16.05.2022 - Drugie prenatalne o 16:00</li>

	<li>08.06.2022 - Szósta wizyta u ginekologa</li>
	<li>29.06.2022 - Siódma wizyta u ginekologa</li>

	<li>22.07.2022 - Immunoglobulina</li>
	<li class="cursor-pointer" (click)="openImage('assets/baby/8.jpg')">25.07.2022 - Trzecie prenatalne o 16:00</li>
	<li>27.07.2022 - Ósma wizyta u ginekologa</li>
	<li>17.08.2022 - Dziewiąta wizyta u ginekologa</li>
	<li>07.09.2022 - Dziesiąta wizyta u ginekologa</li>
	<li>28.09.2022 - Jedenasta wizyta u ginekologa</li>
	<li>11.10.2022 - Wizyta w szpitalu w dniu terminu</li>

	<hr />
	<li>18.10.2022 - 1:13 - Rozpoczeły się regularne skurcze</li>
	<li>18.10.2022 - 4:45 - Zgłoszenie się w szpitalu, zostanie na oddziale</li>
	<li>18.10.2022 - 11:30 - Odwiedziny w szpitalu przez męża</li>
	<li>18.10.2022 - 12:45 - Odeszły wody płodowe przy badaniu</li>
	<li>18.10.2022 - 13:00 - Poród na porodówce</li>
	<li>18.10.2022 - 13:40 - Antybiotyk na GBS</li>
	<li>18.10.2022 - 14:09 - 8 cm rozwarcia</li>
	<li>18.10.2022 - 15:05 - 9 cm rozwarcia</li>
	<li>18.10.2022 - 15:26 - 10 cm rozwarcia</li>
	<li>18.10.2022 - 15:53 - Podanie oksytocyny</li>
	<li>18.10.2022 - 16:48 - Wzięcie na blok operacyjny</li>
	<li>18.10.2022 - 17:02 - Urodziny młodego</li>

	<hr />

	<li>20.10.2022 - Wyjście ze szpitala</li>
	<li>21.10.2022 - Pierwsza kąpiel</li>
	<li>25.10.2022 - Pierwsza wizyta położnej</li>
	<li>27.10.2022 - Sesja noworodkowa</li>
	<li>29.10.2022 - Pierwszy spacer</li>
	<li>09.11.2022 - Porzucenie kapturków laktacyjnych </li>
	<li>14.11.2022 - Powrót taty do pracy </li>
	<li>17.11.2022 - Badanie przez pediatre</li>
	<li>28.11.2022 - Bioderka</li>
	<li>08.12.2022 - Szczepienie 6w1</li>
	<li>22.12.2022 - Wizyta w poradni chirurgicznej w sprawie jąderka</li>
	<li>13.01.2022 - Pierwszy obrót z brzucha na plecy</li>
	<li>15.01.2023 - Chrzest</li>
	<li>16.01.2023 - Bioderka</li>
	<li>07.02.2023 - Szczepienie 6w1 - 2 dawka</li>
	<li>17.02.2023 - Pierwsza raz stopa do buzi</li>
	<li>18.02.2023 - Pierwszy obrót z pleców na brzuszek</li>
	<li>01.04.2023 - Pierwszy ząbek się przebił, prawa dolna jedynka</li>
	<li>03.04.2023 - Czołganie po macie niczym żołnierz zawodowy</li>
	<li>13.04.2023 - Szczepienie 6w1 - 3 dawka</li>
	<li>14.04.2023 - Pierwsza marcheweczka BIO</li>
	<li>15.04.2023 - Pierwsze autko Marcelka z Pepco</li>
	<li>16.04.2023 - Pierwsza marcheweczka ze słoiczka</li>
	<li>17.04.2023 - Bioderka</li>
	<li>18.04.2023 - Położna Środowiskowa na bilans pół roczny</li>
	<li>18.04.2023 - Pierwsza dynia ze słoiczka</li>
	<li>19.04.2023 - Pierwsza cukinia BIO</li>

	<li>21.04.2023 - Pierwszy spacerek w spacerówce</li>
	<li>23.04.2023 - Pierwsza wycieczka do Ustronia</li>
	<li>24.04.2023 - Pierwszy kalafiorek BIO</li>
	<li>25.04.2023 - Zjedzenie całego obiadku, marchewka + kalafior</li>
	<li>26.04.2023 - Otwieranie szuflad w salonie</li>
	<li>27.04.2023 - Jąderko w poradni chirurgicznej</li>
	<li>27.04.2023 - Pierwszy indyczek ze słoiczka</li>
	<li>03.05.2023 - Drugi ząbek sie przebił, lewa dolna jedynka</li>
	<li>03.05.2023 - Pierwsze wołanie mama w nocy</li>
	<li>06.05.2023 - Pierwszy basen młodego</li>
	<li>08.05.2023 - Drugi basen młodego, oswojony z wodą, nie płacze przy prysznicu</li>
	<li>08.05.2023 - Pierwszy deserek jabłko - marchew</li>
	<li>09.05.2023 - Wizyta w Muzeum Śląskim</li>
	<li>15.05.2023 - Wizyta u pediatry w sprawie skóry u Gorban</li>
	<li>16.05.2023 - Wizyta u pediatry w sprawie skóry u Skolik</li>
	<li>18.05.2023 - Pierwsze próby raczkowania</li>
	<li>24.05.2023 - Pierwsze picie wody z kubeczka</li>
	<li>29.05.2023 - Pierwsze picie wody z bidonu</li>
	<li>31.05.2023 - Pierwsze stanie w łóżeczku</li>
	<li>01.06.2023 - Pierwsze siad bez podparcia</li>
	<li>02.06.2023 - Trzeci ząbek, prawa górna jedynka</li>
	<li>03.06.2023 - Piersza wizyta młodego na wsi</li>
	<li>09.06.2024 - Pierwsza burza Marcelka</li>
	<li>12.06.2024 - Pierwsza picie wody z Bidonu zakończone pomyślnie</li>
	<li>12.06.2024 - Pierwsze truskawki Marcelka z własnego balkonu</li>
	<li>14.06.2024 - Pierwszy łosoś ze słoiczka i Malinki na deser</li>
	<li>17.06.2024 - Czwarty ząbek, lewa góra</li>
	<li>20.06.2024 - Pierwszy podróż Marcelka autobusem na działkę</li>
	<li>21.06.2024 - Chory Marcelek</li>
	<li>01.07.2023 - Pierwsze wakacje nad morzem w Pobierowie</li>
	<li>20.07.2023 - Jąderko w poradni chirurgicznej Anna Stołtny o 12:40</li>
	<li>01.08.2023 - Wakacje w Polańczyku</li>
	<li>12.08.2023 - Pierwszy basen otwarty w Rudzie Śląskiej</li>
	<li>19.08.2023 - Pierwszy wizyta we Wrocławiu</li>
	<li>24.08.2023 - Pierwsze chodzenie z pchaczem - chodzikiem</li>
	<li>25.08.2023 - Stanie bez trzymanki</li>
	<li>26.08.2023 - Pierwszy krok bez trzymanki</li>
	<li>18.09.2023 - Chodzenie bez trzymanki</li>
	<li>22.10.2023 - Msza roczkowa i impreza w Prowencie</li>
	<li>25.10.2023 - Poradnia Kardiologii Dziecięcej Joanna Kohut o 13:45 </li>
	<li>15.11.2023 - Pierwsze kredki Rybka </li>
	<li>19.11.2023 - Pierwsze klaskanie Rybka </li>
	<li>23.11.2023 - Bilans roczniaka i szczepienie MMR</li>
	<li>12.12.2023 - Rybek prawidłowo jeździ na hulajnodze</li>
	<li>09.01.2024 - Zabieg na jąderko </li>
	<li>18.01.2024 - Kontrola po zabiegu na jąderko </li>
	<li>03.02.2024 - Górna prawa czwórka przebita </li>

	<li>04.03.2024 - Zostanie sam na sam z tatą cały dzień </li>
	<li>14.03.2024 - Pierwsza drzemka bez płaczu z tatą </li>
	<li>28.03.2024 - Kontrola jąderka z tatą </li>

	<li>14.04.2024 - Ostatni cyc Marcelka </li>
	<li>17.04.2024 - Ząbek - trójka lewa górna się przebiła </li>
	<li>19.04.2024 - Mama wraca do opieki nad Marclem </li>

	<li>24.04.2024 - Szczepienie Marcla 13:15 </li>
	<li>09.05.2024 - Ząbek - górna prawa trójka</li>
	<li>24.05.2024 - Ząbek - lewa górna czwórka</li>
	<li>29.05.2024 - Skolik lekarz 12.20</li>
	<li>04.06.2024 - Skolik lekarz 12.45</li>
	<li>13.06.2024 - Ząbek - Dolna prawa czwórka</li>
	<li>17.06.2024 - Ząbek - Dolna prawa trójka</li>

	<li>06.08.2024 - Spanie w swoim łóżeczku, w swoim pokoju</li>
	<li>19.08.2024 - Pierwsze siknięcie do nocniczka</li>
	<hr />


	<li>30.10.2024 - Poradnia Kardiologii Dziecięcej o 13:45 (Joanna Kohut, ul Frefry 22 Katowice TomMED)</li>
	<hr />

	<li class="cursor-pointer" (click)="utilsService.openUrl('http://devit4.ddns.net:5000/SpiewajaceBrzdace')">
		26.05.2023 - Pierwszy folder Marcelka z ulubioną muzą</li>

	<li class="cursor-pointer" (contextmenu)="openAudioBook()"
		(click)="utilsService.openUrl('http://devit4.ddns.net:5000/Audiobajki')">
		30.05.2023 - Audiobajki
	</li>

	<!-- <li class="cursor-pointer" (click)="openAudioBook()">
    Losuj Baję
  </li> -->


	<hr />
	<div>
		Od poczęcia minęło:
		<h2>
			<app-calc-from-date date="202201180000" showWeek="true" showMonth="true"></app-calc-from-date>
		</h2>
	</div>
	<hr />

	<div>
		Od porodu minęło:
		<h2>
			<app-calc-from-date date="202210181702" showWeek="true" showMonth="true"></app-calc-from-date>
		</h2>
	</div>
</ul>

<hr />
<div class="progress m-3">
	<div class="bar-4 progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0"
		aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
</div>
<hr />

<div style="padding: 10px;" (click)="vm.showMoreInfo =! vm.showMoreInfo">
	<img class="img-fluid mx-auto d-block border-radius-50" src="assets/baby/baby.jpg" style="max-height: 300px;" />
</div>

<ng-image-fullscreen-view [images]="vm.imageObject" [imageIndex]="vm.imageIndex" [show]="vm.showImageFlag"
	(close)="closeImage()">
</ng-image-fullscreen-view>



<ng-template [ngIf]="vm.showMoreInfo" [ngIfThen]="results" [ngIfElse]="noResults"></ng-template>

<ng-template #results>
	<hr />
	<h1 class="ms-1">Waga Marcelka</h1>
	<ul>
		<li *ngFor="let el of vm.weightList; trackBy: trackByIndex">
			{{el.date | date:'dd.MM.yyyy'}} - <b>{{el.weight}}</b> kg</li>
	</ul>
</ng-template>

<ng-template #noResults>

</ng-template>