import { Component, OnInit } from '@angular/core';
import { BibleVM } from "./models/bibleVM";
import { BaseComponent } from '../../core/base/base/base.component';

@Component({
  selector: 'app-bible',
  templateUrl: './bible.component.html',
  styleUrls: ['./bible.component.scss']
})
export class BibleComponent extends BaseComponent implements OnInit {

  public vm: BibleVM = new BibleVM();

  constructor() {
    super();
  }

  public ngOnInit() {
    this.api.apiBibleRandomChapter(true).then(x => {
      this.vm.response = x;
    });
  }

  public rand() {
    this.vm.verse = null;
    this.ngOnInit();
  }

  public set(el: any) {
    if (this.vm.verse == el.verse) {
      this.vm.verse = null;
    } else
      this.vm.verse = el.verse;
  }
}
