<div class="m-3 center">
	<h1 class="sofia-font">Kalendarz Oliwki </h1>
</div>

<img (click)="randomImage()" class="backgroud img-fluid mx-auto d-block"
	src="/assets/icons/baby/icon{{vm.iconIndex}}.png" width="100px" />

<hr />

<ul>
	<li>17.01.2024 - Ostatni dzień miesiączki</li>
	<li>30.01.2024 - Zapłodnienie jajeczka</li>
	<li>13.02.2024 - Pierwszy test ciążowy</li>
	<li>14.02.2024 - Test z krwi</li>
	<li lass="cursor-pointer" (click)="openImage('assets/baby-new/1.jpg')">20.03.2024 - 14:15 - Pierwsze badanie u
		Sadłochy</li>
	<li>22.03.2024 - 8:00 - Badania krwi w przychodni u Sadłochy</li>
	<li>22.03.2024 - 7:30 - Dodatkowe badania krwi w przychodni u Sadłochy</li>
	<li>11.04.2024 - 15:30 - Pierwsze Prenatalne</li>
	<li>19.04.2024 - 08:10 - Druga wizyta w przychodni u Sadłochy</li>
	<li>17.05.2024 - 08:00 - Trzecia wizyta w przychodni u Sadłochy</li>
	<li>05.06.2024 - 12:30 - Drugie prenatalne</li>
	<li>14.06.2024 - 08:10 - Czwarta wizyta w przychodni u Sadłochy</li>
	<li>05.07.2024 - 10:00 - Piąta wizyta w przychodni u Sadłochy</li>
	<li>02.08.2024 - 09:10 - Szósta wizyta w przychodni u Sadłochy</li>
	<li>07.08.2024 - Wybrano imię, będzie Oliwka</li>

	<hr />

	<li>06.09.2024 - 08:10 - Siódma wizyta w przychodni u Sadłochy</li>
	<li>27.09.2024 - 09:10 - Ósma wizyta w przychodni u Sadłochy </li>

	<hr />

	<div>
		Od ostatniej miesiączki minęło:
		<h2>
			<app-calc-from-date date="202401170000" showWeek="true" showMonth="true"></app-calc-from-date>
		</h2>
	</div>
</ul>

<hr />
<div class="progress m-3">
	<div class="bar-4 progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0"
		aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
</div>
<hr />

<div style="padding: 10px;" (click)="vm.showMoreInfo =! vm.showMoreInfo">
	<img class="img-fluid mx-auto d-block border-radius-50" src="assets/baby/baby.jpg" style="max-height: 300px;" />
</div>

<ng-image-fullscreen-view [images]="vm.imageObject" [imageIndex]="vm.imageIndex" [show]="vm.showImageFlag"
	(close)="closeImage()">
</ng-image-fullscreen-view>