import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UtilsUser } from '../utils/utils-user';
import { UserService } from './user.service';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(
        public router: Router,
        public us: UserService
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        let userName = this.us.getUserName();
        if (userName == 'Admin')
            return true;

        if (userName == "David")
          userName = 'Dawid';

        if (next.data.users) {
            return next.data.users.indexOf(userName) > -1;
        }
        else {
            let roles = this.us.availableMenuItems || UtilsUser.getMenu();

            let result = false;
            roles.forEach(role => {
                if (role.url.toLowerCase().indexOf(state.url.substring(1).toLowerCase()) > -1)
                    result = true;
            });
            return result;
        }
    }
}

