<div class="container">
  <div class="row">
    <div class="col-md-9"> <img class="img-fluid mx-auto d-block" src="/assets/images/others/planMieszkania.png"></div>
    <div class="col-md-3">
      <div style="margin:10px">
        <div class="row">
          <div class="col">
            Przedpokój
          </div>
          <div class="col">
            10,60 m²
          </div>
        </div>

        <div class="row">
          <div class="col">
            Salon
          </div>
          <div class="col">
            19,84 m²
          </div>
        </div>

        <div class="row">
          <div class="col">
            Kuchnia
          </div>
          <div class="col">
            9,95 m²
          </div>
        </div>

        <div class="row">
          <div class="col">
            Łazienka
          </div>
          <div class="col">
            4,83 m²
          </div>
        </div>

        <div class="row">
          <div class="col">
            Pokój dziecka
          </div>
          <div class="col">
            16,65 m²
          </div>
        </div>

        <div class="row">
          <div class="col">
            Sypialnia
          </div>
          <div class="col">
            15,61 m²
          </div>
        </div>

        <hr />


        <div class="row">
          <div class="col">
            <b>Razem</b>
          </div>
          <div class="col">
            <b>77,48 m²</b>
          </div>
        </div>

        <div class="row">
          <div class="col">
            Komórka
          </div>
          <div class="col">
            2,33 m²
          </div>
        </div>
        <hr />

        <div class="row">
          <div class="col">
            <b>Razem </b>
          </div>
          <div class="col">
            <b>79,81 m²</b>
          </div>
        </div>

        <hr />
        KA1C/00046795/7

      </div>
    </div>
  </div>
</div>