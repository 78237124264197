import { Injectable } from "@angular/core";
import { DomoticzApiClient } from "../generated/DomoticzApiClient";

@Injectable()
export class LoggerService {

    constructor(
        private api: DomoticzApiClient) {
    }

    public async logInformation(message: string, model: any) {
        return this.api.apiLogSaveLogInformation(message, model).then(x => console.log(x));
    }

    public async logError(message: string, model: any) {
        return this.api.apiLogSaveLogError(message, model).then(x => console.log(x));
    }

    public async logDebug(message: string, model: any) {
        return this.api.apiLogSaveLogDebug(message, model).then(x => console.log(x));
    }

    public async logWarning(message: string, model: any) {
        return this.api.apiLogSaveLogWarning(message, model).then(x => console.log(x));
    }
}
