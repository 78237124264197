import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../core/base/base/base.component';
import { find } from 'lodash-es';

@Component({
  selector: 'app-smog',
  templateUrl: './smog.component.html',
  styleUrls: ['./smog.component.scss']
})
export class SmogComponent extends BaseComponent implements OnInit {

  public description: string;
  public time: string;
  public pm25: string;
  public pm25val: string;
  public pm10: string;
  public pm10val: string;

  public bar: string;
  public visibility: string;
  public sensor: string;

  public temp: string;
  public hum: string;

  ngOnInit() {
    this.get();
  }

  get() {
    let prmise = this.api.apiDomoticzGetDevicesVariables([32, 33, 34, 27, 28, 233, 31, 30, 334, 106]);
    prmise.then(x => {
      let el = find(x, x => x.idx === "32");
      this.description = el.data;
      this.time = el.lastUpdate;

      el = find(x, x => x.idx === "33");
      if (el)
        this.pm25 = el.data;

      el = find(x, x => x.idx === "34");
      if (el)
        this.pm10 = el.data;

      el = find(x, x => x.idx === "27");
      if (el)
        this.pm25val = el.data;

      el = find(x, x => x.idx === "28");
      if (el)
        this.pm10val = el.data;

      el = find(x, x => x.idx === "233");
      if (el)
        this.temp = el.data.split(' ')[0] + ' °C';

      el = find(x, x => x.idx === "31");
      if (el)
        this.hum = el.data.split(' ')[1] + ' %';

      el = find(x, x => x.idx === "30");
      if (el)
        this.bar = el.data;

      el = find(x, x => x.idx === "334");
      if (el)
        this.visibility = el.data;

      el = find(x, x => x.idx === "106");
      if (el)
        this.sensor = el.data;
    });
    return prmise;
  }
}
