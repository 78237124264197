<div style="padding: 40px;">
  <img src="/assets/images/slub.png" alt="Ślub 2021" (click)="back()" class=" img-fluid mx-auto d-block" />

  <br />

  <div class="center">
    <h1><span class="red">❤</span> Nasz Wielki Dzień <span class="red">❤</span></h1>
    <h2>28.08.2021</h2>
    <hr />
  </div>

  <div class="center">
    <h2>
      <app-calc-from-date date="202108281300" showWeek="true" showMonth="true">
      </app-calc-from-date>
    </h2>
  </div>
  <hr />

  <div class="center">
    <h2>Kocham Cię Madziu! <span class="red">❤</span> </h2>
  </div>

</div>