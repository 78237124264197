<div class="form">

  <app-header-for-module text="Wielki Browek" src="/assets/icons/open-ai/open-ai.png"></app-header-for-module>

  <img class="backgroud img-fluid mx-auto d-block" src="/assets/others/hamster_face.png" />


  <div class="container">
    <div class="row mt-3" *ngFor="let h of history; trackBy: trackByIndex">
      <div class="col">
        <div class="card">
          <div class="card-header d-flex justify-content-between p-3"
            [ngClass]="{'browek' : h.who == 'Wielki Browek', 'ja': h.who == 'Ja'}">
            <p class="fw-bold mb-0"> {{h.who}}</p>
            <p class="text-muted small mb-0"><i class="far fa-clock black"></i> {{h.time}}</p>
          </div>
          <div class="card-body">
            <p *ngIf="h.type == 'text'" class="mb-0">
              {{h.message}}
            </p>

            <p *ngIf="h.type == 'image'" class="mb-0">
              <img class="" [src]="h.message" />
            </p>

            <div *ngIf="h.type == 'image-array'">
              <div *ngIf="h.message" [innerHtml]="h.message" class="mb-1">
              </div>

              <div class="group-2">
                <ng-container *ngFor="let img of h.imageObject; trackBy: trackByIndex; let i = index">
                  <div class="ms-3">{{img.content}}</div>

                  <img [src]="img.image" (click)="showLightbox(h, i)" />
                  <br *ngIf="img.content" />
                  <br *ngIf="img.content" />
                </ng-container>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="form-outline">
        <textarea class="form-control" id="textAreaExample2" [(ngModel)]="question" (keyup.enter)="send()"
          rows="4"></textarea>
        <label class="form-label" for="textAreaExample2">Wiadomość</label>
      </div>
    </div>

    <div class="row m-3">
      <button [disabled]="disabledButton" mat-raised-button color="warn" class="col-sm p-10 lavender-black-btn"
        (click)="send()">Wyślij</button>
    </div>
  </div>

  <hr />

  <div *ngIf="!isOfflineMode()">
    <div class="row">
      <button mat-raised-button color="warn" class="col-sm p-10 green-btn"
        (click)="send('losuj zdjęcie Marcelka')">losuj zdjęcie
        Marcelka
      </button>

      <button mat-raised-button color="warn" class="col-sm p-10 green-btn"
        (click)="send('losuj 10 zdjęć Marcelka')">losuj 10 zdjęć Marcelka
      </button>
    </div>

    <div class="row">
      <button mat-raised-button color="warn" class="col-sm p-10 lavender-btn"
        (click)="send('losuj zdjęcie Madzi i Dawida')">losuj zdjęcie Madzi i
        Dawida
      </button>

      <button mat-raised-button color="warn" class="col-sm p-10 lavender-btn"
        (click)="send('losuj 10 zdjęć Madzi i Dawida')">losuj 10 zdjęć Madzi i
        Dawida
      </button>
    </div>

    <div class="row">
      <button mat-raised-button color="warn" class="col-sm p-10 green-btn" (click)="send('biedronka')">karty Biedronka
      </button>

      <button mat-raised-button color="warn" class="col-sm p-10 green-btn" (click)="send('lidl')">karty Lidl
      </button>

      <!-- <button mat-raised-button color="warn" class="col-sm p-10 green-btn" (click)="send('lista zakupów')">lista zakupów
      </button> -->
    </div>

    <div class="row">
      <button mat-raised-button color="warn" class="col-sm p-10 green-btn" (click)="send('orlen')">karta Orlen
      </button>
    </div>

    <div class="row">
      <button mat-raised-button color="warn" class="col-sm p-10 lavender-btn" (click)="send('uruchom ponownie')">uruchom
        ponownie DevPi
      </button>

      <button mat-raised-button color="warn" class="col-sm p-10 lavender-btn"
        (click)="send('uruchom ponownie retropi')">uruchom ponownie RetroPi
      </button>
    </div>

    <div class="row" *ngIf="userName == 'Mama' || userName == 'Tata' || userName == 'Admin'">
      <button mat-raised-button color="warn" class="col-sm p-10 lavender-black-btn"
        (click)="send('losuj zdjęcie Mamy i Taty')">losuj zdjęcie Mamy i Taty
      </button>

      <button mat-raised-button color="warn" class="col-sm p-10 lavender-black-btn"
        (click)="send('losuj 10 zdjęć Mamy i Taty')">losuj 10 zdjęć Mamy i
        Taty
      </button>
    </div>

  </div>

  <div *ngIf="isOfflineMode()">
    <div class="row">
      <button mat-raised-button color="warn" class="col-sm p-10 green-btn" (click)="send('biedronka')">karty Biedronka
      </button>
    </div>
  </div>

  <ng-image-fullscreen-view [images]="imageObject" [imageIndex]="selectedImageIndex" [show]="showFlag"
    (close)="closeEventHandler()">
  </ng-image-fullscreen-view>
</div>