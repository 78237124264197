export class UtilsString {

    public static normalize(value: string) {
        if (!value)
            return '';
        else {
            let res = value.normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .split('ł').join('l')
                .split('Ł').join('L');

            return res;
        }
    }

    public static capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
}