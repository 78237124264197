import { HttpClient } from "@angular/common/http";

export class ReceiptModel {

    name: string;
    dataTarget: string;
    html: any;

    constructor(name: string, dataTarget: string) {
        this.name = name;
        this.dataTarget = dataTarget;
    }
}