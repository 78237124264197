import { Component, OnDestroy } from '@angular/core';

import { interval } from 'rxjs';
import { CigarVM } from './models/cigarVM';
import { BaseComponent } from '../../core/base/base/base.component';
import { UtilsDate } from '../../utils/utils-date';

@Component({
  selector: 'app-cigar',
  templateUrl: './cigar.component.html',
  styleUrls: ['./cigar.component.scss']
})
export class CigarComponent extends BaseComponent implements OnDestroy {

  public vm: CigarVM = new CigarVM();

  constructor() {
    super();

    this.route.params.subscribe(x => {
      if (x.date)
        this.vm.firstDay = UtilsDate.new(x.date, "YYYYMMDDHHmm");
    });

    this.vm.s = interval(1000).subscribe(x => {
      this.vm.actualDate = UtilsDate.new();
      this.vm.result = this.setDate(this.vm.firstDay);

      this.setProgressValue(1, ".bar-1");
      this.setProgressValue(12, ".bar-2");
      this.setProgressValue(24 * 1, ".bar-3");
      this.setProgressValue(24 * 2, ".bar-4");
      this.setProgressValue(24 * 3, ".bar-5");
      this.setProgressValue(24 * 4, ".bar-51");
      this.setProgressValue(24 * 7, ".bar-6");
      this.setProgressValue(24 * 14, ".bar-7");
      this.setProgressValue(24 * 30, ".bar-75");
      this.setProgressValue(24 * 60, ".bar-77");
      this.setProgressValue(24 * 90, ".bar-8");
    });
  }

  setDate(val: any) {
    let result = '';
    this.vm.diffDays = this.vm.actualDate.diff(val, 'days');
    this.vm.diffHours = this.vm.actualDate.diff(val, 'hours');
    this.vm.diffMinutes = this.vm.actualDate.diff(val, 'minutes') % 60;
    this.vm.diffSeconds = this.vm.actualDate.diff(val, 'seconds') % 60;

    if (this.vm.diffDays > 0) {
      result += `${this.vm.diffDays} `;
      if (this.vm.diffDays === 1)
        result += `dzień `;
      else
        result += `dni `;
    }

    if (this.vm.diffHours % 24 > 0) {
      if (this.vm.diffHours === 1)
        result += `${this.vm.diffHours % 24} godzina `;
      else if (this.vm.diffHours > 1 && this.vm.diffHours < 5)
        result += `${this.vm.diffHours % 24} godziny `;
      else if (this.vm.diffHours > 5 && this.vm.diffHours < 22)
        result += `${this.vm.diffHours % 24} godzin `;
      else
        result += `${this.vm.diffHours % 24} godziny `;
    }

    if (this.vm.diffMinutes > 0) {
      if (this.vm.diffMinutes === 1)
        result += `${this.vm.diffMinutes} minuta `;
      else if ((this.vm.diffMinutes > 1 && this.vm.diffMinutes < 5)
        || (this.vm.diffMinutes > 21 && this.vm.diffMinutes < 25)
        || (this.vm.diffMinutes > 31 && this.vm.diffMinutes < 35)
        || (this.vm.diffMinutes > 41 && this.vm.diffMinutes < 45)
        || (this.vm.diffMinutes > 51 && this.vm.diffMinutes < 55))
        result += `${this.vm.diffMinutes} minuty `;
      else
        result += `${this.vm.diffMinutes} minut `;
    }

    if (this.vm.diffSeconds > 0) {
      if (this.vm.diffSeconds === 1)
        result += `${this.vm.diffSeconds} sekunda `;
      else if ((this.vm.diffSeconds > 1 && this.vm.diffSeconds < 5)
        || (this.vm.diffSeconds > 21 && this.vm.diffSeconds < 25)
        || (this.vm.diffSeconds > 31 && this.vm.diffSeconds < 35)
        || (this.vm.diffSeconds > 41 && this.vm.diffSeconds < 45)
        || (this.vm.diffSeconds > 51 && this.vm.diffSeconds < 55))
        result += `${this.vm.diffSeconds} sekundy `;
      else
        result += `${this.vm.diffSeconds} sekund `;
    }

    return result;
  }

  setProgressValue(hour: number, css: string) {
    let start = this.vm.firstDay as any;
    let end = UtilsDate.new(this.vm.firstDay).add(hour, 'hours') as any;
    let today = this.vm.actualDate as any;
    let p = Math.round((today - start) / (end - start) * 100);
    if (p > 100) {
      p = 100;
      if (!$(css).hasClass('bg-success')) {
        $(css).addClass('bg-success');
        $(css).removeClass('bg-danger');
      }
    }

    $(css).css('width', p + '%').attr('aria-valuenow', p);
    $(css).text(p.toString() + "%");
  }

  ngOnDestroy(): void {
    this.vm.s?.unsubscribe();
    super.ngOnDestroy()
  }
}
