import { Component } from '@angular/core';
import { BaseComponent } from '../../core/base/base/base.component';

@Component({
  selector: 'app-weeding',
  templateUrl: './wedding.component.html',
  styleUrls: ['./wedding.component.scss']
})
export class WeddingComponent extends BaseComponent {

  constructor() {
    super();
    super.setTitle(`Ślub 🥰 - Dev IT`);
  }
}
