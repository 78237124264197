<div class="form">

  <app-header-for-module class="cursor-pointer" (click)="goToCategory()" text="Notatki - {{categoryText}}"
    src="/assets/icons/notes/notes-details.png"></app-header-for-module>

  <div class="row">
    <div class="col-10 col-sm-11" *ngIf="!hideKey">
      <mat-form-field style.fontSize="16px" class="example-full-width">
        <mat-label>Klucz</mat-label>
        <input matInput [(ngModel)]="key" />
      </mat-form-field>
    </div>

    <div class="col-10 col-sm-11">
      <mat-form-field style.fontSize="16px" class="example-full-width">
        <mat-label>Wartość</mat-label>
        <input matInput [(ngModel)]="value" (keyup.enter)="add()" />
      </mat-form-field>
    </div>

    <div class="col-2 col-sm-1 x-large cursor-pointer">
      <span (click)="add()">
        <i class="fas fa-plus green-and-hover"></i>
      </span>
    </div>
  </div>


  <br /> <br />

  <mat-card class="mb-2" *ngFor="let c of notes; trackBy: trackByIndex" (dblclick)="openEdit(c)">
    <mat-card-content *ngIf="!c.editMode">
      <div class="row item" *ngIf="(c.key && !hideKey)">
        <b>{{c.key}}</b><br />
      </div>
      <div class="row">
        <div class="col">
          {{c.value}}
        </div>
        <div class="col-1 me-3 item" (click)="remove(c)">
          <i class="fas fa-times red-and-hover cursor-pointer"></i>
        </div>
      </div>
    </mat-card-content>

    <div class="row" *ngIf="c.editMode">
      <div class="col-10">
        <mat-form-field class="example-full-width" *ngIf="!hideKey">
          <mat-label>Klucz</mat-label>
          <input matInput [(ngModel)]="c.tmpKey" (keyup.enter)="updateEdit(c)" />
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label>Wartość</mat-label>
          <input matInput [(ngModel)]="c.tmpValue" (keyup.enter)="updateEdit(c)" />
        </mat-form-field>
      </div>

      <div class="col">
        <span (click)="updateEdit(c)">
          <i class="fas fa-check red item m-3 green-and-hover"></i>
        </span>
        <span (click)="cancelEdit(c)">
          <i class="fas fa-undo-alt item m-3 red-and-hover cancel-edit "></i>
        </span>
      </div>
    </div>
  </mat-card>

  <mat-card *ngIf="notes?.length == 0" class="center">
    <mat-card-content>
      Lista notatek jest pusta 🥰
    </mat-card-content>
  </mat-card>

  <div class="center mt-3" *ngIf="category == 'FILMY DO POBRANIA'">
    <a class="col-sm p-10 lavender-btn" routerLink="/moviedb" routerLinkActive="active" mat-raised-button
      color="warn">Pomocnik Filmowy</a>
  </div>

</div>