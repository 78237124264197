import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
import { NgImageFullscreenViewService } from './ng-image-fullscreen-view.service';
import { BaseComponent } from '../../../core/base/base/base.component';

@Component({
    selector: 'ng-image-fullscreen-view',
    templateUrl: './ng-image-fullscreen-view.html',
    styleUrls: ['./ng-image-fullscreen-view.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NgImageFullscreenViewComponent extends BaseComponent implements OnDestroy {

    @HostListener('window:popstate', [])
    onPopState(event: any) {
        if (this.images.length > 0)
            location.href = "";
    }

    totalImages: any = 0;
    nextImageIndex: any = -1;
    popupWidth: any = 1200;
    marginLeft: any = 0;
    imageFullscreenView: boolean = false;
    lightboxPrevDisable: boolean = false;
    lightboxNextDisable: boolean = false;
    showLoading: boolean = true;
    effectStyle: string = 'none';
    speed: any = 0.3; // default speed in second
    title: string = '';
    currentImageIndex: any = 0;
    indexZoom = 1;

    // for swipe event
    private swipeLightboxImgCoord?: [any, any];
    private swipeLightboxImgTime?: any;

    // @Inputs
    @Input() images: Array<any> = [];
    @Input()
    set imageIndex(index: any) {
        if (index !== undefined && index > -1 && index < this.images.length) {
            this.currentImageIndex = index;
        }
        this.nextPrevDisable();
    }
    @Input()
    set show(visiableFlag: any) {
        this.imageFullscreenView = visiableFlag;
        this.elRef.nativeElement.ownerDocument.body.style.overflow = '';
        if (visiableFlag === true) {
            this.elRef.nativeElement.ownerDocument.body.style.overflow = 'hidden';
            this.setPopupSliderWidth();
        }
    }
    @Input() videoAutoPlay: boolean = false;
    @Input() direction: string = 'ltr';
    @Input() paginationShow: boolean = false;
    @Input()
    set animationSpeed(data: any) {
        if (data
            && typeof (data) === 'number'
            && data >= 0.1
            && data <= 5) {
            this.speed = data;
        }
    }
    @Input() infinite: boolean = false;
    @Input() arrowKeyMove: boolean = true;
    @Input() showVideoControls: boolean = true;

    // @Output
    @Output() close = new EventEmitter<any>();
    @Output() prevImage = new EventEmitter<any>();
    @Output() nextImage = new EventEmitter<any>();

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.effectStyle = 'none';
        this.setPopupSliderWidth();
    }

    @HostListener('document:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event && event.key && this.arrowKeyMove) {
            if (event.key.toLowerCase() === 'arrowright')
                this.nextImageLightbox();

            if (event.key.toLowerCase() === 'arrowleft')
                this.prevImageLightbox();

            if (event.key.toLowerCase() === 'escape')
                this.closeLightbox();
        }
    }

    constructor(
        private cdRef: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
        private elRef: ElementRef,
        public imageFullscreenViewService: NgImageFullscreenViewService,
        @Inject(DOCUMENT) private document: any) {
        super();
    }


    download() {
        var fileArr = this.images[this.currentImageIndex].image.split('/');
        let fileName = fileArr[fileArr.length - 1];
        saveAs(this.images[this.currentImageIndex].image, fileName);
    }

    openInNewWindow() {
        this.utilsService.openImage(this.images[this.currentImageIndex].image);
    }

    setPopupSliderWidth() {
        if (window && window.innerWidth) {
            this.popupWidth = window.innerWidth;
            this.totalImages = this.images.length;
            if (typeof (this.currentImageIndex) === 'number' && this.currentImageIndex !== undefined) {
                this.marginLeft = -1 * this.popupWidth * this.currentImageIndex;
                this.getImageData();
                this.nextPrevDisable();
                setTimeout(() => {
                    this.showLoading = false;
                }, 500);
            }
        }
    }

    prevImageLightbox() {
        this.effectStyle = `all ${this.speed}s ease-in-out`;
        if (this.currentImageIndex > 0 && !this.lightboxPrevDisable) {
            this.currentImageIndex--;
            this.prevImage.emit();
            this.marginLeft = -1 * this.popupWidth * this.currentImageIndex;
            this.getImageData();
            this.nextPrevDisable();
        }
    }

    nextImageLightbox() {
        this.effectStyle = `all ${this.speed}s ease-in-out`;
        if (this.currentImageIndex < this.images.length - 1 && !this.lightboxNextDisable) {
            this.currentImageIndex++;
            this.nextImage.emit();
            this.marginLeft = -1 * this.popupWidth * this.currentImageIndex;
            this.getImageData();
            this.nextPrevDisable();
        }
    }

    nextPrevDisable() {
        this.lightboxNextDisable = this.lightboxPrevDisable = true;
        setTimeout(() => {
            this.applyButtonDisableCondition();
        }, this.speed * 1000);
    }

    applyButtonDisableCondition() {
        this.lightboxNextDisable = this.lightboxPrevDisable = false;

        if (!this.infinite && this.currentImageIndex >= this.images.length - 1)
            this.lightboxNextDisable = true;

        if (!this.infinite && this.currentImageIndex <= 0)
            this.lightboxPrevDisable = true;
    }

    getImageData() {
        if (this.images
            && this.images.length
            && typeof (this.currentImageIndex) === 'number'
            && this.currentImageIndex !== undefined
            && this.images[this.currentImageIndex]
            && (this.images[this.currentImageIndex]['image'] || this.images[this.currentImageIndex]['video'])) {
            this.title = this.images[this.currentImageIndex]['title'] || '';
            this.totalImages = this.images.length;
            for (const iframeI in this.document.getElementsByTagName('iframe')) {
                if (this.document.getElementsByTagName('iframe')[iframeI]
                    && this.document.getElementsByTagName('iframe')[iframeI].contentWindow) {
                    this.document.getElementsByTagName('iframe')[iframeI].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                }
            }
            for (const videoI in this.document.getElementsByTagName('video')) {
                if (this.document.getElementsByTagName('video')[videoI] && this.document.getElementsByTagName('video')[videoI].pause) {
                    this.document.getElementsByTagName('video')[videoI].pause();
                }
            }
        }
    }

    swipeLightboxImg(e: TouchEvent, when: string): void {
        const coord: [any, any] = [e.changedTouches[0].pageX, e.changedTouches[0].pageY];
        const time = new Date().getTime();

        if (when === 'start') {
            this.swipeLightboxImgCoord = coord;
            this.swipeLightboxImgTime = time;
        } else if (when === 'end') {
            const direction = [coord[0] - this.swipeLightboxImgCoord[0], coord[1] - this.swipeLightboxImgCoord[1]];

            if ((time - this.swipeLightboxImgTime) < 1000 && Math.abs(direction[0]) > 30 && Math.abs(direction[0]) > Math.abs(direction[1] * 3))
                direction[0] < 0 ? this.nextImageLightbox() : this.prevImageLightbox();
        }
    }

    closeLightbox() {
        this.close.emit();
    }

    resetState() {
        this.images = [];
    }

    ngOnDestroy() {
        this.resetState();
        super.ngOnDestroy();
    }
}
