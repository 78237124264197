import { Injectable } from "@angular/core";
import { DomoticzApiClient, NotificationModel } from "../generated/DomoticzApiClient";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private api: DomoticzApiClient) { }

  public sendFirebaseNotificationInline(user: string, message: string, title: string = "Dev IT", returnUrl = null) {
    var model = new NotificationModel();
    model.user = user;
    model.message = message;
    model.title = title;
    model.returnUrl = returnUrl;

    return this.sendFirebaseNotification(model);
  };

  public sendFirebaseNotification(model: any) {
    return this.api.apiNotificationSendFirebaseNotification(model).then(x => console.log(x));
  }
}
