<div class="form">

  <app-header-for-module text="Pismo Swiete" src="/assets/icons/bible/bible.png"></app-header-for-module>

  <app-progress-spinner [isShow]="!vm.response"></app-progress-spinner>

  <div *ngIf="vm.response" class="white-background">
    <div class="center">
      <h1 class="cursor-pointer mt-3" (click)="rand()">
        <b>{{vm.response.part}} </b>
      </h1>

      <h2>
        {{vm.response.book}} (rozdział. {{ vm.response.chapter}})
      </h2>
      wersety {{vm.response.versesRange}}

    </div>

    <hr class="mt-3" />

    <ng-template [ngIf]="true" [ngIfThen]="verses" [ngIfElse]="content"></ng-template>

    <ng-template #content>
      <div class="content" [innerHTML]="vm.response.content"></div>
    </ng-template>

    <ng-template #verses>
      <div class="content">
        <div *ngFor="let el of vm.response.verses; trackBy: trackByIndex" class="d-inline" (click)="set(el)"
          [ngClass]="{'font-bold' : vm.verse == el.verse }">
          <sup class="lavender-lite">{{el.verse}} </sup>{{el.text}}
        </div>
      </div>
    </ng-template>

  </div>
</div>