<div class="top-bar" [ngClass]="appName == 'RetroPie' ? 'retropie' : ''"
  [ngStyle]="{ 'visibility': !isLoading ? 'visible' : 'hidden' }">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-md-6 d-md-block d-none">
        <a href="" routerLink="/" routerLinkActive="active" class="logo noselect" (click)="back()">Dev
          <span class="sofia-font">IT</span>
        </a>
        <div style="margin-left: 8rem !important;">v. {{version}}</div>
      </div>

      <div *ngIf="isOffline" class="ribbon ribbon-top-right noselect"><span>Offline</span></div>

      <div *ngIf="!isOffline && newRibbon" class="ribbon ribbon-top-right noselect" (click)="openMenuNative()"
        (dblclick)="promptLogout()">
        <span> {{ userName }}</span>
      </div>

      <div class="col-md-6">
        <div class="d-flex justify-content-md-end justify-content-between">
          <ul class="list-inline contact-info d-block d-md-none">
            <a href="" routerLink="/" routerLinkActive="active" class="logo noselect" (click)="back()">Dev
              <span class="sofia-font">IT</span>
            </a>
            <div style="margin-left: 7rem !important;" *ngIf="userName == 'Admin'">v. {{version}}</div>
          </ul>
          <div class="login">

            <a *ngIf="isBridge" href="#" data-toggle="modal" data-target="#login-modal" class="login-btn">
              <i class="fas fa-plug"></i>
              {{version}}
              <!-- {{versionNS}} -->
            </a>

            <a href="#" class="signup-btn" *ngIf="isOffline && !newRibbon">
              <span class="sofia-font noselect" (click)="openMenuNative()" (dblclick)="promptLogout()">
                {{ userName }}
              </span>
            </a>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>