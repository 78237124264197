import { MenuPosition } from "../app/home/models/menu-position";

export class UtilsUser {

    public static roles: Array<any> = [
        { id: 1, name: 'Domoticz', url: ':802', imgSrc: "/assets/icons/domoticz/domoticz.png" },
        { id: 2, name: 'Monit', url: '{url}:806', imgSrc: "/assets/icons/monit/monit.png" },
        { id: 3, name: 'Plex', url: '{url}:32400/web/index.html', imgSrc: "/assets/icons/the-movie-db/the-movie-db.png" },
        { id: 6, name: 'Swagger', url: '{url}:5000/swagger', imgSrc: "/assets/icons/swagger/swagger.png" },
        { id: 7, name: 'Downloader', url: 'downloader', imgSrc: "/assets/icons/downloader/downloader.png" },
        { id: 8, name: 'Tautulli', url: '{url}:8181', imgSrc: "/assets/icons/tautulli/tautulli.png" },
        { id: 11, name: 'Notyfikacje', url: 'notification', imgSrc: "/assets/icons/notification/notification.png" },
        { id: 12, name: 'NextCloud', url: '{url}:815', imgSrc: "/assets/icons/nextcloud/nextcloud.png" },
        { id: 13, name: 'Frame', url: 'nh-frame', imgSrc: "/assets/icons/frame/frame1.png" },
        { id: 14, name: 'Timers', url: 'timers', imgSrc: "/assets/icons/timers/timers.png" },
        { id: 15, name: 'Ślub', url: 'nh-wedding', imgSrc: "/assets/icons/wedding/wedding.png" },
        { id: 16, name: 'KZK-GOP', url: 'busstop', imgSrc: "/assets/icons/bus-stop/bus-stop.png" },
        { id: 19, name: 'Inne', url: 'others', imgSrc: "/assets/icons/others/others.png" },
        { id: 20, name: 'Junior', url: 'baby', imgSrc: "/assets/icons/baby/icon1.png" },
        { id: 21, name: 'PhpMyAdmin', url: '{url}:810' },
        { id: 22, name: 'GPON', url: 'http://192.168.1.100', imgSrc: "/assets/icons/gpon/gpon.png" },
        { id: 23, name: 'Przepisy', url: 'receipcs', imgSrc: "/assets/icons/receipcs/receipcs.png" },
        { id: 24, name: 'Ważne', url: 'important-new', imgSrc: "/assets/icons/important/important.png" },
        { id: 25, name: 'Ustawienia', url: 'settings', imgSrc: "/assets/icons/settings/settings.png" },
        { id: 27, name: 'Tasmota RF', url: 'http://192.168.1.121', imgSrc: "/assets/icons/tasmota-rf/tasmota-rf.png" },
        { id: 28, name: 'Czas Pracy', url: 'work-time', imgSrc: "/assets/icons/work-time/work-time.png" },
        { id: 29, name: 'Lista zakupów', url: 'shop-list', imgSrc: "/assets/icons/shop-list/shop-list.png" },
        { id: 30, name: 'Wordpress', url: "{url}/blog", imgSrc: "/assets/icons/blog/blog.png" },
        { id: 39, name: "Wakacje", url: "nh-vacation", imgSrc: "/assets/icons/vacation/vacation.png" },
        { id: 40, name: "Notatki", url: "notes", imgSrc: "/assets/icons/notes/notes.png" },
        { id: 41, name: "Zdjęcia Emi ❤", url: "https://1drv.ms/f/s!AgoUJ6bNz13TkJIeQMNPuVFijz9riw", imgSrc: "/assets/icons/photos/photos.png" },
        { id: 42, name: "Pi Hole", url: "{url}:856", imgSrc: "/assets/icons/pihole/pihole.png" },
        { id: 43, name: "Smog", url: "smog", imgSrc: "/assets/icons/airly/airly.png" },
        { id: 45, name: "Zdjęcia Dziadka", url: "https://1drv.ms/f/s!AgoUJ6bNz13TkPQ3jDce6YA6mx5k4A", imgSrc: "/assets/icons/photos/photos.png" },
        { id: 46, name: "Mantis", url: "{url}:852", imgSrc: "/assets/icons/mantis/mantis.png" },
        { id: 47, name: "Wielki Browek", url: "open-ai", imgSrc: "/assets/icons/open-ai/open-ai.png" },
        { id: 48, name: "Kalendarz", url: "calendar", imgSrc: "/assets/icons/calendar/calendar.png" },
        { id: 49, name: "OneDrive", url: "onedrive", imgSrc: "/assets/icons/onedrive/onedrive.png" },
        { id: 50, name: "Pismo Święte", url: "bible", imgSrc: "/assets/icons/bible/bible.png" },
        { id: 51, name: "Dyski USB Info", url: "backup", imgSrc: "/assets/icons/backup/backup.png" },
        { id: 52, name: "Tak czy nie?", url: "yes-no", imgSrc: "/assets/icons/yes-no/yes-no.png" },
        { id: 53, name: "Inteligentny Dom", url: "domoticz", imgSrc: "/assets/icons/domoticz/domoticz.png" },

        { id: 54, name: "Webmin", url: "{url}:10000", imgSrc: "" },
        { id: 55, name: "Forum", url: "{url}:851", imgSrc: "" },
        { id: 56, name: "Redmine", url: "{url}:855", imgSrc: "" },
        { id: 57, name: "Cockpit", url: "{url}:9090/system/terminal", imgSrc: "" },
        { id: 58, name: "PhpPgAdmin", url: "http://192.168.1.116/phppgadmin", imgSrc: ""},
        { id: 59, name: "RetroPie Manager", url: "http://192.168.1.127:8000", imgSrc: ""}
    ];

    public static userIsNotDavid() {

        let user = this.getUserName();
        if (user !== 'Dawid' && user !== 'David' && user !== 'Admin')
            return true;
        else
            return false;
    }

    public static getUserName(): string {
        if (localStorage.getItem("user")?.indexOf("Sudo") === 0)
            return "Admin";
        else if (localStorage.getItem("user"))
            return localStorage.getItem("user") as string;
        else if (navigator.userAgent.indexOf("DevITAndroidApp-") < 0)
            return "Anonim";
        else
            return navigator.userAgent.split('-')[1];
    }

    public static getRoles() {
        let userName = UtilsUser.getUserName();
        if (userName == "Admin") {
            let res = new Array<number>();
            UtilsUser.roles.forEach(x => { if (x.id < 1000) res.push(x.id) });
            return res;
        }
        else if (userName === 'Dawid' || userName === 'David')
            return [53, 39, 47, 7, 48, 29, 1, 11, 43, 40, 3, 8, 4, 6, 15, 20, 16, 23, 24, 28, 44, 49, 50, 19, 25, 46, 51, 52];
        else if (userName === "Madzia")
            return [53, 39, 29, 48, 47, 43, 20, 44, 24, 40, 16, 7, 23, 11, 15, 49, 50];
        else if (userName === "Mama")
            return [35, 11, 9, 16, 23, 41, 44, 20, 45];
        else if (userName === "Lukasz")
            return [29, 16, 23];
        else if (userName === "Wiola")
            return [29, 16, 23];
        else if (userName === "Test")
            return [11];
        else
            return [43, 39, 50, 23, 52];
    }

    public static getTechnicalRoles() {
        return [1001, 1002, 1003, 1004];
    }

    public static getTechnicalMenu(): MenuPosition[] {
        let res = new Array<MenuPosition>();
        let roles = this.getTechnicalRoles();
        roles.forEach(z => {
            let arr = UtilsUser.roles.filter(x => x.id == z);
            if (arr.length > 0)
                res.push(arr[0]);
        });
        return res;
    }

    public static getMenu(): MenuPosition[] {
        let res = new Array<MenuPosition>();
        let roles = this.getRoles();
        roles.forEach(z => {
            let arr = UtilsUser.roles.filter(x => x.id == z);
            if (arr.length > 0)
                res.push(arr[0]);
        });
        return res;
    }
}
