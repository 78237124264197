import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ReceiptModel } from './models/receipt.model';
import { ReceiptsVM } from './models/receiptsVM';
import { Location } from '@angular/common';
import { ReceipcsDetailsModalComponent } from './receipcs-details-modal/receipcs-details-modal.component';
import { NotificationService } from '../../services/notification.service';
import { BaseComponent } from '../../core/base/base/base.component';
import { UtilsUser } from '../../utils/utils-user';

@Component({
  selector: 'app-receipcs',
  templateUrl: './receipcs.component.html',
  styleUrls: ['./receipcs.component.scss']
})
export class ReceipcsComponent extends BaseComponent {

  public vm: ReceiptsVM = new ReceiptsVM();

  constructor(
    private ns: NotificationService,
    private hc: HttpClient,
    private sr: DomSanitizer,
    private location: Location) {

    super();
    super.setTitle("Przepisy - Dev IT");

    this.route.params.subscribe(x => {
      if (x.datatarget) {
        setTimeout(() => {
          let el = this.vm.receipts.find(z => z.dataTarget == x.datatarget);
          this.showDetails(el);
        }, 100);
      }
    });

    if (UtilsUser.userIsNotDavid())
      this.ns.sendFirebaseNotificationInline("Dawid", `Sprawdzane przepisy przez ${this.userName}`);

    this.vm.receipts.forEach(x => {
      try {
        this.getHtml(x);
      } catch (exception) { }
    });
  }

  showDetails(el: ReceiptModel) {
    this.location.replaceState(`/receipcs/${el.dataTarget}`);
    super.setTitle(`${el.name} - Przepisy - Dev IT`);
    this.dialog.open(ReceipcsDetailsModalComponent, { data: { element: el }, maxHeight: '100vh', width: '100vw', maxWidth: '100%' })
      .afterClosed().subscribe(x => {
        this.location.replaceState(`/receipcs`);
        super.setTitle("Przepisy - Dev IT");
      });
  }

  getHtml(x: ReceiptModel) {
    this.hc.get('/assets/receipcs/' + x.dataTarget + ".html", { responseType: 'text' }).toPromise().then((z: any) => {
      x.html = this.sr.bypassSecurityTrustHtml(z);
    });
  }

  setStep(num: number) {
    if (num == this.vm.step)
      this.vm.step = null;
    else
      this.vm.step = num;
  }
}
