import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AuthGuardService } from "../services/auth-guard.service";
import { LinkService } from "../services/link.service";
import { NsBridgeService } from "../services/ns-bridge.service";
import { ProgressSpinnerComponent } from "./shared/progress-spinner/progress-spinner.component";

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DownloaderComponent } from './downloader/downloader.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';
import { DatePickerComponent } from './shared/material/date-picker/date-picker.component';
import { TimersComponent } from './timers/timers.component';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from "@angular/common";
import { RouterModule } from '@angular/router';
import { DomoticzApiClient } from "../generated/DomoticzApiClient";
import { GoogleAnalyticsEventsService } from '../services/googleAnalyticsEvents.service';
import { LoggerService } from "../services/logger.service";
import { UtilsService } from "../services/utils.service";
import { BabyComponent } from './baby/baby.component';
import { BibleComponent } from './bible/bible.component';
import { BusStopPanelComponent } from './bus-stop/bus-stop-panel/bus-stop-panel.component';
import { BusStopComponent } from './bus-stop/bus-stop.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CigarComponent } from './cigar/cigar.component';
import { FlatComponent } from './flat/flat.component';
import { FrameParentsComponent } from './frame/frame-parents/frame-parents.component';
import { FrameComponent } from './frame/frame.component';
import { ImportantNewComponent } from './important-new/important-new.component';
import { NotesDetailsComponent } from './notes/notes-details/notes-details.component';
import { NotesComponent } from './notes/notes.component';
import { NotificationComponent } from './notification/notification.component';
import { OnedriveComponent } from './onedrive/onedrive.component';
import { OpenAiComponent } from './open-ai/open-ai.component';
import { ReceipcsComponent } from './receipcs/receipcs.component';
import { SeedComponent } from './seed/seed.component';
import { SettingsComponent } from './settings/settings.component';
import { CalcFromDateComponent } from './shared/calc-from-date/calc-from-date.component';
import { HeaderForModuleComponent } from "./shared/header-for-module/header-for-module.component";
import { MaterialModule } from "./shared/material/material.module";
import { NgImageFullscreenViewModule } from "./shared/ng-image-fullscreen-view/ng-image-fullscreen-view.module";
import { ShopListComponent } from './shop-list/shop-list.component';
import { SmogComponent } from './smog/smog.component';
import { TheMovieDbComponent } from './the-movie-db/the-movie-db.component';
import { VacationComponent } from './vacation/vacation.component';
import { WeddingComponent } from './wedding/wedding.component';
import { WorkTimeComponent } from './work-time/work-time.component';
import { YesNoComponent } from './yes-no/yes-no.component';
import { DomoticzComponent } from './domoticz/domoticz.component';
import { Baby2Component } from "./baby2/baby2.component";
import { UserService } from "../services/user.service";
import { HealthComponent } from './health/health.component';

@NgModule({
   declarations: [
      AppComponent,
      HeaderComponent,
      FooterComponent,
      HomeComponent,
      DatePickerComponent,
      DownloaderComponent,
      TimersComponent,
      WeddingComponent,
      FrameComponent,
      FrameParentsComponent,
      CigarComponent,
      FlatComponent,
      SeedComponent,
      BusStopComponent,
      BusStopPanelComponent,
      BabyComponent,
      Baby2Component,
      CalcFromDateComponent,
      ImportantNewComponent,
      SettingsComponent,
      NotificationComponent,
      WorkTimeComponent,
      ShopListComponent,
      VacationComponent,
      NotesComponent,
      NotesDetailsComponent,
      SmogComponent,
      OpenAiComponent,
      CalendarComponent,
      OnedriveComponent,
      BibleComponent,
      TheMovieDbComponent,
      ProgressSpinnerComponent,
      HeaderForModuleComponent,
      ReceipcsComponent,
      YesNoComponent,
      DomoticzComponent,
      HealthComponent
   ],
   imports: [
      BrowserModule,
      CommonModule,
      RouterModule,
      ClipboardModule,
      BrowserAnimationsModule,
      BrowserModule,
      FormsModule,
      ReactiveFormsModule,
      HttpClientModule,
      MaterialModule,
      AppRoutingModule,
      NgImageFullscreenViewModule
   ],
   providers: [
      { provide: DomoticzApiClient, useFactory: DomoticzApiFactoryService, deps: [LinkService, HttpClient] },
      { provide: "DomoticzApiRetroPieClient", useFactory: DomoticzApiBackupFactoryService, deps: [LinkService, HttpClient] },
      { provide: "DomoticzApiClient", useFactory: DomoticzApiFactoryService, deps: [LinkService, HttpClient] },

      GoogleAnalyticsEventsService, LoggerService, NsBridgeService, AuthGuardService, UtilsService, UserService
   ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }

export function DomoticzApiFactoryService(linkService: LinkService, httpClient: HttpClient) {
   return new DomoticzApiClient("http://devit4.ddns.net:5000");
}

export function DomoticzApiBackupFactoryService(linkService: LinkService, httpClient: HttpClient) {
   return new DomoticzApiClient("http://devit4.ddns.net:5001");
}
