import { ImageObject } from "./image-object";

export class OpenAiChat {
    public message: string;
    public who: string;
    public time: string;
    public type: string;
    public imageObject: Array<object> = [];

    constructor(who: string, message: string, time: string, type: string) {
        this.who = who;
        this.message = message;
        this.time = time;
        this.type = type;
    }
}
