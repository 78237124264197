<div class="form">

  <app-header-for-module text="Przystanki Autobusow" src="/assets/icons/bus-stop/bus-stop.png"></app-header-for-module>

  <div class="card-group">
    <app-bus-stop-panel [stationId]="161627"></app-bus-stop-panel>
    <app-bus-stop-panel [stationId]="161624"></app-bus-stop-panel>

    <app-bus-stop-panel [stationId]="157844"></app-bus-stop-panel>
    <app-bus-stop-panel [stationId]="157847"></app-bus-stop-panel>

    <app-bus-stop-panel [stationId]="157845"></app-bus-stop-panel>
    <app-bus-stop-panel [stationId]="157846"></app-bus-stop-panel>


    <app-bus-stop-panel [stationId]="158003"></app-bus-stop-panel>
    <app-bus-stop-panel [stationId]="158004"></app-bus-stop-panel>

    <app-bus-stop-panel [stationId]="158009"></app-bus-stop-panel>
    <app-bus-stop-panel [stationId]="158010"></app-bus-stop-panel>

  </div>
</div>