<div *ngIf="vm.res && !vm.hide" class="card border-dark" style="width: 98%; margin: 5px">
    <div class="header card-header">{{vm.res?.departures[0].platformName}}
        {{vm.res?.departures[0].platformNumber}}
        <button type="button" (click)="vm.hide = true" class="btn-close" aria-label="Close"></button>
    </div>
    <div class="card-body">
        <div class="row" *ngFor="let p of vm.res.departures; trackBy: trackByIndex">
            <div class="col-2">
                {{p.lineNumberText}}
            </div>
            <div class="col">
                {{p.destinationName}}
            </div>
            <div class="col-3">
                {{parseInt(p.real / 60)}} min
            </div>
        </div>
    </div>
    <div class="card-footer">
        Ostatnia aktualizacja: {{vm.lastUpdate | date: 'HH:mm:ss'}}
    </div>
</div>