import { Component, OnDestroy, inject } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { DomoticzApiClient } from "../../../generated/DomoticzApiClient";
import { NsBridgeService } from "../../../services/ns-bridge.service";
import { UtilsService } from "../../../services/utils.service";
import { UtilsUser } from "../../../utils/utils-user";
import { RoleEnum } from "../enums/role.enum";

declare var $: any;

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export abstract class BaseComponent implements OnDestroy {

  public dialog: MatDialog = inject(MatDialog);
  public route: ActivatedRoute = inject(ActivatedRoute);
  public router: Router = inject(Router);

  public api: DomoticzApiClient = inject(DomoticzApiClient);
  public utilsService: UtilsService = inject(UtilsService);
  public androidBridge: NsBridgeService = inject(NsBridgeService);

  private snackBar: MatSnackBar = inject(MatSnackBar);
  private titleService: Title = inject(Title);

  public userName: string = UtilsUser.getUserName();
  public isOffline: boolean = this.isOfflineMode();

  constructor() {
    this.isOfflineMode();
  }

  isOfflineMode() {
    return localStorage.getItem('IsOffline') == 'true';
  }

  back() {
    this.router.navigateByUrl('');
  }

  isAdmin() {
    return this.userName == RoleEnum.Admin;
  }

  isDavid() {
    return this.userName == RoleEnum.Admin || this.userName == RoleEnum.Dawid || this.userName == RoleEnum.David;
  }

  isMadzia() {
    return this.userName == RoleEnum.Madzia;
  }

  setTitle(value: string) {
    this.titleService.setTitle(value);
  }

  displaySnack(value: string) {
    this.snackBar.open(value, '', {
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
      duration: 2 * 1000
    });
  }

  trackByIndex($index: any) {
    return $index;
  }

  public async saveInLocalStorage(key: string, model: any) {
    try {
      let json = JSON.stringify(model);
      localStorage.setItem(key, json);
      this.androidBridge.emit(key, json);
    } catch (e) {
      console.error("Nie udało się zserializować danych");
    }
  }

  public loadFromLocalStorage(key: string) {
    try {
      let json = JSON.parse(localStorage.getItem(key));
      return json;
    } catch (e) {
      console.error("Nie udało się sparsować danych");
      return null;
    }
  }

  ngOnDestroy(): void {
    //this.isOffline = sessionStorage.getItem('isOffline') == "true";
    if (typeof $ == 'function' && $('.offcanvas'))
      $('.offcanvas').offcanvas('hide');
  }
}
