export class NotesModel {
    value: string;
    context: string;
    key: string;

    constructor(value: string, context: string, key: string) {
        this.value = value;
        this.context = context;
        this.key = key;
    }
}