<div class="form pb-5">
  <app-header-for-module text="Przepisy" src="/assets/icons/receipcs/receipcs.png"></app-header-for-module>

  <div *ngFor="let el of vm.receipts" class="item">

    <mat-card class="mt-3 cursor-pointer" (click)="showDetails(el)">
      <mat-card-content>
        <b>{{el.name}}</b>
      </mat-card-content>
    </mat-card>

  </div>
</div>