import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { LoggerService } from "../../../services/logger.service";
import { NsBridgeService } from "../../../services/ns-bridge.service";
import { BaseComponent } from '../../../core/base/base/base.component';
import { environment } from '../../../environments/environment';
import { LinkService } from '../../../services/link.service';
import { UtilsString } from '../../../utils/utils-string';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent {

  public newRibbon: boolean = true;
  public isLoading: boolean = false;
  public isBridge: boolean = false;
  public version = environment.version;
  public appName = environment.name;
  public versionNS: string;

  constructor(
    public linkService: LinkService,
    public loggerService: LoggerService,
    public nsBridgeService: NsBridgeService,
    private zone: NgZone,
    private cdr: ChangeDetectorRef) {
    super();

    document.addEventListener("DOMContentLoaded", () => {
      this.isLoading = false;
    });

    this.bridgeNativeScript();
    this.bridgeNativeAndroid();
  }



  openMenuNative() {
    //this.nsBridgeService.emit('OpenMenu', null);
  }

  promptLogout() {
    // if (this.nsBridgeService.isBridge())
    //  return;

    this.utilsService.openPrompt("Na pewno chcesz się wylogować?", "Wylogowanie", (x: any) => {
      if (!x)
        return;

      if (x && x.split(' ')[0].toLowerCase() === 'ds') {
        localStorage.setItem('user', UtilsString.capitalizeFirstLetter(x.split(' ')[1]));
        location.reload();
      } else {
        localStorage.clear();
        sessionStorage.clear();
        location.reload();
      }
    });
  }

  bridgeNativeScript() {
    window.addEventListener("ns-bridge-ready", e => {

      this.isBridge = true;

      this.nsBridgeService.listen("VersionApp", x => {
        this.zone.run(() => {
          this.versionNS = x;
        });
      });

      this.nsBridgeService.emit('LoadedApp', null);
    });
  }


  bridgeNativeAndroid() {
    window.addEventListener("android-bridge-ready", e => {

      this.isBridge = true;

      this.nsBridgeService.eventListener("VersionApp", x => {
        this.zone.run(() => this.versionNS = x.detail);
        this.nsBridgeService.emit('LoadedApp', environment.version);
      });

      this.nsBridgeService.eventListener("IsOffline", x => {
        this.zone.run(() => {
          this.isOffline = x.detail == 'true';

          //this.displaySnack("isOffline: " + x.detail + " : " + this.isOffline);
          localStorage.setItem('IsOffline', this.isOffline.toString());
        });
      });
    });

    // this.nsBridgeService.eventListener("GetLocalStorage", x => {
    //   console.log(localStorage.getItem(x.detail));
    //   this.nsBridgeService.emit("GetLocalStorage", localStorage.getItem(x.detail));
    // });


    //Koniec działa android-bridge-ready
    //});
  }
}
