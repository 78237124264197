<div class="form">
	<app-header-for-module text="Pomocnik Filmowy"
		src="/assets/icons/the-movie-db/the-movie-db.png"></app-header-for-module>
</div>

<div class="m-2 center row mt-3">

	<div class="col-12 col-sm-6 col-md-3 mt-3 min-width ">
		<div class="row">
			<div class="col">
				Gatunek:
			</div>
			<div class="col">
				<select [(ngModel)]="request.genre" class="width-select">
					<option *ngFor="let g of genreList; trackBy: trackByIndex" [value]="g.id">{{g.name}}</option>
				</select>
			</div>
		</div>
	</div>

	<div class="col-12 mt-3 col-sm-6 col-md-3 min-width ">
		<div class="row">
			<div class="col">
				Sortowanie:
			</div>
			<div class="col">
				<select [(ngModel)]="request.sort" class="width-select">
					<option value="popularity.desc">Popularność</option>
					<option value="release_date.desc">Data wydania</option>
					<option value="original_title.asc">Tytuł</option>
					<option value="vote_average.desc">Średnia głosów</option>
					<option value="vote_count.desc">Liczba głosów</option>
				</select>
			</div>
		</div>
	</div>

	<div class="col-12 col-sm-6 col-md-3 mt-3 min-width">
		<div class="row">
			<div class="col">
				Rok:
			</div>
			<div class="col">
				<select [(ngModel)]="request.year" class="width-select">
					<option *ngFor="let g of yearList; trackBy: trackByIndex" [value]="g.id">{{g.name}}</option>
				</select>
			</div>
		</div>
	</div>

	<div class="col-12 col-sm-6 col-md-3 mt-3 min-width">
		<div class="row">
			<div class="col">
				Ocena:
			</div>
			<div class="col">
				<select [(ngModel)]="request.voteAverageGte" class="width-select">
					<option *ngFor="let g of rateList; trackBy: trackByIndex" [value]="g.id">{{g.name}}</option>
				</select>
			</div>
		</div>
	</div>

	<div class="col-12 col-sm-6 col-md-3 mt-3 min-width">
		<div class="row">
			<div class="col">
				Wszystkie:
			</div>
			<div class="col">
				<select [(ngModel)]="request.showAllMovies" class="width-select">
					<option [ngValue]="true">Tak</option>
					<option [ngValue]="false">Nie</option>
				</select>
			</div>
		</div>
	</div>

	<div class="col-12 col-sm-6 col-md-3 mt-3 min-width">
		<div class="row">
			<div class="col">
				Ukryj pobrane:
			</div>
			<div class="col">
				<select [(ngModel)]="request.hideDownloaded" class="width-select">
					<option [ngValue]="true">Tak</option>
					<option [ngValue]="false">Nie</option>
				</select>
			</div>
		</div>
	</div>

</div>

<div class="center">
	<a class="col-sm p-10 lavender-btn mt-3 me-2" routerLink="/notes-details/Filmy do pobrania" routerLinkActive="active"
		mat-raised-button color="warn">Do pobrania</a>

	<a class="col-sm p-10 lavender-btn mt-3 me-2" routerLink="/downloader" routerLinkActive="active" mat-raised-button
		color="warn">Downloader</a>

	<button class="col-sm p-10 lavender-black-btn mt-3" (click)="searchAndReset()" mat-raised-button
		color="warn">Szukaj</button>
</div>


<div class="mt-2 center" *ngIf="model">
	<hr />

	<button class="col-sm p-10 lavender-btn me-3 mt-3" [disabled]="request.page == 1" (click)="previousPage()"
		mat-raised-button color="warn">Poprzednia</button>

	<input type="text" [(ngModel)]="request.page" style="width:20px;border: none; background: transparent;" /> z
	{{modelPagging?.total_pages || 0}}

	<button class="col-sm p-10 lavender-btn ms-3 mt-3" [disabled]="request.page == modelPagging?.total_pages"
		(click)="nextPage()" mat-raised-button color="warn">Następna</button>

	<hr />
</div>

<app-progress-spinner [isShow]="!model"></app-progress-spinner>

<div class="m-3 row" *ngFor="let el of model; trackBy: trackByIndex">

	<div class="col-12 col-md-8">
		<div class="mb-3">
			<div>
				<h1 [ngClass]="{'green': el.folderScylla, 'gold-and-hover' : el.existOnListToDownload}"
					(dblclick)="openInBlank('https://www.filmweb.pl/search#/?query=' + el.data.title)"
					(contextmenu)="openInBlank('https://www.google.pl/search?q=' + el.data.title + ' cda')">
					<b>{{el.data.title}} ({{getYear(el.data.release_date)}})</b>
				</h1>
			</div>

			<h2 (dblclick)="openInBlank(el.link)">
				{{el.data.original_title}} ({{getYear(el.data.release_date)}})
			</h2>
		</div>


		<div style="overflow: hidden;">
			<b>Nazwy plików:</b>
			<br />
			<div (dblclick)="openRapideoAndCopy(el.rapideoFileTitle)">
				{{el.rapideoFileTitle}}
			</div>

			<div (dblclick)="openRapideoAndCopy(el.rapideoFileOriginalTitle)">
				{{el.rapideoFileOriginalTitle}}
			</div>
			<br />
		</div>

		<div>
			Ocena: <b>{{el.data.vote_average}}</b> <br />
			Liczba ocen: {{el.data.vote_count}} <br />
			Data wydania: {{el.data.release_date}} <br />
			Popularność: {{el.data.popularity}}
		</div>
		<br />

		<div (click)="getSimilar(el)" data-bs-target="#similar" data-bs-toggle="offcanvas" aria-controls="offcanvasTop"
			mat-raised-button color="warn">Podobne</div>

		<br />

		<div (click)="getRecomendations(el)" data-bs-target="#similar" data-bs-toggle="offcanvas"
			aria-controls="offcanvasTop" mat-raised-button color="warn">Rekomendacje</div>
		<br />

		<div>
			{{el.data.overview}}
		</div>
		<br /> <br />
	</div>

	<div class="col-12 col-md-4 center mb-3">
		<img (dblclick)="el.data.bigSize = true" *ngIf="!el.data.bigSize"
			src="https://image.tmdb.org/t/p/w185/{{el.data.poster_path}}" />
		<img (dblclick)="el.data.bigSize = false" *ngIf="el.data.bigSize"
			src="https://image.tmdb.org/t/p/w300/{{el.data.poster_path}}" />
	</div>

	<div class="center mb-3 cursor-pointer" *ngIf="!el.folderScylla">
		<div class="green" *ngIf="!el.existOnListToDownload" (click)="addToDownloadList(el)">
			Dodaj do listy do Pobrania
			<i class="fa fa-plus bigger"></i>
		</div>

		<div class="red" *ngIf="el.existOnListToDownload" (click)="removeFromDownloadList(el)">
			Usuń z listy do Pobrania
			<i class="fa fa-minus bigger"></i>
		</div>
	</div>

	<hr />

</div>

<div class="m-2 center" *ngIf="model">
	<button class="col-sm p-10 lavender-btn me-3" [disabled]="request.page == 1" (click)="previousPage()"
		mat-raised-button color="warn">Poprzednia</button>

	<input type="text" [(ngModel)]="request.page" style="width:20px;border: none; background: transparent;" /> z
	{{modelPagging?.total_pages || 0}}

	<button class="col-sm p-10 lavender-btn ms-3" [disabled]="request.page == modelPagging?.total_pages"
		(click)="nextPage()" mat-raised-button color="warn">Następna</button>
</div>


<mat-card *ngIf="model?.length === 0" class="center">
	<mat-card-content>
		Brak filmów spełniających podane kryteria 🥰
	</mat-card-content>
</mat-card>



<div class="offcanvas offcanvas-top similarMovies" tabindex="-1" id="similar" aria-labelledby="offcanvasTopLabel">
	<div class="offcanvas-header">
		<h1 id="offcanvasTopLabel">{{similarModel.name}} <b>{{similarModel.movie}}</b></h1>
		<button type="button" class="btn-close text-reset red" data-bs-dismiss="offcanvas" aria-label="Close"></button>
	</div>
	<hr />
	<div class="offcanvas-body">

		<div class="m-2 center" *ngIf="this.similarModel.data">
			<button class="col-sm p-10 lavender-btn me-3" [disabled]="similarModel.data.root.page == 1"
				(click)="previousPageSub()" mat-raised-button color="warn">Poprzednia</button>

			<input type="text" [(ngModel)]="similarModel.data.root.page"
				style="width:20px;border: none; background: transparent;" /> z
			{{similarModel.data.root.total_pages || 0}}

			<button class="col-sm p-10 lavender-btn ms-3"
				[disabled]="similarModel.data.root.page == similarModel.data.root.total_pages" (click)="nextPageSub()"
				mat-raised-button color="warn">Następna</button>
		</div>

		<app-progress-spinner [isShow]="!similarModel.data?.moviesToDownload"></app-progress-spinner>

		<div class="m-3 row" *ngFor="let el of similarModel.data?.moviesToDownload; trackBy: trackByIndex">
			<div class="col-12 col-md-8">
				<div class="mb-3">
					<div>
						<h1 [ngClass]="{'green': el.folderScylla, 'gold-and-hover' : el.existOnListToDownload}"
							(dblclick)="openInBlank('https://www.filmweb.pl/search#/?query=' + el.data.title)"
							(click)="openInBlank('https://www.google.pl/search?q=' + el.data.title + ' cda')">
							<b>{{el.data.title}} ({{getYear(el.data.release_date)}})</b>
						</h1>
					</div>

					<h2 (dblclick)="openInBlank(el.link)">
						{{el.data.original_title}} ({{getYear(el.data.release_date)}})
					</h2>
				</div>

				<div style="overflow: hidden;">
					<b>Nazwy plików:</b>
					<br />
					<div (dblclick)="openRapideoAndCopy(el.rapideoFileTitle)">
						{{el.rapideoFileTitle}}
					</div>

					<div (dblclick)="openRapideoAndCopy(el.rapideoFileOriginalTitle)">
						{{el.rapideoFileOriginalTitle}}
					</div>
					<br />
				</div>

				<div>
					Ocena: <b>{{el.data.vote_average}}</b> <br />
					Liczba ocen: {{el.data.vote_count}} <br />
					Data wydania: {{el.data.release_date}} <br />
					Popularność: {{el.data.popularity}}
				</div>
				<br />

				<div>
					{{el.data.overview}}
				</div>
				<br /> <br />
			</div>

			<div class="col-12 col-md-4 center mb-3">
				<img (dblclick)="el.data.bigSize = true" *ngIf="!el.data.bigSize"
					src="https://image.tmdb.org/t/p/w185/{{el.data.poster_path}}" />
				<img (dblclick)="el.data.bigSize = false" *ngIf="el.data.bigSize"
					src="https://image.tmdb.org/t/p/w300/{{el.data.poster_path}}" />
			</div>

			<div class="center mb-3 cursor-pointer" *ngIf="!el.folderScylla">
				<div class="green" *ngIf="!el.existOnListToDownload" (click)="addToDownloadList(el)">
					Dodaj do listy do Pobrania
					<i class="fa fa-plus bigger"></i>
				</div>

				<div class="red" *ngIf="el.existOnListToDownload" (click)="removeFromDownloadList(el)">
					Usuń z listy do Pobrania
					<i class="fa fa-minus bigger"></i>
				</div>
			</div>

			<hr />

		</div>

		<div class="m-2 center" *ngIf="this.similarModel.data?.moviesToDownload">
			<button class="col-sm p-10 lavender-btn me-3" [disabled]="similarModel.data.root.page == 1"
				(click)="previousPageSub()" mat-raised-button color="warn">Poprzednia</button>

			<input type="text" [(ngModel)]="similarModel.data.root.page"
				style="width:20px;border: none; background: transparent;" /> z
			{{similarModel.data.root.total_pages || 0}}

			<button class="col-sm p-10 lavender-btn ms-3"
				[disabled]="similarModel.data.root.page == similarModel.data.root.total_pages" (click)="nextPageSub()"
				mat-raised-button color="warn">Następna</button>
		</div>

	</div>
</div>