import { AfterViewInit, Component, Inject } from '@angular/core';

import { UtilsDate } from '../../utils/utils-date';
import { BaseComponent } from '../../core/base/base/base.component';
import { DomoticzApiClient } from '../../generated/DomoticzApiClient';


@Component({
  selector: 'app-work-time',
  templateUrl: './work-time.component.html',
  styleUrls: ['./work-time.component.scss']
})
export class WorkTimeComponent extends BaseComponent implements AfterViewInit {

  public selectedRaport = 0;
  public year = UtilsDate.new().year();
  public data: any;
  public isColor: boolean = false;

  constructor(
    private client: DomoticzApiClient,
    @Inject("DomoticzApiRetroPieClient") retroApi: DomoticzApiClient) {
    super();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.raport(3);
    }, 1000);
  }

  public plus() {
    if (this.year < UtilsDate.new().year()) {
      this.year++;
      this.raport(this.selectedRaport);
    }
  }

  public minus() {
    if (this.year > 2017) {
      this.year--;
      this.raport(this.selectedRaport);
    }
  }

  public raport(val: number) {
    this.data = null;
    this.selectedRaport = val;
    switch (this.selectedRaport) {
      case 1:
        this.api.apiRCPEntries(this.year).then(x => this.data = x);
        break;
      case 2:
        this.api.apiRCPGetEmployeeOldRaport(this.year).then(x => this.data = x);
        break;
      case 3:
        this.api.apiRCPGetEmployeeRaport(this.year).then(x => {
          this.data = x;
          this.data.months.forEach((entry: any) => {
            this.getTimeRoznica(entry.fullWorkedTimeMinutes - entry.workDay * 8 * 60, entry);
          });
        });
        break;
    }
  }

  public getClass(entry: any) {

    if (!this.isColor)
      return null;

    var hours = Number.parseInt(entry.summaryTime.split(':')[0]);
    if (hours < 5)
      return 'error';
    else if (hours < 7)
      return 'warning';
    else if (hours >= 8)
      return 'overtime';
    else
      return null;
  }

  public getTime(num: number) {

    let isNgetaive = false;
    if (num < 0) {
      num = num * -1;
      isNgetaive = true;
    }

    var hours = Math.floor(num / 60);
    var minutes = Math.floor(num % 60);
    let minutesString = minutes < 10 ? "0" + minutes : minutes.toString();

    if (!isNgetaive)
      return hours + ":" + minutesString;
    else
      return "-" + hours + ":" + minutesString;
  }

  public getTimeRoznica(num: number, element: any) {
    if (element.month >= UtilsDate.new().month() + 2 && this.year == UtilsDate.new().year())
      return null;

    let isNegative = false;
    if (num < 0) {
      num = num * -1;
      isNegative = true;
    }

    var hours = Math.floor(num / 60);
    var minutes = Math.floor(num % 60);
    let minutesString = minutes < 10 ? "0" + minutes : minutes.toString();

    if (isNegative) {
      element.minutes = -(hours * 60 + minutes);
      return "-" + hours + ":" + minutesString;
    }
    else {
      element.minutes = (hours * 60 + minutes);
      return hours + ":" + minutesString;
    }
  }

  public getSummary(attr: string) {
    var summary = 0;

    this.data?.months?.forEach((element: any) => {
      if (element[attr])
        summary += element[attr];
    });
    return summary;
  }

  public getDayofWeek(name: string) {
    return UtilsDate.getDayofWeek(name);
  }
}
