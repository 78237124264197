<div class="center mt-10">
  <h2>{{result}}</h2>
</div>

<hr />

<div style="margin:10px">
  <!-- <b>Produkcja dzienna</b> -->
  <!-- <p>W ciągu doby mężczyzna produkuje niespełna 100 milionów plemników.</p> -->
  <p>Aktualnie wyprodukowano {{ 1000000 * getBarVal('.bar-1') / 1000000}} milionów</p>

  <div class="progress">
    <div class="bar-1 progress-bar progress-bar-striped progress-bar-animated progress-bar-animated" role="progressbar"
      aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
  </div>

  <hr />
</div>

<div *ngIf="false" style="margin:10px">
  <b>Produkcja dla najlepszej jakości nasienia</b>
  <p>Nie należy spożywać alkoholu przez minimum dwie doby / najlepiej pięć</p>

  <div class="progress">
    <div class="bar-2 progress-bar progress-bar-striped progress-bar-animated progress-bar-animated" role="progressbar"
      aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
  </div>

  <hr />
</div>

<div *ngIf="false" style="margin:10px">
  <b>Produkcja dla najlepszej jakości nasienia</b>
  <p>W celu uzyskania maksymalnej jakości nasienia na 3-5 dni przed badaniem należy zachować też abstynencję seksualną
    (powstrzymanie się od wytrysku).</p>

  <div class="progress">
    <div class="bar-12 progress-bar progress-bar-striped progress-bar-animated progress-bar-animated" role="progressbar"
      aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
  </div>

  <hr />
</div>

<br />
<div *ngIf="false" style="margin:10px">
  <p> Do codziennego jadłospisu należy wprowadzić zdrowe produkty bogate w selen, cynk, witaminę E, C, antyoksydanty, a
    także zawierające kwasy omega 3, które mogą wzmocnić plemniki. Jednocześnie trzeba wystrzegać się fast foodów,
    słodyczy, a także używek, w tym alkoholu i papierosów. Czasem sama dieta nie pomoże, niezbędna może okazać się
    suplementacja, którą najlepiej przeprowadzić pod okiem lekarza. Poza dietą mężczyźni powinni dbać o kondycję
    fizyczną,
    unikać przegrzewania jąder, a także zwrócić uwagę na swoje zdrowie psychiczne. Nadmiar stresu nie jest bowiem
    korzystna w codziennym życiu pary starającej się o dziecko, jak i dla plemników.</p>

  <p>
    Para starająca się o dziecko powinna współżyć ze sobą nie tylko w dni płodne. Regularna aktywność seksualna poprawia
    bowiem jakość nasienia i pobudza jego produkcję. Zatem jak zwiększyć ruchliwość plemników? Zalecane jest uprawianie
    seksu 2-3 razy w tygodniu, dzięki temu plemnik będzie miał najlepsze parametry, zarówno pod względem ruchliwości,
    koncentracji i ruchu postępowego.
  </p>



  <br /><b>Kwas foliowy</b> <br /><br />

  O suplementowaniu kwasu foliowego często jest mowa w kontekście kobiet starających się o dziecko i będących w ciąży.
  Jest on natomiast również ważny dla organizmu mężczyzn.

  Odpowiednia ilość kwasu foliowego w organizmie zapobiega chociażby ewentualnym zaburzeniom w strukturze materiału
  genetycznego nasienia.

  Źródła kwasu foliowego:

  szpinak,
  brokuły,
  ryż,
  pomarańcze,
  banany,
  produkty zbożowe,
  kurza i wołowa wątróbka,
  jaja.

  <br /><br /><b>Selen</b><br /><br />

  Wprawdzie selen nie występuje w organizmie w dużych ilościach, ale pełni w nim ważną funkcję, także w obszarze
  płodności mężczyzn. Poprawia ruchliwość plemników oraz ich liczebność. Wspiera także metabolizm testosteronu.

  Źródła selenu:

  orzechy brazylijskie,
  nasiona słonecznika,
  owoce morza i ryby,
  drób,
  czosnek,
  cebula
  ryż brązowy.

  <br /><br /><b>Cynk</b><br /><br />

  Cynk bierze udział w procesie spermatogenezy, czyli wytwarzania plemników, oddziałuje także na ich żywotność. Wpływa
  na wytwarzanie hormonów płciowych, a także na libido, i proces zapłodnienia. Niedobór tego mikroelementu może
  prowadzić do zaburzeń płodności.

  Źródła cynku:

  nabiał,
  ryby,
  jaja,
  pestki dyni i słonecznika,
  szpinak,
  orzechy,
  mięso.

  <br /><br /><b>Witamina E</b><br /><br />

  Witamina E polepsza jakość plemników. Co więcej, połączenie witaminy E z selenem może okazać się pomocne w leczenia
  raka prostaty, a nawet w uniknięciu zachorowania na ten nowotwór.

  Źródła witaminy E:

  ryby,
  oleje roślinne,
  orzechy,
  papryka,
  szpinak,
  biała kapusta.

  <br /><br /><b>Witamina C</b><br /><br />

  O korzystnym wpływie witaminy C na organizm słyszymy dosyć często, zwłaszcza w okresie wzmożonych przeziębień.
  Znacznie mniej osób wie, że witamina C chroni także DNA plemników przed niepożądanymi zmianami. Jest naturalnym
  antyoksydantem neutralizującym i usuwającym z organizmu wolne rodniki, które uszkadzają komórki. Wspomaga również
  działanie witaminy E.

  Źródła witaminy C:

  owoce dzikiej róży,
  czarne porzeczki,
  cytrusy,
  papryka,
  brukselka,
  szpinak,
  kalafior.

  <br /><br /><br /><br />
  Badanie, które zostało zaprezentowane podczas dorocznej konferencji American Society for Reproductibe Medicine w
  Denver również wykazało, że kobiety, które piły jeden napój alkoholowy co drugi dzień, było o 26 procent mniej podatne
  na poczęcie dziecka, niż kobiety, które ograniczały picie napojów wysokoprocentowych.

  <br /><br />
  Badania prowadzone przez Gaskina i wsp. udowodniły, że odpowiednia ilość ruchu wpływa na poprawę jakości nasienia i
  poziomu testosteronu. Badacze wykazali, że mężczyźni, którzy uprawiali sport przez 12 godzin w tygodniu, mieli
  zdecydowanie większą liczbę plemników, aniżeli panowie prowadzący siedzący tryb życia (nawet o 73 proc.).

  <br /><br />
  Ashwagandha, czyli żeń-szeń indyjski, od dawna odgrywa rolę w tradycyjnych lekach jako lekarstwo na kilka form
  zaburzeń seksualnych.

  Badanie z 2013 roku wykazało, że 46 mężczyzn z niską liczbą plemników, którzy przyjmowali 675 mg ashwagandhy dziennie
  przez 90 dni, odnotowało wzrost liczby plemników o 167%. Ich ilość spermy została skutecznie zwiększona dzięki tej
  niesamowitej roślinie.

</div>