import { CdkTableModule } from '@angular/cdk/table';
import { NgModule } from '@angular/core';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';


import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';

import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';

import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';

import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
    exports: [
        // CdkTableModule,
         MatAutocompleteModule,
         MatButtonModule,
      //   MatButtonToggleModule,
         MatCardModule,
         MatFormFieldModule,
         MatCheckboxModule,
      //   MatChipsModule,
         MatDatepickerModule,
      //   MatDialogModule,
      //   MatExpansionModule,
      //   MatGridListModule,
      //   MatIconModule,
         MatInputModule,
      //   MatListModule,
      //   MatMenuModule,
      //   MatNativeDateModule,
      //   MatPaginatorModule,
      //   MatProgressBarModule,
         MatProgressSpinnerModule,
      //   MatRadioModule,
      //   MatSelectModule,
      //   MatSidenavModule,
      //   MatSliderModule,
      //   MatSlideToggleModule,
      //   MatSnackBarModule,
      //   MatSortModule,
      //   MatTabsModule,
      //   MatToolbarModule
    ], providers: [MatDatepickerModule]
})

export class MaterialModule { }
