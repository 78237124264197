<div class="form">

  <app-header-for-module text="Kalendarz" src="/assets/icons/calendar/calendar.png"></app-header-for-module>

  <app-progress-spinner [isShow]="!vm.calendarElements"></app-progress-spinner>

  <div class="container mb-5">
    <div class="row mt-3" *ngFor="let h of vm.calendarElements; let i = index; trackBy: trackByIndex"
      [attr.data-index]="i">
      <div class=" col">
        <div class="card">
          <div class="card-header d-flex justify-content-between p-3" [ngClass]="{'odd' : i%2 == 0, 'even': i%2== 1}">
            <p class="fw-bold mb-0"> {{h.date | date:'dd'}} {{getMonth(h.date | date:'MMMM')}}</p>

            <div *ngIf="!isOffline" (click)="sendNotification(h)">
              <i class=" far fa-bell gold cursor-pointer"></i>
            </div>

            <p class=" text-muted small mb-0">
              {{getDayofWeek(h.date | date:'EEEE')}}, {{h.date | date:'HH:mm'}} <i class="far fa-clock black"></i>
            </p>
          </div>

          <div class="card-body">
            <p class="mb-0">
              {{h.summary}}
            </p>
          </div>

        </div>
      </div>
    </div>

  </div>