<div class="form">

  <app-header-for-module text="Notyfikacje" src="/assets/icons/notification/notification.png"></app-header-for-module>


  <div class="center">

    <mat-card *ngFor="let el of history" style="margin-bottom: 20px;">
      <mat-card-content>
        <b>{{el.title}}</b> ({{el.date | date:'dd/MM HH:mm'}}) <br />
        {{el.message}}
      </mat-card-content>
    </mat-card>
  </div>


  <mat-form-field style.fontSize="16px" class="example-full-width">
    <mat-label>Wiadomość</mat-label>
    <textarea matInput cdkTextareaAutosize (keyup)="message" [(ngModel)]="message" #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
  </mat-form-field>

  <div class="row" *ngIf="userName ==='Admin' || userName === 'Dawid'">
    <button class="col-sm lavender-black-btn" [disabled]="!message" (click)="sendTo('Mama', 'Dawid 💖')"
      mat-raised-button color="warn">Wyślij do Davida</button>
  </div>

  <div class="row" *ngIf="userName === 'Admin' || userName ==='Mama'">
    <button class="col-sm lavender-black-btn" [disabled]="!message" (click)="sendTo('Lukasz', userName)"
      mat-raised-button color="warn">Wyślij do Łukasza</button>
  </div>

  <div class="row" *ngIf="userName === 'Madzia'">
    <button class="col-sm lavender-black-btn" [disabled]="!message" (click)="sendTo('Dawid', 'Żonka 💖')"
      mat-raised-button color="warn">Wyślij do Dawida</button>
  </div>

  <div class="row"
    *ngIf="userName ==='Admin' || userName ==='Mama' || userName === 'Test' || userName === 'Lukasz' || userName === 'Dawid' || userName === 'David' ">
    <button class="col-sm lavender-black-btn" [disabled]="!message" (click)="sendTo('Dawid', userName)"
      mat-raised-button color="warn">Wyślij do Dawida</button>
  </div>

  <div class="row" *ngIf="userName === 'Dawid' || userName ==='Admin' || userName === 'David'">
    <button class="col-sm lavender-black-btn" [disabled]="!message" (click)=" sendTo('Madzia', 'Mężuś 💖' )"
      mat-raised-button color="warn">Wyślij do Magdy</button>
  </div>

  <div class="row">
    <button class="col-sm green-btn" (click)="back()" mat-raised-button color="warn">Powrót</button>
  </div>

</div>