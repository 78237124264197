import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-for-module',
  templateUrl: './header-for-module.component.html',
  styleUrls: ['./header-for-module.component.scss']
})
export class HeaderForModuleComponent {

  @Input() src: string;
  @Input() text: string;

}
