import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LinkService {

  getUrl() {

   if (location.host.indexOf('localhost') >= 0)
        return 'http://192.168.1.116';


    if (location.origin.startsWith('http://'))
      return 'http://' + location.hostname;
    else
      return 'https://' + location.hostname;
  }
}
