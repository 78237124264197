<div>
	<div class="row cursor-pointer mb-2">

		<div class="element col-12 col-sm-6 col-lg-4 scrollable-element"
			*ngFor="let el of menuElements; trackBy: trackByIndex" (click)="enter(el)" (contextmenu)="displayUrl(el, $event)"
			[ngClass]="'column1'" [ngStyle]="{ 'visibility': !isLoading ? 'visible' : 'hidden' }">

			<div class="content center column1 noselect">
				<img [src]="el.imgSrc ? el.imgSrc : '/assets/icons/no-image/no-image.png'" (load)="imageLoaded()" width="40px"
					height="40px" />
				{{el.name}}
			</div>

		</div>
	</div>
</div>

<div *ngIf="showMenu" [style.top.px]="top" [style.left.px]="left" class="dropdown contextMenu">
	<ul class="dropdown-menu show">
		<li><a class="dropdown-item" (click)="openInNewWindow()">Otwórz w nowym oknie</a></li>
		<li role="separator" class="dropdown-divider"></li>
		<li><a class="dropdown-item" (click)="closeContextMenu()">Zamknij</a></li>
	</ul>
</div>