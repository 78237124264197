import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from "@angular/forms";
import { Observable, Subscription, interval } from 'rxjs';
import { startWith } from 'rxjs/internal/operators/startWith';
import { map } from 'rxjs/operators';
import { UtilsService } from "../../services/utils.service";
import { SaveLocationEnum } from './enums/saveLocation.enum';
import { DownloadModel } from './models/download.model';
import { GuidModel } from "./models/guid.model";
import { BaseComponent } from '../../core/base/base/base.component';
import { UtilsString } from '../../utils/utils-string';
import { clone, cloneDeep, filter } from 'lodash-es';

@Component({
	selector: 'app-downloader',
	templateUrl: './downloader.component.html',
	styleUrls: ['./downloader.component.scss']
})
export class DownloaderComponent extends BaseComponent implements OnInit, OnDestroy {

	@ViewChild('content') content: ElementRef;

	private subscription: Subscription;

	public model: DownloadModel = new DownloadModel();
	public lastModels: Array<DownloadModel> = [];
	public isDownloaded: boolean = false;

	public folderControl = new UntypedFormControl();
	public folders: any = [];
	public filteredFolders: Observable<string[]>;

	public logs: any;

	public saveLocationItems: string[] = ["Inne/youtube-dl", "Filmy", "Horrory", "Animowane", "Seriale", "Muzyka"];

	public settings: any = {
		isDownloadMulti: false,
		isDownloadAsync: false
	};
	subscriptionInterval: Subscription;

	constructor(
		public httpClient: HttpClient,
		public utilsService: UtilsService) {
		super();
		super.setTitle(`Downloader - Dev IT`);
		this.getFromLocalStorage();
	}

	ngOnInit() {
		this.filteredFolders = this.folderControl.valueChanges.pipe(startWith(''), map((value: string) => this.filter(value)));
		this.getFolders();
		this.logsCanvasEvents();

		setTimeout(() => {
			this.getDownloadList().then(() => {
				this.isDownloaded = true;
				let intervalTime = this.settings.isDownloadMulti ? 3000 : 1000;

				this.subscription = interval(intervalTime).subscribe(x => {
					var guids = this.lastModels.filter(x => !x.status).map(x => x.guid);

					this.api.apiDownloaderGetFilePercentages(guids).then(y => {
						y.forEach(e => {
							let el = this.lastModels.find(x => x.guid == e.guid);
							el.percetage = e.text;
							this.setStatusFromPercentage(el);
						});
					});
				});
			}, () => this.isDownloaded = true);
		}, 1000);
	}

	getModuleName() {
		if (!this.settings.isDownloadMulti)
			return 'Downloader';
		else
			return "Downloader - Multi"
	}

	logsCanvasEvents() {
		const myOffcanvas = document.getElementById('log')

		myOffcanvas.addEventListener('show.bs.offcanvas', () => { })

		myOffcanvas.addEventListener('shown.bs.offcanvas', () => {
			this.content.nativeElement.scrollTop = this.content.nativeElement.scrollHeight;
		});

		myOffcanvas.addEventListener('hide.bs.offcanvas', () => {
			this.logs = [];
			this.subscriptionInterval.unsubscribe();
		});
	}

	setStatusFromPercentage(el: any) {
		if (el.percetage.indexOf('100%') >= 0
			|| el.percetage.indexOf('100.0%') >= 0
			|| el.percetage.indexOf('Error') >= 0
			|| el.percetage.indexOf('Deletwg origwal file') >= 0
			|| el.percetage.indexOf('Deleting original file') >= 0)
			el.status = true;
	}

	filter(value: string): string[] {
		setTimeout((() => {
			this.model.folderName = value;
		}), 150);

		const filterValue = UtilsString.normalize(value.toLowerCase());

		return this.folders.filter((option: string) =>
			UtilsString.normalize(option.toLowerCase())
				.includes(filterValue));
	}

	getVisibleQualitySection() {
		return this.model.link && this.model.link.indexOf('cda.pl') > -1;
	}

	getFolders(isShouldClearFolderName: boolean = true) {
		return this.api.apiDownloaderGetFolders(this.model.saveLocation).then(x => {
			this.folders = x;
			if (isShouldClearFolderName) {
				this.model.folderName = '';
				this.folderControl.setValue('');
			}
		});
	}

	getDownloadList() {
		return this.api.apiDownloaderGetDownloadList().then((x: any) => {
			this.lastModels = x;
			this.lastModels.forEach(element => element.status = false);
		});
	}

	onFileNameBlur() {
		if (this.model.fileName)
			this.model.fileName = this.model.fileName.replace(':', '.').trim();
	}

	onLinkBlur() {
		if (!this.model.link)
			return;

		this.model.link = this.model.link.replace('m.cda.pl', 'cda.pl')

		if (this.model.link.indexOf(' ') > -1)
			this.settings.isDownloadMulti = true;
		else
			this.settings.isDownloadMulti = false;

		let res = this.model.link.toUpperCase().match(/S\d+E\d+/g);
		if (res && res.length > 0) {
			res.sort();
			this.model.fileName = '';
			if (res.length > 1)
				res.forEach(x => this.model.fileName += x + ' ');
			else
				this.model.fileName = res[0];

			let shouldClear = this.model.saveLocation != SaveLocationEnum.Series
			this.model.saveLocation = SaveLocationEnum.Series;

			this.getFolders(shouldClear).then(() =>
				this.extractNameFromRapideo());
		}

		if (!res && !this.settings.isDownloadMulti && this.model.link.toLowerCase().indexOf('rapideo') > -1) {
			this.model.fileName = this.model.link.split('/').pop().replaceAll('.', ' ');

			try {
				var s = this.model.fileName.split('PL');
				if (s.length > 0) {
					this.model.fileName = s[0].trim();
					var year = this.model.fileName.split(' ').pop();
					this.model.fileName = this.model.fileName.replace(year, '').trim() + ` (${year})`;
				}
			} catch (ex) { }
		}
	}

	getFileNameFromLink(link: string) {
		let regexp = new RegExp('S\\d+E\\d+');
		let res = regexp.exec(link.toUpperCase());
		if (res && res.length > 0) {
			return res[0];
		} else
			return null;
	}

	extractNameFromRapideo() {
		if (this.model.link.indexOf('rapideo') > 0) {
			var arraySplit = this.model.link.split('/');

			var array = arraySplit[arraySplit.length - 1].split('.');
			let index = array.indexOf(this.model.fileName);
			if (index > 0 && !this.model.folderName) {
				var arr = filter(this.folders, x => x.toLowerCase().indexOf(array[index - 1].toLowerCase()) > -1);
				if (arr.length > 0) {
					this.model.folderName = arr[0];
					this.folderControl.setValue(arr[0]);
				}
			}
		}
	}

	checkButtonDisbaled() {
		if (this.model.link)
			return false;
		else
			return true;
	}

	refreshLibrary() {
		this.api.apiPlexScanLibraries().then(() => {
			super.displaySnack('Rozpoczęto skanowanie..');
		});
	}

	openRapideo() {
		this.utilsService.openUrl('https://www.rapideo.net/twoje_pliki')
	}

	save() {
		this.model.saveLocation = Number(this.model.saveLocation);
		if (this.model.fileName)
			this.model.fileName = this.model.fileName.replace(':', '.').trim();
		if (this.model.folderName)
			this.model.folderName = this.model.folderName.replace(':', '.').trim();

		if (!this.getVisibleQualitySection())
			this.model.quality = null;

		if (this.settings.isDownloadMulti && this.model.saveLocation == SaveLocationEnum.Series)
			this.downloadMultiLogic();
		else
			this.downloadLogic();
	}

	downloadLogic() {
		if (this.settings.isDownloadAsync) {
			this.api.apiDownloaderDownloadAsynch(this.model as any).then(x => {
				console.log("DownloadAsync", x);
			}, error => {
				console.error(error);
			});
		} else {
			this.api.apiDownloaderDownload(this.model as any).then(x => {
				console.log("Download", x);
			}, error => {
				console.error(error);
			});
		}

		this.lastModels.unshift(this.model);
		if (this.model.saveLocation == SaveLocationEnum.Series) {
			this.model = clone(this.model);
			this.model.guid = GuidModel.create().toString();
			this.seriesLogic();
		} else {
			let oldModel = clone(this.model);
			this.model = new DownloadModel();
			this.model.saveLocation = oldModel.saveLocation;
			this.folderControl.setValue('');
			this.getFolders();
		}
	}

	downloadMultiLogic() {
		let array = this.model.link.split(' ');
		array.forEach(element => {
			this.model.link = element;
			this.model.fileName = this.getFileNameFromLink(this.model.link);
			console.log('Pobieram plik: ', this.model);
			this.downloadLogic();
		});
	}

	seriesLogic() {
		this.model.link = '';
		let valStr = this.model.fileName.substring(this.model.fileName.length - 2, this.model.fileName.length);
		let valNum = Number(valStr);
		if (!Number.isNaN(valNum)) {
			valNum++;
			if (valNum < 10)
				valStr = `0${valNum}`;
			else
				valStr = valNum.toString();

			this.model.fileName = `${this.model.fileName.substring(0, this.model.fileName.length - 2)}${valStr}`;
		} else
			this.model.fileName = '';
	}

	public delete(model: DownloadModel) {
		this.api.apiDownloaderRemoveDownloadElement(model.guid).then(x => {
			this.lastModels = this.lastModels.filter(x => x.guid !== model.guid);
		});
	}

	public clear() {
		this.model = new DownloadModel();
		this.folderControl.setValue('');
		this.getFolders(true);
	}

	public clearDownloadList() {
		this.api.apiDownloaderClearDownloadList().then(x => this.lastModels = []);
	}

	public reTry(model: DownloadModel) {
		this.model = cloneDeep(model);
		this.model.status = false;
		this.model.percetage = 'Inicjalizacja...';
		this.model.counter = 0;

		this.getFolders().then(() => {
			this.model.folderName = model.folderName;
			this.folderControl.setValue(model.folderName);
		});
	}

	public showDetails(el: DownloadModel) {
		this.subscriptionInterval = interval(1000).subscribe(z => {
			this.api.apiDownloaderGetFileStatus(el.guid).then(x => {
				this.logs = x;
				setTimeout(() => {
					this.content.nativeElement.scrollTop = this.content.nativeElement.scrollHeight + 200;
				}, 100);
			}
			);
		});
	}

	public onChange(value: boolean, name: string) {
		this.settings[name] = !value;
		sessionStorage.setItem(name, String(!value));
	}

	public getFromLocalStorage() {
		this.setLocalStorageValue('isDownloadMulti');
	}

	public setLocalStorageValue(value: string) {
		let valueS = localStorage.getItem(value);
		if (valueS)
			this.settings[value] = JSON.parse(valueS);
	}

	ngOnDestroy() {
		this.subscription?.unsubscribe();
		super.ngOnDestroy();
	}
}
