import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../core/base/base/base.component';
import { forEach } from 'lodash-es';

@Component({
  selector: 'app-domoticz',
  templateUrl: './domoticz.component.html',
  styleUrls: ['./domoticz.component.scss']
})
export class DomoticzComponent extends BaseComponent implements OnInit {

  public data = new Map<number, string>();

  ngOnInit() {
    this.get();
  }

  get() {
    let prmise = this.api.apiDomoticzGetDevicesVariables([233, 234, 17,248,52,85, 127, 126]);
    prmise.then(x => {
      forEach(x, z => {
        this.data[Number(z.idx)] = z;
      });
    });
  }
}
