<div class="center mt-10">
  <h2>{{vm.result}}</h2>
</div>

<hr />

<div style="margin:10px">
  <b>Puls</b>
  <p>Pierwsze efekty zobaczysz już po 1 godzinie. Twoje tętno spadnie i wróci do normy.</p>

  <div class="progress">
    <div class="bar-1 progress-bar progress-bar-striped progress-bar-animated progress-bar-animated" role="progressbar"
      aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
  </div>

  <hr />

  <b>Poziom Tlenu</b>
  <p>Po 12 godzinach poziom tlenu wróci do normy, a poziom tlenku węgla we krwi zmniejszy sie o połowę.</p>

  <div class="progress">
    <div class="bar-2  progress-bar progress-bar-striped progress-bar-animated progress-bar-animated" role="progressbar"
      aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
  </div>

  <hr />

  <b>Poziom Tlenku Węgla</b>
  <p>Po 1 dniu Twoje ciało wypłuka z siebie cały tlenek węgla.</p>

  <div class="progress">
    <div class="bar-3  progress-bar progress-bar-striped progress-bar-animated progress-bar-animated" role="progressbar"
      aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
  </div>

  <hr />

  <b>Zapach i Smak</b>
  <p>Po 2 dniach poprawi się Twój zmysł smaku i zapachu</p>

  <div class="progress">
    <div class="bar-4  progress-bar progress-bar-striped progress-bar-animated progress-bar-animated" role="progressbar"
      aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
  </div>

  <hr />

  <b>Łatwiejsze oddychanie</b>
  <p>Po 3 dniach oddychanie staje się łatwiejsze. Twoje płuca zaczynają się regenerować, a poziom energii rośnie.</p>

  <div class="progress">
    <div class="bar-5  progress-bar progress-bar-striped progress-bar-animated progress-bar-animated" role="progressbar"
      aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
  </div>

  <hr />

  <b>Błona śluzowa żołądka</b>
  <p>Po czterech dniach zaczyna się odbudowywać błona śluzowa żołądka. Niewielu palaczy wie, że papierosy zwiększają
    wydzielanie soku żołądkowego i podrażniają błonę śluzową żołądka.</p>

  <div class="progress">
    <div class="bar-51  progress-bar progress-bar-striped progress-bar-animated progress-bar-animated"
      role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
  </div>

  <hr />

  <b>Nieświeży oddech</b>
  <p>Po 7 dniach nieświeży oddech związany z apleniem powinien zniknąć.</p>

  <div class="progress">
    <div class="bar-6  progress-bar progress-bar-striped progress-bar-animated progress-bar-animated" role="progressbar"
      aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
  </div>

  <hr />

  <b>Krążenie</b>
  <p>W ciągu dwóch tygodni możesz zacząć zauważać, że chodzi Ci się łatwiej. Dzieje się tak dzięki poprawie krążenia i
    dotlenienieniu.</p>

  <div class="progress">
    <div class="bar-7  progress-bar progress-bar-striped progress-bar-animated progress-bar-animated" role="progressbar"
      aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
  </div>

  <hr />

  <b>Skóra</b>
  <p> Po miesiącu widoczne są efekty oczyszczania cery po rzuceniu palenia - odnawia się naskórek, skóra jest lepiej
    nawilżona, a naczynia krwionośne i małe naczynia włosowate lepiej napięte i bardziej elastyczne.</p>

  <div class="progress">
    <div class="bar-75  progress-bar progress-bar-striped progress-bar-animated progress-bar-animated"
      role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
  </div>

  <hr />

  <b>Nasienie</b>
  <p> Po 2 miesiącach gotowe są nowe plemniki.</p>

  <div class="progress">
    <div class="bar-77  progress-bar progress-bar-striped progress-bar-animated progress-bar-animated"
      role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
  </div>

  <hr />


  <b>Kaszel i Świszczący oddech</b>
  <p>Po 3 miesiącach problemy kaszlu, świszczącego oddechu i problemy z oddychaniem zaczynają znikać wraz ze wzrostem
    czynności płuc nawet o 10%.</p>
  <p>kaszel palacza zanika, znacznej poprawie ulega ciśnienie krwi oraz kondycja układu krążenia.</p>

  <div class="progress">
    <div class="bar-8 progress-bar progress-bar-striped progress-bar-animated progress-bar-animated" role="progressbar"
      aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
  </div>


</div>