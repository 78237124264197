import { Component, OnDestroy } from "@angular/core";

import { Subscription, interval } from 'rxjs';
import { BaseComponent } from "../../core/base/base/base.component";
import { environment } from "../../environments/environment";
import { UtilsDate } from "../../utils/utils-date";


@Component({
  selector: 'app-timers',
  templateUrl: './timers.component.html',
  styleUrls: ['./timers.component.scss']
})
export class TimersComponent extends BaseComponent implements OnDestroy {

  public isShow = false;
  private s: Subscription;

  public fDateString = "202402211200";
  public mDateString = "202402201410";

  public f: any = UtilsDate.new(this.fDateString, "YYYYMMDDHHmm");
  public m: any = UtilsDate.new(this.mDateString, "YYYYMMDDHHmm");
  public a: any = UtilsDate.new("202402201410", "YYYYMMDDHHmm");

  public fResult: string;
  public mResult: string;
  public aResult: string;

  public actualDate: any = UtilsDate.new()

  constructor() {
    super();

    if (environment.name != 'Local')
      return;

    this.isShow = true;
    // this.utilsService.openLogin(x => {
    //   if (x) {
    //     this.api.apiUserSignIn(x).then(r => {
    //       if (r.login == "Dawid" || this.userName == 'Admin')
    //         this.isShow = this.userName == 'Dawid' || this.userName == 'Admin';
    //     })
    //   }
    //});

    this.s = interval(1000).subscribe(x => {
      this.actualDate = UtilsDate.new();

      this.fResult = this.setDate(this.f);
      this.mResult = this.setDate(this.m);
      this.aResult = this.setDate(this.a);
    });
  }

  setDate(val: any) {
    let result = '';
    const diffDays = this.actualDate.diff(val, 'days');
    const diffHours = this.actualDate.diff(val, 'hours');
    const diffMinutes = this.actualDate.diff(val, 'minutes');
    const diffSeconds = this.actualDate.diff(val, 'seconds');
    result = `${diffDays} dni ${diffHours % 24} godzin ${diffMinutes % 60} minut ${diffSeconds % 60} sekund`;
    return result;
  }

  ngOnDestroy(): void {
    this.s?.unsubscribe();
    super.ngOnDestroy();
  }
}
