import dayjs from "dayjs";

export class UtilsDate {

  static new(date = undefined, format = undefined): dayjs.Dayjs {
    if (date === undefined) {
        return dayjs();
    }
    if (format) {
        return dayjs(date, format);
    } else {
        return dayjs(date);
    }
}

    public static getDayofWeek(name: string) {
        if (name === "Monday")
            return "Poniedzialek";
        else if (name === "Tuesday")
            return "Wtorek";
        else if (name === "Wednesday")
            return "Sroda";
        else if (name === "Thursday")
            return "Czwartek";
        else if (name === "Friday")
            return "Piatek";
        else if (name === "Saturday")
            return "Sobota";
        else if (name === "Sunday")
            return "Niedziela"
        else
            return name;
    }

    public static getMonth(name: string) {
        if (name === "January")
            return "Styczeń";
        else if (name === "February")
            return "Luty";
        else if (name === "March")
            return "Marzec";
        else if (name === "April")
            return "Kwiecień";
        else if (name === "May")
            return "Maj";
        else if (name === "June")
            return "Czerwiec";
        else if (name === "July")
            return "Lipiec"
        else if (name === "August")
            return "Sierpień"
        else if (name === "September")
            return "Wrzesień"
        else if (name === "October")
            return "Październik"
        else if (name === "November")
            return "Listopad"
        else if (name === "December")
            return "Grudzień"
        else
            return name;
    }
}
