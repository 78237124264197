import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SettingsEntity } from '../../generated/DomoticzApiClient';
import { BaseComponent } from '../../core/base/base/base.component';
import { filter } from 'lodash-es';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent extends BaseComponent implements OnInit {

  public model: SettingsEntity[];

  ngOnInit() {
    super.setTitle("Ustawienia - Dev IT")
    this.api.apiSettingsLiteDbGetAll().then(res => {
      this.model = filter(res, x => x.valueType == 'Boolean');
    })
  }

  onChange(element: any, $event: MatCheckboxChange) {
    element.value = element.value === "0" ? "1" : "0";
    this.api.apiSettingsLiteDbUpdate(element).then(() =>
      this.displaySnack(`Pomyślnie zaktualizowano wartość na ${element.value}.`), err => {
        console.error(err);
        this.displaySnack(`Nie udało się zaktualizowanać wartości.`)
      });
  }
}
