import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleEnum } from "../core/base/enums/role.enum";
import { BabyComponent } from './baby/baby.component';
import { BibleComponent } from "./bible/bible.component";
import { BusStopComponent } from './bus-stop/bus-stop.component';
import { CalendarComponent } from "./calendar/calendar.component";
import { CigarComponent } from './cigar/cigar.component';
import { DownloaderComponent } from './downloader/downloader.component';
import { FlatComponent } from './flat/flat.component';
import { FrameParentsComponent } from './frame/frame-parents/frame-parents.component';
import { FrameComponent } from './frame/frame.component';
import { HomeComponent } from './home/home.component';
import { ImportantNewComponent } from './important-new/important-new.component';
import { NotesDetailsComponent } from './notes/notes-details/notes-details.component';
import { NotesComponent } from './notes/notes.component';
import { NotificationComponent } from './notification/notification.component';
import { OnedriveComponent } from "./onedrive/onedrive.component";
import { OpenAiComponent } from "./open-ai/open-ai.component";
import { SeedComponent } from './seed/seed.component';
import { AuthGuardService as AuthGuard } from "../services/auth-guard.service";
import { SettingsComponent } from './settings/settings.component';
import { ShopListComponent } from './shop-list/shop-list.component';
import { SmogComponent } from "./smog/smog.component";
import { TheMovieDbComponent } from "./the-movie-db/the-movie-db.component";
import { TimersComponent } from './timers/timers.component';
import { VacationComponent } from './vacation/vacation.component';
import { WeddingComponent } from './wedding/wedding.component';
import { WorkTimeComponent } from './work-time/work-time.component';
import { ReceipcsComponent } from './receipcs/receipcs.component';
import { YesNoComponent } from './yes-no/yes-no.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './privacy-policy/terms-conditions/terms-conditions.component';
import { DomoticzComponent } from './domoticz/domoticz.component';
import { Baby2Component } from './baby2/baby2.component';
import { HealthComponent } from './health/health.component';


const routes: Routes = [

	{ path: 'cigar/:date', component: CigarComponent, canActivate: [AuthGuard], data: { users: [RoleEnum.Dawid] } },
	{ path: 'seed/:date', component: SeedComponent, canActivate: [AuthGuard], data: { users: [RoleEnum.Dawid] } },
	{ path: 'timers', component: TimersComponent, canActivate: [AuthGuard], data: { users: [RoleEnum.Dawid] } },

	{ path: 'baby', component: BabyComponent, canActivate: [AuthGuard], data: { users: [RoleEnum.Dawid, RoleEnum.Madzia] } },
	{ path: 'baby2', component: Baby2Component, canActivate: [AuthGuard], data: { users: [RoleEnum.Dawid, RoleEnum.Madzia] } },
	{ path: 'important-new', component: ImportantNewComponent, canActivate: [AuthGuard] },
	{ path: 'downloader', component: DownloaderComponent, canActivate: [AuthGuard] },
	{ path: 'downloader/get', component: DownloaderComponent, canActivate: [AuthGuard] },

	{ path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
	{ path: 'notification', component: NotificationComponent, canActivate: [AuthGuard] },

	{ path: 'notes', component: NotesComponent, canActivate: [AuthGuard] },
	{ path: 'notes-details/:id', component: NotesDetailsComponent, canActivate: [AuthGuard], data: { users: [RoleEnum.Dawid, RoleEnum.Madzia] } },
	{ path: 'notes-details/:id/:hide', component: NotesDetailsComponent, canActivate: [AuthGuard], data: { users: [RoleEnum.Dawid, RoleEnum.Madzia] } },

	{ path: 'nh-vacation', component: VacationComponent, canActivate: [AuthGuard] },
	{ path: 'nh-frame', component: FrameComponent, canActivate: [AuthGuard] },
	{ path: 'nh-frameParents', component: FrameParentsComponent, canActivate: [AuthGuard] },
	{ path: 'nh-frame/:id', component: FrameComponent, canActivate: [AuthGuard] },
	{ path: 'nh-busstop', component: BusStopComponent, canActivate: [AuthGuard] },
	{ path: 'busstop', component: BusStopComponent, canActivate: [AuthGuard] },

	{ path: 'flat', component: FlatComponent, canActivate: [AuthGuard] },

	{ path: 'receipcs', component: ReceipcsComponent },
	{ path: 'receipcs/:datatarget', component: ReceipcsComponent },

	{ path: 'work-time', component: WorkTimeComponent, canActivate: [AuthGuard] },
	{ path: 'shop-list', component: ShopListComponent, canActivate: [AuthGuard] },
	{ path: 'smog', component: SmogComponent, canActivate: [AuthGuard] },
	{ path: 'calendar', component: CalendarComponent, canActivate: [AuthGuard] },
	{ path: 'onedrive', component: OnedriveComponent, canActivate: [AuthGuard] },

	{ path: 'nh-wedding', component: WeddingComponent },
	{ path: 'open-ai', component: OpenAiComponent, canActivate: [AuthGuard] },
	{ path: 'domoticz', component: DomoticzComponent, canActivate: [AuthGuard] },
	{ path: 'redirect', component: HomeComponent },
	{ path: 'home', component: HomeComponent },
	{ path: 'bible', component: BibleComponent },
	{ path: 'moviedb', component: TheMovieDbComponent },
	{ path: 'yes-no', component: YesNoComponent },
	{ path: 'health', component: HealthComponent },


	{ path: 'privacy-policy', component: PrivacyPolicyComponent },
	{ path: 'terms-conditions', component: TermsConditionsComponent },

	{ path: '', component: HomeComponent }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'top' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
