import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { tap } from "rxjs/operators";

@Component({
  selector: 'app-yes-no',
  templateUrl: './yes-no.component.html',
  styleUrls: ['./yes-no.component.css']
})
export class YesNoComponent implements OnInit {

  public response: any;

  constructor(private hc: HttpClient) { }

  ngOnInit() {
    this.random();
  }

  random() {
    this.hc.get('https://yesno.wtf/api').subscribe(x => {
      this.response = x;
    });
  }
}
