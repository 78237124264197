import { Input } from "@angular/core";
import { Subscription } from "rxjs/internal/Subscription";

export class BusStopPanelVM {

    public hide: boolean = false;
    public lastUpdate: Date;
    public res: any;

    public s: Subscription;
}
