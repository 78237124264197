import { Component } from '@angular/core';
import { BaseComponent } from '../../core/base/base/base.component';

@Component({
  selector: 'app-vacation',
  templateUrl: './vacation.component.html',
  styleUrls: ['./vacation.component.scss']
})
export class VacationComponent extends BaseComponent {

  public isShow: boolean = false;

  constructor() {
    super();
    super.setTitle(`Wakacje - Dev IT`);
  }

  outputEvent() {
    this.isShow = true;
  }
}
