<div class="form">

  <app-header-for-module text="Zdrowie" src="/assets/icons/health/health.png"></app-header-for-module>

  <div class="center">

    <mat-card *ngFor="let el of history" style="margin-bottom: 20px;">
      <mat-card-header>
        <b class="grey">{{el.date | date:'dd/MM/yyyy HH:mm'}}</b>
      </mat-card-header>
      <mat-card-content>

        <div class="row">
          <div class="col">
            <b>{{el.value}}</b> <br /> {{el.pulse}}
          </div>
          <div style="font-size: larger; cursor: pointer;" class="col-2 red-and-hover" (click)="remove(el.id)">
            <i class="fas fa-times ml-10"></i>
          </div>
        </div>

      </mat-card-content>
    </mat-card>
  </div>


  <mat-form-field style.fontSize="16px" class="example-full-width">
    <mat-label>Ciśnienie</mat-label>
    <textarea matInput cdkTextareaAutosize (keyup)="value" [(ngModel)]="value" #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
  </mat-form-field>

  <mat-form-field style.fontSize="16px" class="example-full-width">
    <mat-label>Puls</mat-label>
    <textarea matInput cdkTextareaAutosize (keyup)="pulse" [(ngModel)]="pulse" #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
  </mat-form-field>


  <div class="row">
    <button class="col-sm lavender-black-btn" [disabled]="!value && !pulse" (click)="save()" mat-raised-button
      color="warn">Zapisz</button>

  </div>

</div>