import { Component } from '@angular/core';
import { BaseComponent } from '../../core/base/base/base.component';
import { HealthEntity } from '../../generated/DomoticzApiClient';

@Component({
	selector: 'app-health',
	templateUrl: './health.component.html',
	styleUrl: './health.component.scss'
})
export class HealthComponent extends BaseComponent {

	public history: any;
	public value: string;
	public pulse: number;

	constructor() {
		super();

		let userName = this.userName;
		if (userName == 'Admin')
			userName = 'Dawid';

		this.api.apiHealthGetRecords(userName).then(x => {
			this.history = x;
		});
	}

	remove(id: number) {
		this.utilsService.openConfirm(`Czy na pewno usunąć rekord   ?`, x => {
			if (x) {
				this.api.apiHealthRemoveRecord(id).then(z => {
					this.api.apiHealthGetRecords(this.userName).then(x => {
						this.history = x;
					});
				});
			}
		});
	}


	save() {
		let model = <HealthEntity>{
			pulse: this.pulse,
			user: this.userName,
			value: this.value
		};

		this.api.apiHealthInsertRecord(model).then(z => {
			this.api.apiHealthGetRecords(this.userName).then(x => {
				this.history = x;
				this.pulse = null;
				this.value = null;
			});
		})
	}
}
