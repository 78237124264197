import { Component, OnDestroy } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { Product } from "./models/product.model";
import { BaseComponent } from '../../core/base/base/base.component';
import { ShopListModel, DictionaryEntity } from '../../generated/DomoticzApiClient';
import { orderBy } from 'lodash-es';

@Component({
	selector: 'app-shop-list',
	templateUrl: './shop-list.component.html',
	styleUrls: ['./shop-list.component.scss']
})
export class ShopListComponent extends BaseComponent implements OnDestroy {

	public settings: any = {
		isShowTryBuy: true,
		isBought: true,
		isBellNotification: true,
		expandTryBuy: false,
		expandBought: true
	};


	public newProduct: string;

	public products: Array<any> = new Array<any>();
	public productsTryBuy: Array<any> = new Array<any>();
	public productsOld: Array<any> = new Array<any>();

	public canAdd: boolean = true;
	public isError: boolean = false;
	public isSomethingInEditMode: boolean = false;
	public isGetFormLocalstorage: boolean = true;
	public isDownloadedData: boolean = false;
	public isLocalStorageData: boolean = false;
	public model: ShopListModel;

	private s: Subscription;

	constructor(
		private notificationService: NotificationService) {
		super();

		this.init();
	}

	init(): void {
		this.getSettigns();
		setTimeout(() => {
			this.getAllModel();
		}, 0);

		this.s = interval(10000).subscribe(() => {
			if (!this.isSomethingInEditMode)
				this.getAllModel();
		});
	}

	getSettigns() {
		this.getFromLocalStorage();
	}

	getAllModel() {
		try {
			this.api.apiShopListGetAll(undefined).then(x => {
				this.isLocalStorageData = false;
				this.isDownloadedData = true;
				this.isError = false;
				this.products = x.all;
				this.productsOld = orderBy(x.deleted, x => x.id, 'desc');
				this.productsTryBuy = orderBy(x.tryBuy, x => x.id, 'desc');
				this.saveDataInLocalStorage(x)
			}, err => {
				this.getDataFromlocalStorage();
			}).catch(err => {
				this.getDataFromlocalStorage();
			});
		} catch (e) {
			this.getDataFromlocalStorage();
		}
	}

	public async saveDataInLocalStorage(model: ShopListModel) {
		try {
			let json = JSON.stringify(model);
			localStorage.setItem('ShopListData', json);
			this.androidBridge.emit('ShopListData', json);
		} catch (e) {
			console.error("Nie udało się zserializować danych");
		}
	}

	public async getDataFromlocalStorage() {
		try {
			console.log("Pobrano dane z LocalStorage");
			let x = JSON.parse(localStorage.getItem('ShopListData'));
			this.products = x.all;
			this.productsOld = orderBy(x.deleted, x => x.id, 'desc');
			this.productsTryBuy = orderBy(x.tryBuy, x => x.id, 'desc');
			this.isDownloadedData = true;
			this.isLocalStorageData = true;
		} catch (e) {
			this.isDownloadedData = true;
			this.isError = true;
		}
	}

	public add() {
		if (!this.newProduct)
			return;

		this.canAdd = false;
		let model = new Product(this.newProduct, this.getContext('ShopList'), 'ShopList');
		this.api.apiDictionaryLiteDbAdd(new DictionaryEntity(model)).then(x => {
			this.getAll();
			this.newProduct = null;
			this.canAdd = true;
		});
	}

	public onChange(value: boolean, name: string) {
		this.settings[name] = !value;
		localStorage.setItem(name, String(!value));
	}

	public openEdit(product: any) {
		if (!product.editMode) {
			product.editMode = true;
			product.tmpValue = product.value;
			this.isSomethingInEditMode = true;
		} else {
			product.editMode = false;
			this.isSomethingInEditMode = false;
		}
	}

	public getContext(context: string) {
		if (this.userName == 'Lukasz' || this.userName == 'Wiola')
			return context += 'LukaszCon';
		else
			return context;
	}

	public updateEdit(product: any) {
		product.value = product.tmpValue;
		product.editMode = false;
		this.update(product);
	}

	public cancelEdit(product: any) {
		product.editMode = false;
	}

	public moveToTryBuy(product: any) {
		this.api.apiShopListRemove(product.id).then(x => {
			this.getDeleted();
			let model = { value: product.value, context: this.getContext('ShopListTryBuy'), key: 'ShopList' };
			this.api.apiShopListAdd(new DictionaryEntity(model)).then(() => this.getTryBuy());
		});
	}

	public update(model: any) {
		this.api.apiShopListUpdate(new DictionaryEntity(model)).then(x => console.log(x));
	}

	public remove(product: any) {
		this.api.apiShopListRemove(product.id).then(() => {
			this.getAll();
			let model = { value: product.value, context: this.getContext('ShopListDeleted'), key: 'ShopList' };
			this.api.apiShopListAdd(new DictionaryEntity(model)).then(() => this.getDeleted());
		})
	}

	public backProduct(product: any) {
		this.newProduct = product.value;
		this.add();
		this.api.apiShopListRemove(product.id).then(x => {
			this.getDeleted();
			this.getTryBuy();
		});
	}

	public removeOld() {
		this.productsOld = [];
	}

	sendNotificationDblClick() {
		if (this.userName == 'Dawid')
			this.sendNotification('Madzia');
		else if (this.userName == 'Madzia')
			this.sendNotification('Dawid');
	}

	public sendNotification(userName: string) {
		this.utilsService.openConfirm(`Na pewno chcesz wysłać powiadomienie do ${userName}`, x => {
			if (x) {
				if (userName == "Dawid")
					this.notificationService.sendFirebaseNotificationInline(userName, "Żonka przypomina o liście zakupów 🛒", "Lista zakupów", 'shop-list').then(() => this.utilsService.openAlert("Wysłano powiadomienie do Męża"));
				else if (userName == "Madzia")
					this.notificationService.sendFirebaseNotificationInline(userName, "Mężuś przypomina o liście zakupów 🛒", "Lista zakupów", 'shop-list').then(() => this.utilsService.openAlert("Wysłano powiadomienie do Żony"));
			}
		});
	}

	public getAll() {
		return this.api.apiShopListGet('').then(x => {
			this.isError = false;
			this.products = x;
		}, err => {
			this.init();
		});
	}

	public copyDatabase() {
		// this.api.apiBackupCopyLiteDatabase().then(x => {
		// 	console.log('copyLitedb', x);
		// });
	}

	public getDeleted() {
		this.api.apiDictionaryLiteDbGetByContext(this.getContext('ShopListDeleted')).then(x => {
			this.productsOld = orderBy(x, x => x.id, 'desc');
		});
	}

	public getTryBuy() {
		this.api.apiDictionaryLiteDbGetByContext(this.getContext('ShopListTryBuy')).then(x => {
			this.productsTryBuy = orderBy(x, x => x.id, 'desc');
		});
	}

	public clearHistory() {
		this.productsOld = [];
		this.api.apiDictionaryLiteDbRemoveByContext(this.getContext('ShopListDeleted')).then(x => console.log(x));
	}

	public removeFromHistory(product: any) {
		this.api.apiDictionaryLiteDbRemoveByid(product.id).then(x => {
			this.getDeleted();
			this.getTryBuy();

			this.displaySnack(`Usunięto ${product.value.toUpperCase()}`);
		});
	}

	public getFromLocalStorage() {
		this.setLocalStorageValue('isShowTryBuy');
		this.setLocalStorageValue('isBought');
		this.setLocalStorageValue('isBellNotification');
		this.setLocalStorageValue('expandTryBuy');
		this.setLocalStorageValue('expandBought');
	}

	public setLocalStorageValue(value: string) {
		let valueS = localStorage.getItem(value);
		if (valueS)
			this.settings[value] = JSON.parse(valueS);
	}

	public trackByProduct(index: number, model: any) {
		return model.id;
	}

	override ngOnDestroy(): void {
		this.s?.unsubscribe();
		super.ngOnDestroy();
	}
}
