import { Component, OnInit } from '@angular/core';

import { NotificationService } from '../../services/notification.service';
import { Baby2VM } from './models/baby2VM';
import { HttpClient } from '@angular/common/http';
import { BaseComponent } from '../../core/base/base/base.component';
import { UtilsDate } from '../../utils/utils-date';
import { filter } from 'lodash-es';

@Component({
  selector: 'app-baby2',
  templateUrl: './baby2.component.html',
  styleUrls: ['./baby2.component.scss']
})
export class Baby2Component extends BaseComponent implements OnInit {

  public vm: Baby2VM = new Baby2VM();

  constructor(
    private ns: NotificationService,
    private httpClient: HttpClient) {
    super();
    super.setTitle("Marcelek - Dev IT")
  }

  ngOnInit() {
    if (this.isMadzia())
      this.ns.sendFirebaseNotificationInline("Dawid", `Sprawdzane new junior info przez ${this.userName} 👶`);

    this.setProgressValue(24 * 280, ".bar-4");

    this.getBabyWeightList();
  }

  public getBabyWeightList() {
    this.httpClient.get('/assets/baby/weight/babyplus_data_export.json').toPromise().then((x: any) => {
      this.vm.weightList = filter(x.baby_growth, x => x.weight);
    });
  }

  public openImage(url: string) {
    let i = this.vm.imageObject.findIndex(x => (x as any).image == url);
    this.vm.imageIndex = i;
    this.vm.showImageFlag = true;
  }

  public async openAudioBook() {
    let url = await this.api.apiDevItRandomAudioBook();
    this.utilsService.openUrl(url.path);
  }

  public closeImage() {
    this.vm.imageIndex = -1;
    this.vm.showImageFlag = false;
  }

  public randomImage() {
    this.vm.iconIndex = this.vm.iconIndex % 4 + 1;
  }

  public setProgressValue(hour: number, css: string) {
    let start = this.vm.firstDay as any;
    let end = UtilsDate.new(this.vm.firstDay).add(hour, 'hours') as any;
    let today = this.vm.actualDate as any;
    let p = Math.round((today - start) / (end - start) * 100) - 1;
    if (p > 100) {
      p = 100;
      if (!$(css).hasClass('bg-success')) {
        // /$(css).addClass('bg-success');
        $(css).removeClass('bg-danger');
      }
    }

    $(css).css('width', p + '%').attr('aria-valuenow', p);
    $(css).text(p.toString() + "%");
  }
}
