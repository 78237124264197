<div class="form">

  <app-header-for-module text="Notatki - Kategorie" src="/assets/icons/notes/notes.png"></app-header-for-module>

  <div class="row" *ngIf="!isOffline">
    <div class="col-10 col-sm-11">
      <mat-form-field style.fontSize="16px" class="example-full-width">
        <mat-label>Nowy</mat-label>
        <input matInput [(ngModel)]="newCategory" (keyup.enter)="add()" />
      </mat-form-field>
    </div>

    <div class="col-2 col-sm-1 x-large cursor-pointer mt-3">
      <span *ngIf="canAdd" (click)="add()">
        <i class="fas fa-plus green-and-hover"></i>
      </span>
    </div>
  </div>


  <br /> <br />

  <mat-card class="mb-2" *ngFor="let c of categories; trackBy: trackByIndex">
    <mat-card-content>
      <div class="row item">
        <div class="col" (click)="go(c)">
          {{c.value | uppercase}}
        </div>
        <div class="col-1 me-3" (click)="remove(c)">
          <i class="fas fa-times ml-10 red-and-hover"></i>
        </div>
      </div>
    </mat-card-content>
  </mat-card>


  <mat-card *ngIf="categories?.length == 0" class="center">
    <mat-card-content>
      Lista kategorii jest pusta 🥰
    </mat-card-content>
  </mat-card>

</div>