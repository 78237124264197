import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';

import { Configuration, ConfigurationParameters, CreateCompletionRequest, CreateImageRequest, OpenAIApi } from "openai";
import { LinkService } from '../../services/link.service';
import { NotificationService } from "../../services/notification.service";
import { OpenAiChat } from "./models/open-ai-chat";
import { BaseComponent } from '../../core/base/base/base.component';
import { UtilsUser } from '../../utils/utils-user';
import { UtilsString } from '../../utils/utils-string';
import { UtilsDate } from '../../utils/utils-date';

@Component({
	selector: 'app-open-ai',
	templateUrl: './open-ai.component.html',
	styleUrls: ['./open-ai.component.scss']
})
export class OpenAiComponent extends BaseComponent {

	public history: Array<OpenAiChat> = new Array<OpenAiChat>();
	public openApi: OpenAIApi;
	public question: string;
	public disabledButton: boolean;

	public showFlag: boolean = false;
	public selectedImageIndex: number = -1;
	public currentIndex: number = -1;
	public imageObject: Array<object> = [];

	public showLightbox(message: OpenAiChat, index: number) {
		this.imageObject = message.imageObject;
		this.selectedImageIndex = index;
		this.showFlag = true;
	}

	public closeEventHandler() {
		this.imageObject = [];
		this.selectedImageIndex = -1;
		this.currentIndex = -1;
		this.showFlag = false;
	}

	constructor(
		public httpClient: HttpClient,
		public linkService: LinkService,
		private ns: NotificationService) {
		super();
		super.setTitle("Wielki Browek - Dev IT")

		this.disabledButton = this.isOfflineMode();

		this.route.queryParamMap.subscribe(x => {
			let who = x.get('who');
			if (who == 'tata')
				this.randomParentsPhoto(10);
		});

		if (UtilsUser.userIsNotDavid())
			this.ns.sendFirebaseNotificationInline("Dawid", `Uruchomiony Wielki Browek przez ${this.userName} 😼`);

		let params = <ConfigurationParameters>{ apiKey: "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" };
		let conf = new Configuration(params);
		this.openApi = new OpenAIApi(conf);

		if (!this.isOfflineMode())
			this.history.push(new OpenAiChat("Wielki Browek", "Co tam?", UtilsDate.new().format("HH:mm:ss"), 'text'));
		else
			this.history.push(new OpenAiChat("Wielki Browek", "Nie mam internetu. Działam w trybie offline, nie mogę zbyt wiele dla Ciebie zrobić.", UtilsDate.new().format("HH:mm:ss"), 'text'));
	}

	public send(message: string = null) {

		if (message)
			this.question = message;

		console.log(this.question);

		if (!this.question)
			return;
		else
			this.history.push(new OpenAiChat("Ja", this.question, UtilsDate.new().format("HH:mm:ss"), 'text'));

		let instruction = UtilsString.normalize(this.question.toLowerCase());

		if (instruction.indexOf('generuj obraz') >= 0) {
			this.generateImage(this.question, instruction.split('generuj obraz')[1], 1);
		}
		else if (instruction.match('generuj \\d+ obraz')) {
			let t = instruction.split(' ');
			this.generateImage(this.question, t.slice(3, t.length).join(' '), Number(instruction.match('generuj \\d+ obraz')[0].match('\\d+')[0]));
		}
		else if (instruction.match('losuj zdjecie madzi')) {
			this.randomMadziaPhoto();
		}
		else if (instruction.match('losuj \\d+ zdj[a-zA-Z]* madzi')) {
			this.randomMadziaPhoto(Number(instruction.match('losuj \\d+ zdj[a-zA-Z]* madzi')[0].match('\\d+')[0]));
		}
		else if (instruction.match('losuj zdjecie juniora')) {
			this.randomJuniorPhoto();
		}
		else if (instruction.match('losuj \\d+ zdj[a-zA-Z]* juniora')) {
			this.randomJuniorPhoto(Number(instruction.match('losuj \\d+ zdj[a-zA-Z]* juniora')[0].match('\\d+')[0]));
		}
		else if (instruction.match('losuj zdjecie marcelka')) {
			this.randomJuniorPhoto();
		}
		else if (instruction.match('losuj \\d+ zdj[a-zA-Z]* marcelka')) {
			this.randomJuniorPhoto(Number(instruction.match('losuj \\d+ zdj[a-zA-Z]* marcelka')[0].match('\\d+')[0]));
		}
		else if (instruction.match('lista zakupow')) {
			this.shopListLogic();
		}
		else if (instruction.match('uruchom ponownie')) {
			this.reboot();
		}
		else if (instruction.match('uruchom ponownie retropi')) {
			this.rebootRetroPi();
		}
		else if (instruction.match('biedronka')) {
			this.getBiedronkaCard();
		}
		else if (instruction.match('orlen')) {
			this.getOrlenCard();
		}
		else if (instruction.match('lidl')) {
			this.getLidlCard();
		}
		else if (instruction.match('losuj zdjecie mamy i taty')) {
			this.randomParentsPhoto();
		}
		else if (instruction.match('losuj 5 zdjec mamy i taty')) {
			this.randomParentsPhoto(5);
		}
		else if (instruction.match('losuj 10 zdjec mamy i taty')) {
			this.randomParentsPhoto(10);
		}
		else {
			this.generateResponse(this.question);
		}

		this.question = null;
	}

	public generateResponse(question: string) {

		this.disabledButton = true;

		let request = <CreateCompletionRequest>{
			prompt: "<|endoftext|>" + question + "\n--\nLabel:", model: 'text-davinci-003',
			max_tokens: 1000, user: "1", temperature: 0, logprobs: 10, top_p: 0
		};
		this.openApi.createCompletion(request).then(x => {
			console.log(x);
			this.disabledButton = false;
			x.data.choices.forEach(element => {
				this.history.push(new OpenAiChat("Wielki Browek", element.text, UtilsDate.new().format("HH:mm:ss"), 'text'));
			});
		}, err => {
			this.disabledButton = false;
			this.history.push(new OpenAiChat("Wielki Browek", 'Wystąpił nieoczekiwany błąd.', UtilsDate.new().format("HH:mm:ss"), 'text'));
		});
	}

	public generateImage(question: string, prompt: string, n: number = 1) {
		console.log('generate image ', question, prompt)

		let request = <CreateImageRequest>{ user: "1", n: n, prompt: prompt };
		this.openApi.createImage(request).then(x => {
			console.log(x);
			this.disabledButton = false;
			let res = new OpenAiChat("Wielki Browek", null, UtilsDate.new().format("HH:mm:ss"), 'image-array');
			x.data.data.forEach(element => {
				res.imageObject.push({ 'image': element.url });
			});
			this.history.push(res);
		}, err => {
			console.log(err);
			if (err.response.data.error.message.indexOf('Your request was rejected as a result of our safety system') >= 0) {
				this.history.push(new OpenAiChat("Wielki Browek", 'Twoja prośba została odrzucona ze względu na nasz system bezpieczeństwa.', UtilsDate.new().format("HH:mm:ss"), 'text'));
			} else {
				this.history.push(new OpenAiChat("Wielki Browek", 'Wystąpił nieoczekiwany błąd.', UtilsDate.new().format("HH:mm:ss"), 'text'));
			}
			this.disabledButton = false;
		});
	}

	private reboot() {
		this.api.apiDevPiReboot().then();
		this.history.push(new OpenAiChat("Wielki Browek", "Uruchamiam ponownie DevPi.", UtilsDate.new().format("HH:mm:ss"), 'text'));
	}

	private rebootRetroPi() {
		// this.api.apiRetroPiReboot().then();
		this.history.push(new OpenAiChat("Wielki Browek", "Uruchamiam ponownie RetroPi.", UtilsDate.new().format("HH:mm:ss"), 'text'));
	}

	private randomMadziaPhoto(count = 1) {
		let response = new OpenAiChat("Wielki Browek", "Pracuję, proszę czekać..." + this.getLoaderHtml(), UtilsDate.new().format("HH:mm:ss"), 'image-array');

		for (let i = 0; i < count; i++)
			this.api.apiFrameRandomPhoto(true).then(x => {
				response.message = null;
				response.imageObject.push({ 'image': `${this.linkService.getUrl()}/${x.path}` });
			}, err => {
				this.history.pop();
				this.history.push(new OpenAiChat("Wielki Browek", 'Wystąpił nieoczekiwany błąd.', UtilsDate.new().format("HH:mm:ss"), 'text'));
			});

		this.history.push(response);
	}

	private randomParentsPhoto(count = 1) {
		let response = new OpenAiChat("Wielki Browek", "Pracuję, proszę czekać..." + this.getLoaderHtml(), UtilsDate.new().format("HH:mm:ss"), 'image-array');

		for (let i = 0; i < count; i++)
			this.api.apiFrameRandomPhotoParents(true).then(x => {
				response.message = null;
				response.imageObject.push({ 'image': `${this.linkService.getUrl()}/${x.path}` });
			}, err => {
				this.history.pop();
				this.history.push(new OpenAiChat("Wielki Browek", 'Wystąpił nieoczekiwany błąd.', UtilsDate.new().format("HH:mm:ss"), 'text'));
			});

		this.history.push(response);
	}

	private randomJuniorPhoto(count = 1) {
		let response = new OpenAiChat("Wielki Browek", "Pracuję, proszę czekać..." + this.getLoaderHtml(), UtilsDate.new().format("HH:mm:ss"), 'image-array');

		for (let i = 0; i < count; i++)
			this.api.apiFrameRandomPhotoJunior(true).then(x => {
				response.message = null;
				response.imageObject.push({ 'image': `${this.linkService.getUrl()}/${x.path}` });
			}, err => {
				this.history.pop();
				this.history.push(new OpenAiChat("Wielki Browek", 'Wystąpił nieoczekiwany błąd.', UtilsDate.new().format("HH:mm:ss"), 'text'));
			});

		this.history.push(response);
	}

	private shopListLogic() {
		this.api.apiDictionaryLiteDbGetByContext('ShopList').then(x => {
			let res = '';
			x.forEach((element) => res += element.value + ', ');
			if (x.length === 0) res = 'Lista zakupów jest pusta';

			this.history.push(new OpenAiChat("Wielki Browek", res, UtilsDate.new().format("HH:mm:ss"), 'text'));
		});
	}

	private getOrlenCard() {
		let response = new OpenAiChat("Wielki Browek", 'Podrzucam kartę Orlen Vitay 😺', UtilsDate.new().format("HH:mm:ss"), 'image-array');
		response.imageObject.push({ 'image': 'assets/images/others/orlen/orlen.jpg', 'content': 'Madzia' });
		this.history.push(response);
	}

	private getBiedronkaCard() {
		// https://www.kreseczki.pl/index.php?pn=1
		let response = new OpenAiChat("Wielki Browek", 'Podrzucam listę kart do biedronki 😺', UtilsDate.new().format("HH:mm:ss"), 'image-array');
		response.imageObject.push({ 'image': 'assets/images/others/biedronka/biedronka-dawid.png', 'content': 'Dawid' });
		response.imageObject.push({ 'image': 'assets/images/others/biedronka/biedronka-magda.png', 'content': 'Madzia' });
		response.imageObject.push({ 'image': 'assets/images/others/biedronka/biedronka-jaroslaw.png', 'content': 'Jarosław' });
		response.imageObject.push({ 'image': 'assets/images/others/biedronka/biedronka-dorota.png', 'content': 'Dorota' });
		response.imageObject.push({ 'image': 'assets/images/others/biedronka/biedronka-anna.png', 'content': 'Anna' });
		this.history.push(response);
	}

	private getLidlCard() {
		// https://www.kreseczki.pl/index.php?pn=1
		let response = new OpenAiChat("Wielki Browek", 'Podrzucam listę kart do biedronki 😺', UtilsDate.new().format("HH:mm:ss"), 'image-array');
		response.imageObject.push({ 'image': 'assets/images/others/lidl/lidl-dawid.jpg', 'content': 'Dawid' });
		response.imageObject.push({ 'image': 'assets/images/others/lidl/lidl-magda.jpg', 'content': 'Madzia' });

		this.history.push(response);
	}

	private getLoaderHtml() {
		return `<br/><br/><div class="spinner-border loader"></div>`;
	}
}

