<div class="form">

  <app-header-for-module text="Ustawienia" src="/assets/icons/settings/settings.png"></app-header-for-module>

  <app-progress-spinner [isShow]="!model"></app-progress-spinner>

  <div class="container" *ngFor="let element of model; trackBy: trackByIndex">
    <div class="row justify-content-md-center">
      <div class="col-2 col-sm-2 ">
        {{element.id}}
      </div>
      <div class="col-8 col-sm-8 ">
        {{element.name}}
      </div>
      <div class="col-2 col-sm-2">
        <mat-checkbox [checked]="element.value == 1" (change)="onChange(element, $event)"></mat-checkbox>
      </div>
      <hr class="col-sm-12" />
    </div>
  </div>

  <span class="row">
    <button class="col-sm p-10 green-btn" (click)="back()" mat-raised-button color="warn">Powrót</button>
  </span>
</div>