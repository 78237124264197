import { Component } from '@angular/core';

import { Subscription, interval } from 'rxjs';
import { BaseComponent } from '../../core/base/base/base.component';
import { UtilsDate } from '../../utils/utils-date';

@Component({
  selector: 'app-seed',
  templateUrl: './seed.component.html',
  styleUrls: ['./seed.component.scss']
})
export class SeedComponent extends BaseComponent {

  private s: Subscription;
  public diffSeconds: number = 0;
  public diffMinutes: number = 0;
  public diffHours: number = 0;
  public diffDays: number = 0;
  public firstDay: any = UtilsDate.new("202107200830", "YYYYMMDDHHmm");
  public actualDate: any = UtilsDate.new();
  public result: string = '';

  constructor() {
    super();

    this.route.params.subscribe(x => {
      if (x['date'])
        this.firstDay = UtilsDate.new(x['date'], "YYYYMMDDHHmm");
    });

    this.s = interval(1000).subscribe(x => {
      this.actualDate = UtilsDate.new();
      this.result = this.setDate(this.firstDay);

      this.setProgressValue(24, ".bar-1");
      this.setProgressValue(24 * 2, ".bar-2");
      this.setProgressValue(24 * 5, ".bar-12");
    });
  }

  setDate(val: any) {
    let result = '';
    this.diffDays = this.actualDate.diff(val, 'days');
    this.diffHours = this.actualDate.diff(val, 'hours');
    this.diffMinutes = this.actualDate.diff(val, 'minutes') % 60;
    this.diffSeconds = this.actualDate.diff(val, 'seconds') % 60;

    if (this.diffDays > 0) {
      result += `${this.diffDays} `;
      if (this.diffDays === 1)
        result += `dzień `;
      else
        result += `dni `;
    }

    if (this.diffHours % 24 > 0) {
      if (this.diffHours === 1)
        result += `${this.diffHours % 24} godzina `;
      else if (this.diffHours > 1 && this.diffHours < 5)
        result += `${this.diffHours % 24} godziny `;
      else if (this.diffHours > 5 && this.diffHours < 22)
        result += `${this.diffHours % 24} godzin `;
      else
        result += `${this.diffHours % 24} godziny `;
    }

    if (this.diffMinutes > 0) {
      if (this.diffMinutes === 1)
        result += `${this.diffMinutes} minuta `;
      else if ((this.diffMinutes > 1 && this.diffMinutes < 5)
        || (this.diffMinutes > 21 && this.diffMinutes < 25)
        || (this.diffMinutes > 31 && this.diffMinutes < 35)
        || (this.diffMinutes > 41 && this.diffMinutes < 45)
        || (this.diffMinutes > 51 && this.diffMinutes < 55))
        result += `${this.diffMinutes} minuty `;
      else
        result += `${this.diffMinutes} minut `;
    }

    if (this.diffSeconds > 0) {
      if (this.diffSeconds === 1)
        result += `${this.diffSeconds} sekunda `;
      else if ((this.diffSeconds > 1 && this.diffSeconds < 5)
        || (this.diffSeconds > 21 && this.diffSeconds < 25)
        || (this.diffSeconds > 31 && this.diffSeconds < 35)
        || (this.diffSeconds > 41 && this.diffSeconds < 45)
        || (this.diffSeconds > 51 && this.diffSeconds < 55))
        result += `${this.diffSeconds} sekundy `;
      else
        result += `${this.diffSeconds} sekund `;
    }

    return result;
  }

  setProgressValue(hour: number, css: string) {
    let start = this.firstDay as any;
    let end = UtilsDate.new(this.firstDay).add(hour, 'hours') as any;
    let today = this.actualDate as any;
    let p = Math.round((today - start) / (end - start) * 100);
    if (p > 100) {
      p = 100;
      if (!$(css).hasClass('bg-success')) {
        $(css).addClass('bg-success');
        $(css).removeClass('bg-danger');
      }
    }


    $(css).css('width', p + '%').attr('aria-valuenow', p);
    $(css).text(p.toString() + "%");
  }

  getBarVal(css: string) {

    let start = this.firstDay as any;
    let end = UtilsDate.new(this.firstDay).add(24, 'hours') as any;
    let today = this.actualDate as any;
    let p = Math.round((today - start) / (end - start) * 100);
    console.log(p)
    return p;
    //return $(css).attr('aria-valuenow');
  }

  ngOnDestroy(): void {
    this.s?.unsubscribe();
    super.ngOnDestroy();
  }
}
