

import { Subscription } from "rxjs";
import { UtilsDate } from "../../../utils/utils-date";

export class CigarVM {
    public s: Subscription;
    public diffSeconds: number;
    public diffMinutes: number;
    public diffHours: number;
    public diffDays: number;
    public firstDay: any = UtilsDate.new("202210250830", "YYYYMMDDHHmm");
    public actualDate: any = UtilsDate.new();
    public result: string;
}
