import { random } from "lodash-es";


import { UtilsDate } from "../../../utils/utils-date";

export class Baby2VM {

    constructor() {
        this.initImages();
        this.random();
    }

    public iconIndex: number = 1;
    public showMoreInfo: boolean = false;
    public showImageFlag: boolean = false;
    public imageObject: Array<object> = [];
    public imageIndex: number = 1;
    public weightList: any;

    public firstDay: any = UtilsDate.new("202401170000", "YYYYMMDDHHmm");
    public actualDate: any = UtilsDate.new();

    public random() {
        this.iconIndex = random(3) + 1;
    }

    public initImages() {
        for (let i = 1; i < 9; i++)
            this.imageObject.push({ 'image': `assets/baby-new/${i}.jpg` });
    }
}
