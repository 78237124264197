<div class="center body">
  <img *ngIf="count < 5" [src]="getImage()" class="img-fluid"
    [ngClass]="{'rotated' : isRotated, 'transitioning-src ' : imageClass === 'transitioning-src', 'parallax-slider' : imageClass === 'parallax-slider'}"
    (load)="onImageLoad($event)" />
</div>

<div *ngIf="count < 5 && actualDate">
  <h1 class="time"><b>{{actualDate}}</b></h1>
  <h3 class="date">{{dayOfWeek}}, {{date}}</h3>
  <h3 class="folder">{{folderName}}</h3>
  <h3 class="weather">{{weather}}</h3>
</div>