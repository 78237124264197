import { Attribute, Component, EventEmitter, OnDestroy, Output } from "@angular/core";

import { Subscription, interval } from 'rxjs';
import { UtilsDate } from "../../../utils/utils-date";

@Component({
  selector: 'app-calc-from-date',
  templateUrl: './calc-from-date.component.html',
  styleUrls: ['./calc-from-date.component.scss']
})
export class CalcFromDateComponent implements OnDestroy {

  public months: string;
  public actualDate: any = UtilsDate.new();
  public result: string;
  public week: any;

  private s: Subscription;

  @Output()
  public output: EventEmitter<boolean> = new EventEmitter();

  constructor(
    @Attribute('date') public date,
    @Attribute('showWeek') public showWeek,
    @Attribute('showMonth') public showMonth) {

    this.s = interval(1000).subscribe(x => {
      this.actualDate = UtilsDate.new();
      this.setDate(UtilsDate.new(this.date, "YYYYMMDDHHmm"));
    });
  }

  setDate(val: any) {
    this.months = this.actualDate.diff(val, 'months');

    const diffDays = this.actualDate.diff(val, 'days');
    const diffHours = this.actualDate.diff(val, 'hours');
    const diffMinutes = this.actualDate.diff(val, 'minutes');
    const diffSeconds = this.actualDate.diff(val, 'seconds');

    if (diffSeconds >= 0) {
      this.result = `${diffDays} dni ${diffHours % 24} godzin ${diffMinutes % 60} minut ${diffSeconds % 60} sekund`;
      this.week = parseInt((diffDays / 7).toPrecision(3)) + " tydz. i " + diffDays % 7 + " dni";
    }
    else {
      this.result = `${diffDays * - 1} dni ${diffHours * -1 % 24} godzin ${diffMinutes * - 1 % 60} minut ${diffSeconds * - 1 % 60} sekund`;
      this.week = parseInt((diffDays * - 1 / 7).toPrecision(3)) + " tydz. i " + diffDays * - 1 % 7 + " dni";
    }

    this.output.emit(true);
  }

  ngOnDestroy(): void {
    this.s?.unsubscribe();
  }
}
