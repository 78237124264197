import { Component } from "@angular/core";
import { BaseComponent } from "../../core/base/base/base.component";

@Component({
  selector: 'app-important-new',
  templateUrl: './important-new.component.html',
  styleUrls: ['./important-new.component.scss']
})
export class ImportantNewComponent extends BaseComponent {

  public counter = 0;

}
