<div class="form">

  <app-header-for-module text="Jakosc Powietrza" src="/assets/icons/airly/airly.png"></app-header-for-module>

  <app-progress-spinner [isShow]="!sensor"></app-progress-spinner>

  <div *ngIf="sensor">
    <h1 class="center mt-3">
      <b>{{description}}</b>
    </h1>
    <h4 class="center"><i>{{time}}</i></h4>

    <hr />

    <div class="row center" style="width:98%">
      <div class="col">
        PM2.5:
        <b>{{pm25}}</b>
      </div>
      <div class="col">
        PM10:
        <b>{{pm10}}</b>
      </div>
    </div>

    <hr />

    <div class="row center" style="width:98%">
      <div class="col">
        PM2.5:
        <b>{{pm25val}}</b>
      </div>
      <div class="col">
        PM10:
        <b>{{pm10val}}</b>
      </div>
    </div>

    <hr />

    <div class="row center" style="width:98%">
      <div class="col">
        Temperatura:
        <b>{{temp}}</b>
      </div>
      <div class="col">
        Wilgotność:
        <b>{{hum}}</b>
      </div>
    </div>


    <hr />

    <div class="row center" style="width:98%">
      <div class="col">
        Ciśnienie:
        <b>{{bar}}</b>
      </div>
      <div class="col">
        Widoczność:
        <b>{{visibility}}</b>
      </div>
    </div>

    <hr />

    <h4 class="center"><i [innerHTML]="sensor"></i></h4>

  </div>
</div>