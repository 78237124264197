import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../core/base/base/base.component';

@Component({
  selector: 'app-onedrive',
  templateUrl: './onedrive.component.html',
  styleUrls: ['./onedrive.component.scss']
})
export class OnedriveComponent extends BaseComponent implements OnInit {

  public response: any;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.api.apiFrameGetOneDriveModel()
      .then(x => this.response = x);
  }

  synchronize() {
    this.displaySnack('Rozpoczęto sycnchronizacje..');
    this.api.apiFrameSynchronize().then(
      () => console.log("Zakończono synchronizację"));
  }

  openJuniorFolder() {
    this.utilsService.openUrl('https://1drv.ms/u/s!AgoUJ6bNz13TkM48Y6LISpW5l5QWBA?e=7RlJml');
  }

  openMadziaFolder() {
    this.utilsService.openUrl('https://1drv.ms/u/s!AgoUJ6bNz13Tjc4tI-O9yNlEKW3dDQ?e=yR53LM');
  }

  openMadziaCiazowaFolder() {
    this.utilsService.openUrl('https://1drv.ms/u/s!AgoUJ6bNz13Tj_ITSVJ8P9KugojciQ?e=ktM5Ol');
  }
}
